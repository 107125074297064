export const formatNum = (number) => {
  if (Number(number) === 0) {
    return 0;
  }
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-US").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return `${Number(number).toFixed(7)}`;
    }
  }
};

export const decimalPrice = (number) => {
  if (number && Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return i - 1;
      }
    }
  } else {
    return 5;
  }
};

export const formatNumNotDolla = (number) => {
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">$0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-IN").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return Number(number).toFixed(7);
    }
  }
};
export const convertFixed = (value) => {
  if (Number(value) === 0) // nếu value < 0.00000009
    return value ?.toFixed(2) ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // thì show giá trị 9 sốthập phân
  else if (Number(value) > 1e3) // nếu value lớn hơn 1e5 (100000)
    return value ?.toFixed(0) ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //thì show giá trị 0 số thập phân
  else if (Number(value) < 1 && Number(value) >= 9e-4) // nếu 1 > value >= 0.0009
    return value?.toFixed(4); // thì show giá trị 4 sốthập phân
    else if (Number(value) < 9e-4 && Number(value) >= 9e-7) // nếu value < 0.00000009
    return value?.toFixed(7); // thì show giá trị 9 sốthập phân
  else return value.toFixed(2);
};
