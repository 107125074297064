import { styled } from "styled-components";

export const AiMysteryBoxStyle = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-image: url("/assets/background/bg-ai-box.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 150px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("/assets/background/bg-navbar.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const AiMysteryBoxContainer = styled.div`
  position: relative;
  max-width: 1106px;
  margin: 0 auto;
  margin-top: 120px;
  position: relative;
  .img-right-hand {
    position: absolute;
    max-width: 280px;
    bottom: -165px;
    left: 235px;
  }
  .img-left-hand {
    position: absolute;
    max-width: 280px;
    bottom: -165px;
    left: -110px;
  }
  .img-box-left-hand {
    position: absolute;
    max-width: 220px;
    bottom: -120px;
    left: -105px;
    z-index: 2;
  }
  @media screen and (max-width: 1075px) {
    margin-top: 80px;
    .img-right-hand {
      right: 0;
      left: unset;
    }
    .img-left-hand {
      left: 0;
    }
    .img-box-left-hand {
      left: -25px;
    }
  }
`;

export const AiMysteryBoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 65px;

  @media screen and (max-width: 1075px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
`;

export const AiMysteryBoxLeft = styled.div`
  max-width: 420px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  border-radius: 17px;
  border: 3px solid #440e89;
  background: rgba(43, 5, 46, 0.45);
  backdrop-filter: blur(50px);
  padding: 27px;
  position: relative;
  padding-top: 0;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("/assets/background/bg-box.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    mix-blend-mode: color-dodge;
    opacity: 0.7;
    border-radius: 10px;
  }
  @media screen and (max-width: 1075px) {
    max-width: 100%;
  }
`;

export const AiMysteryBoxLeftTitle = styled.div`
  position: relative;
  padding-top: 27px;
  .img-ai-box {
    max-width: 320px;
    margin: 0 auto;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: url("/assets/images/img-title-light.png");
      background-position: center;
      background-repeat: no-repeat;
      top: -70px;
      left: -8px;
      mix-blend-mode: screen;
    }
  }
  .img-banner-l {
    position: absolute;
    max-width: 50px;
    right: 0;
    top: 145px;
  }
`;

export const AiMysteryBoxLeftSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
`;

export const AiMysteryBoxLeftStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .num-step {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    border: 0.604px solid rgba(161, 174, 229, 0.15);
    span {
      color: #b9c7ff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 15.214px; /* 108.671% */
      letter-spacing: 0.254px;
      opacity: 0.5;
    }
  }
  p {
    color: #a0aee5;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.405px; /* 145.747% */
    letter-spacing: 0.268px;
  }

  .active {
    .num-step {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(26, 21, 29, 0.35) 0%,
        rgba(135, 57, 233, 0.35) 100%
      );
      box-shadow: 0px 0px 18.113px 0px rgba(175, 199, 222, 0.22) inset;
      span {
        opacity: 1;
      }
    }
    p {
      background: var(
        --Gradient,
        linear-gradient(90deg, #c44cff 0%, #5c00fc 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const AiMysteryBoxLeftLine = styled.div`
  height: 3px;
  width: 100%;
  background-color: #c0d3ea;
  border-radius: 4px;
  margin-top: 17px;
`;

export const AiMysteryBoxLeftDes = styled.div`
  position: relative;
  margin-top: 25px;
  p {
    color: #c0d3ea;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.268px;
  }
`;

export const AiMysteryBoxLeftList = styled.div``;

export const AiMysteryBoxLeftListItem = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin: 5px 0;
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #c0d3ea;
    margin-top: 5px;
  }
`;

export const AiMysteryBoxRight = styled.div`
  max-width: calc((100% - 470px));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 17px;
  border: 3px solid #440e89;
  background: rgba(18, 21, 29, 0.45);
  backdrop-filter: blur(50px);
  position: relative;
  overflow: hidden;
  .img-light {
    position: absolute;
    right: -330px;
    top: -40px;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("/assets/background/bg-box.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    mix-blend-mode: color-dodge;
    opacity: 0.7;
    border-radius: 10px;
  }
  @media screen and (max-width: 1075px) {
    max-width: 100%;
  }
`;

export const AiMysteryBoxRightLogo = styled.div`
  background: rgba(18, 21, 29, 0.45);
  border-bottom: 1px solid rgba(53, 60, 87, 0.22);
  padding: 17px;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  width: 100%;
  .img-logo {
    max-width: 100px;
  }
`;

export const AiMysteryBoxRightContainer = styled.div`
  position: relative;
  padding: 27px;
  width: 100%;
`;

export const AiMysteryBoxRightTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const AiMysteryBoxRightImage = styled.div`
  width: 270px;
  height: 270px;
  background-image: url("/assets/background/bg-img.png");
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: lighten;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .img-cart-1 {
    max-width: 115px;
    position: absolute;
    bottom: 20px;
    right: -30px;
    z-index: 1;
    transform: rotate(5deg);
  }
  .img-cart-2 {
    max-width: 75px;
    position: absolute;
    transform: rotate(-25deg);
    top: 0;
    right: 15px;
    z-index: 1;
  }
  .img-cart-3 {
    max-width: 115px;
    position: absolute;
    bottom: -25px;
    left: -35px;
    z-index: 1;
    transform: rotate(-15deg);
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("/assets/images/img-box.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: lighten;
    transform: scale(1.2);
    bottom: -25px;
  }
`;

export const AiMysteryBoxRightContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: calc((100% - 285px));
  h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 22.672px; /* 113.359% */
    letter-spacing: 0.298px;
    background: linear-gradient(180deg, #b6c2f1 0%, #ebefff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  button {
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 2.983px;
    align-self: stretch;
    width: 165px;
    border: 0.905px solid rgba(161, 174, 229, 0.2);
    span {
      color: #d6deff;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 15.214px; /* 101.427% */
      letter-spacing: 0.254px;
    }
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    align-items: center;
  }
`;

export const AiMysteryBoxRightInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 50px;
  p {
    color: #a0aee5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.268px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 30px;
  }
`;

export const AiMysteryBoxRightTable = styled.div`
  position: relative;
  width: 100%;
  border-radius: 17px;
  border: 0.746px solid #7b00c6;
  margin-top: 35px;
  background: linear-gradient(
    90deg,
    rgba(15, 13, 17, 0.43) 0%,
    rgba(28, 28, 34, 0) 100%
  );
  backdrop-filter: blur(50px);
  .ant-progress-text {
    color: #ecf0ff;
    font-weight: 600;
  }
  .tb-header {
    border-top-right-radius: 17px;
    border-top-left-radius: 17px;
    border-bottom: 1px solid #7b00c6;
    padding: 5px 18px;
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 5px 30px;
    }
    h1 {
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.8px;
      background: linear-gradient(0deg, #a1aee5 0%, #ecf0ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 112.5% */
      margin-right: 10px;
    }
  }

  .tb-content {
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
    padding: 20px 30px 25px 30px;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      span {
        color: var(--Colors-Primary-Colors-Gray-300, #d0d5dd);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 120% */
      }
      p {
        color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 112.5% */
        margin-top: 10px;
      }
    }
  }
`;
