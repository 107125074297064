import { Modal } from "antd";
import React from "react";
import "./index.css";
import { ContentModal } from "./styled";
import { HistoryListRightBlockDetail } from "../styled";
import moment from "moment";

const ModalMobile = ({
  isModalOpen,
  handleOk,
  handleCancel,
  dataItem,
}: any) => {
  return (
    <>
      <Modal
        title="Image Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="modal-detail-mobile"
        centered={true}
      >
        <ContentModal>
          <div className="image-detail">
            {/* <img
              src={dataItem?.resultUrl}
              alt={dataItem?.text ? dataItem?.text : "Image"}
            /> */}
            {(dataItem?.resultUrl?.endsWith(".jpg") ||
              dataItem?.resultUrl?.endsWith(".png")) && (
              <img src={dataItem?.resultUrl} alt={dataItem?.text} />
            )}
            {dataItem?.resultUrl?.endsWith(".mp4") && (
              <>
                <video controls>
                  <source src={dataItem?.resultUrl} type="video/mp4" />
                </video>
              </>
            )}
          </div>
          <HistoryListRightBlockDetail>
            {dataItem && (
              <div className="content-time">
                <p>
                  {moment(parseInt(dataItem?.createdAt)).format("MMM, Do YYYY")}
                </p>
                <p className="count-creadit">Credit: {dataItem?.credit}</p>
              </div>
            )}
            <h3>Image Description</h3>
            <p>{dataItem?.text ? dataItem?.text : "No text"}</p>
          </HistoryListRightBlockDetail>
        </ContentModal>
      </Modal>
    </>
  );
};

export default ModalMobile;
