import Marquee from "react-fast-marquee";
import StarUp from "../../components/StarUp";
import { ButtonStyle } from "../Homev2/styled";
import {
  AiMysteryBoxContainer,
  AiMysteryBoxLeft,
  AiMysteryBoxLeftDes,
  AiMysteryBoxLeftLine,
  AiMysteryBoxLeftList,
  AiMysteryBoxLeftListItem,
  AiMysteryBoxLeftStep,
  AiMysteryBoxLeftSteps,
  AiMysteryBoxLeftTitle,
  AiMysteryBoxRight,
  AiMysteryBoxRightTable,
  AiMysteryBoxRightTitle,
  AiMysteryBoxRightContent,
  AiMysteryBoxRightInfo,
  AiMysteryBoxRow,
  AiMysteryBoxStyle,
  AiMysteryBoxRightLogo,
  AiMysteryBoxRightContainer,
  AiMysteryBoxRightImage,
} from "./styled";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAccount, useContractRead } from "wagmi";
import { useWeb3Modal } from "@web3modal/scaffold-react";
import { ADDRESS_AI_MYSTERY_BOX } from "../../contract/address";
import { bsc } from "viem/chains";
import abiMysterybox from "../../contract/ABI/abiMysterybox.json";
import useMintNFT from "../../hooks/useMintNFT";
import useGetPriceBox from "../../hooks/useGetPriceBox";
import { shortenAddress } from "../../utils/addressUser";
import { toast } from "react-hot-toast";
import { formatEther } from "viem";
import { Link } from "react-router-dom";
import useTransactionHash from "../../hooks/useTransactionHash";
import { ethers } from "ethers";
import { Progress } from "antd";
import { convertIpfsToObjHash } from "../../utils/ipfsToImage";
import ModalSuccess from "../../components/Modal";

const LIST_DES = [
  "Profit-sharing from the SocialFi App",
  "Free access to AI features",
  "NFT Staking",
  "Whitelist for $AMFI Pre-Sale and AIMETAFI partner projects",
  "Increased profits through $AMFI Staking",
];

const STEPS = [
  {
    label: "Ready: 07:00 UTC - April 04,2024",
    time: "04/04/2024",
  },
  {
    label: "Start: 07:00 UTC - April 05,2024",
    time: "05/04/2024",
  },
  {
    label: "End : TBA",
    time: "",
  },
];

export default function AiMysteryBox() {
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState<any>(0);
  const [days, setDays] = useState<any>(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isBuy, setIsBuy] = useState(false);
  const [textCountDown, setTextCountDown] = useState("");
  const [dataHash, setDataHash] = useState<any>(null);
  const [addressBuy, setAddressBuy] = useState<any>(null);
  const [addressId, setAddressId] = useState<any>(null);
  const [objHash, setObjHash] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [percentMinted, setPercent] = useState<any>(0);

  const amountPayment: any = useGetPriceBox(
    ADDRESS_AI_MYSTERY_BOX,
    abiMysterybox
  );

  const amountMint: any = useContractRead({
    address: ADDRESS_AI_MYSTERY_BOX,
    abi: abiMysterybox,
    functionName: "getAmountRemainingMintPhases2",
    chainId: bsc.id,
  });

  const { writeAsync: buyAiMysteryBox, isPrepareError } = useMintNFT(
    amountPayment?.data?.toString(),
    ADDRESS_AI_MYSTERY_BOX,
    abiMysterybox
  );

  const transactionHash = useTransactionHash(dataHash);

  const handleBuyBox = async () => {
    try {
      if (!address) {
        open();
      } else {
        if (isPrepareError) {
          return toast.error(
            "You are not eligible to purchase, please check your wallet again"
          );
        }
        setIsLoading(true);
        await buyAiMysteryBox?.()
          .then((res) => {
            setDataHash(res.hash);
            setIsShowModal(true);
          })
          .catch(() => {
            toast.error("You've rejected");
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log("handleBuyBox err", error);
      setIsLoading(false);
    }
  };

  const countDownTime = () => {
    const currentHour = 7;
    const timeUTC = ((new Date().getTimezoneOffset() * 60) / (60 * 60)) * -1;
    const countDownDate = new Date(
      `04/05/2024 ${currentHour + timeUTC}:00`
    ).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // Time calculations for days, hours, minutes and seconds
      setDays(
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24))
      );
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      const percent = Number(
        ((500 - Number(amountMint?.data)) / 500) * 100
      ).toFixed(0);

      setPercent(percent);

      if (distance <= 0) {
        clearInterval(x);
        setIsStarted(true);
        setTextCountDown("Congratulation");
        setIsBuy(true);
      } else if (Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0) {
        setIsStarted(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (isShowModal && transactionHash.data) {
      const iface = new ethers.Interface(abiMysterybox);
      const parsed2 = iface.parseLog(transactionHash.data.logs[2]);
      setAddressBuy(parsed2?.args[0]);
      setAddressId(Number(parsed2?.args[1]));
      const objHash = convertIpfsToObjHash(parsed2?.args[3]);
      setObjHash(objHash);
      setIsLoading(false);
    }
  }, [transactionHash]);

  useEffect(() => {
    countDownTime();
    const date: any = dayjs().format("DD/MM/YYYY");
    setCurrentDate(date);
  }, []);

  return (
    <AiMysteryBoxStyle>
      <ModalSuccess
        isOpenModal={isShowModal}
        address={dataHash}
        id={addressId}
        level={objHash?.label}
        image={objHash?.image}
        onClose={() => {
          setIsLoading(false);
          setIsShowModal(false);
          setObjHash(null);
        }}
      />

      <AiMysteryBoxContainer>
        <div className="img-right-hand">
          <img src="/assets/images/img-hand-right.png" alt="img-hand-right" />
        </div>
        <div className="img-box-left-hand">
          <img src="/assets/images/img-box-left-hand.png" alt="img-hand-left" />
        </div>
        <div className="img-left-hand">
          <img src="/assets/images/img-hand-left.png" alt="img-hand-left" />
        </div>
        <AiMysteryBoxRow>
          <AiMysteryBoxLeft>
            <AiMysteryBoxLeftTitle>
              <div className="img-ai-box">
                <img src="/assets/images/img-ai-box.png" alt="img-ai-box" />
              </div>
              <div className="img-banner-l">
                <img src="/assets/images/img-banner-l.png" alt="banner" />
              </div>
              <AiMysteryBoxLeftSteps>
                {STEPS.map((item: any, index: number) => {
                  return (
                    <AiMysteryBoxLeftStep key={index}>
                      <div
                        className={
                          currentDate === item.time && isStarted ? "active" : ""
                        }
                      >
                        <div className="num-step">
                          <span>{index + 1}</span>
                        </div>
                      </div>
                      <div
                        className={
                          currentDate === item.time && isStarted ? "active" : ""
                        }
                      >
                        <p>{item.label}</p>
                      </div>
                    </AiMysteryBoxLeftStep>
                  );
                })}
              </AiMysteryBoxLeftSteps>
            </AiMysteryBoxLeftTitle>
            <AiMysteryBoxLeftLine></AiMysteryBoxLeftLine>
            <AiMysteryBoxLeftDes>
              <p>
                AIMETAFI NFT is a collection of 500 NFTs with 3 rarity: Bronze,
                Silver, and Gold. Owning an AIMETAFI NFT grants numerous
                benefits, including:
              </p>
              <AiMysteryBoxLeftList>
                {LIST_DES.map((item: any, index: number) => {
                  return (
                    <AiMysteryBoxLeftListItem>
                      <div className="dot"></div>
                      <p>{item}</p>
                    </AiMysteryBoxLeftListItem>
                  );
                })}
              </AiMysteryBoxLeftList>
            </AiMysteryBoxLeftDes>
          </AiMysteryBoxLeft>
          <AiMysteryBoxRight>
            <div className="img-light">
              <img src="/assets/images/img-light-box.png" alt="img-light-box" />
            </div>
            <AiMysteryBoxRightLogo>
              <div className="img-logo">
                <img src="/assets/images/img-logo-box.png" alt="logo-box" />
              </div>
            </AiMysteryBoxRightLogo>
            <AiMysteryBoxRightContainer>
              <AiMysteryBoxRightTitle>
                <AiMysteryBoxRightImage>
                  <div className="img-cart-1">
                    <img src="/assets/images/img-card-3.gif" alt="card" />
                  </div>
                  <div className="img-cart-2">
                    <img src="/assets/images/img-card-1.gif" alt="card" />
                  </div>
                  <div className="img-cart-3">
                    <img src="/assets/images/img-card-3.gif" alt="card" />
                  </div>
                </AiMysteryBoxRightImage>
                <AiMysteryBoxRightContent>
                  <h1>AIMETAFI MYSTERY BOX</h1>
                  <AiMysteryBoxRightInfo>
                    <p>Name : AIMETAFI NFT Collection</p>
                    <p>Symbol: AMFINFT</p>
                    <a
                      href="https://bscscan.com/address/0xDad8e790ce4976AB1A21B897b4feacd0d8df5F89"
                      target="_blank"
                    >
                      <p>
                        Smart Contract: {shortenAddress(ADDRESS_AI_MYSTERY_BOX)}{" "}
                      </p>
                    </a>
                  </AiMysteryBoxRightInfo>
                  <ButtonStyle>
                    <button
                      disabled={isLoading || !isBuy}
                      onClick={handleBuyBox}
                      style={{
                        cursor: isLoading || !isBuy ? "not-allowed" : "",
                        width: "170px",
                      }}
                    >
                      <StarUp />
                      {isLoading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>{address ? "Buy Box" : "Connect wallet"}</span>
                      )}
                      <img
                        src="/assets/icons/icon-go-staking.png"
                        alt="icon-go-staking"
                      />
                    </button>
                  </ButtonStyle>
                </AiMysteryBoxRightContent>
              </AiMysteryBoxRightTitle>
              <AiMysteryBoxRightTable>
                <div className="tb-header">
                  {textCountDown ? (
                    <div className="row">
                      <p>Minted</p>
                      <Progress
                        status="active"
                        percent={percentMinted}
                        strokeColor={{ from: "#6707fd", to: "#c44cff " }}
                        showInfo={true}
                        trailColor="rgba(176, 137, 226, .5)"
                      />
                    </div>
                  ) : (
                    <h1>
                      {days < 10 ? `0${days}` : days}d:
                      {hours < 10 ? `0${hours}` : hours}h:
                      {minutes < 10 ? `0${minutes}` : minutes}m:
                      {seconds < 10 ? `0${seconds}` : seconds}s
                    </h1>
                  )}
                </div>
                <div className="tb-content">
                  <div className="row">
                    <div className="col">
                      <span>Sale Price</span>
                      {amountPayment?.data && (
                        <p>
                          {formatEther(amountPayment?.data?.toString())} BNB
                        </p>
                      )}
                    </div>
                    <div className="col">
                      <span>Minted</span>
                      <p>{500 - Number(amountMint?.data)}</p>
                    </div>
                    <div className="col">
                      <span>Total Supply</span>
                      <p>500</p>
                    </div>
                  </div>
                </div>
              </AiMysteryBoxRightTable>
            </AiMysteryBoxRightContainer>
          </AiMysteryBoxRight>
        </AiMysteryBoxRow>
      </AiMysteryBoxContainer>
    </AiMysteryBoxStyle>
  );
}
