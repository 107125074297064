import {
  NavIcon,
  SidebarCollapse,
  SidebarContainer,
  SidebarContent,
  SidebarLinks,
  SidebarLogo,
  SidebarWrapper,
  SubMenu,
  SubMenuInner,
} from "./styled";
import sidebar_logo from "../../assets/Sidebar/sidebar_logo.png";
import { NavLink, Link, useParams } from "react-router-dom";
import collapse_icon from "../../assets/Sidebar/collapse.png";
import logo_collapse from "../../assets/Sidebar/logo_collapse.svg";
import account from "../../assets/Sidebar/normal/account.svg";
import bonus from "../../assets/Sidebar/normal/bonus.svg";
import affiliate from "../../assets/Sidebar/normal/affiliate.svg";
import dashboard from "../../assets/Sidebar/normal/dashboard.svg";
import history from "../../assets/Sidebar/normal/history.svg";
import swap from "../../assets/Sidebar/normal/swap.svg";
import invest from "../../assets/Sidebar/normal/invest.svg";
import wallet from "../../assets/Sidebar/normal/wallet.svg";
import support from "../../assets/Sidebar/normal/support.svg";
import ai from "../../assets/Sidebar/normal/ai.svg";
import video from "../../assets/Sidebar/normal/video.svg";
import image from "../../assets/Sidebar/normal/img.svg";
import support_active from "../../assets/Sidebar/active/support_active.svg";
import account_active from "../../assets/Sidebar/active/account_active.svg";
import bonus_active from "../../assets/Sidebar/active/bonus_active.svg";
import affiliate_active from "../../assets/Sidebar/active/affiliate_active.svg";
import dashboard_active from "../../assets/Sidebar/active/dashboard_active.svg";
import history_active from "../../assets/Sidebar/active/history_active.svg";
import swap_active from "../../assets/Sidebar/active/swap_active.svg";
import invest_active from "../../assets/Sidebar/active/invest_active.svg";
import wallet_active from "../../assets/Sidebar/active/wallet_active.svg";
import ai_active from "../../assets/Sidebar/active/ai_active.svg";
import video_active from "../../assets/Sidebar/active/video_active.svg";
import image_active from "../../assets/Sidebar/active/img_active.svg";
import { ContextProviderWrapper } from "../Context";
import { useContext } from "react";
import BottomBox from "./BottomBox";
import { useTranslation } from "react-i18next";
import { HeaderLanguage } from "../ChooseLanguage/styled";
import ChangeLanguage from "../ChooseLanguage";

const Sidebar = ({
  setCollapse,
  collapse,
  openHamburger,
  setOpenHamburger,
}: any) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const linksData = [
    {
      title: `${t("Dashboard")}`,
      href: "/dashboard",
      iconNormal: dashboard,
      iconActive: dashboard_active,
    },
    {
      title: `${t("Investment")}`,
      href: "/investment",
      iconNormal: invest,
      iconActive: invest_active,
    },
    {
      title: `${t("Ranking Bonus")}`,
      href: "/bonus",
      iconNormal: bonus,
      iconActive: bonus_active,
    },
    {
      title: `${t("Swap")}`,
      href: "/swap-aifi",
      iconNormal: swap,
      iconActive: swap_active,
    },
    {
      title: `${t("Wallet")}`,
      href: "/wallet",
      iconNormal: wallet,
      iconActive: wallet_active,
    },
    {
      title: `${t("Affiliate")}`,
      href: "/affiliate",
      iconNormal: affiliate,
      iconActive: affiliate_active,
    },
    {
      title: `${t("History")}`,
      href: "/history",
      iconNormal: history,
      iconActive: history_active,
    },
    {
      title: `${t("Account")}`,
      href: "/account",
      iconNormal: account,
      iconActive: account_active,
    },
    {
      title: `${t("Support")}`,
      href: "/support",
      iconNormal: support,
      iconActive: support_active,
    },
    {
      title: `${t("Video Generate")}`,
      href: `/artificial/video`,
      iconNormal: video,
      iconActive: video_active,
    },
    {
      title: `${t("Image Generate")}`,
      href: `/artificial/image`,
      iconNormal: image,
      iconActive: image_active,
    },
  ];

  return (
    <SidebarContainer
      className={
        collapse && isDesktop ? "collapse" : openHamburger ? "open" : ""
      }
    >
      {isDesktop && (
        <SidebarCollapse
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <img src={collapse_icon} alt="collapse" />
        </SidebarCollapse>
      )}
      <SidebarWrapper className={collapse ? "collapse" : ""}>
        {isMobile && (
          <HeaderLanguage>
            <ChangeLanguage />
          </HeaderLanguage>
        )}
        <div>
          {isDesktop && (
            <SidebarLogo className={collapse ? "collapse" : ""}>
              <Link to="/">
                <img className="normal" src={sidebar_logo} alt="logo" />
                <img className="active" src={logo_collapse} alt="collapse" />
              </Link>
            </SidebarLogo>
          )}
        </div>
        <SidebarContent>
          <SidebarLinks>
            {linksData.map((item: any, index) => {
              return (
                <li key={index} className={item.subMenu && "is-height"}>
                  <div>
                    <NavLink
                      to={item.href}
                      onClick={() => {
                        !isDesktop && setOpenHamburger(false);
                      }}
                    >
                      <SubMenuInner>
                        <NavIcon>
                          <img
                            className="normal"
                            src={item.iconNormal}
                            alt="normal"
                          />
                          <img
                            className="active"
                            src={item.iconActive}
                            alt="active"
                          />
                        </NavIcon>
                        <p className={collapse ? "collapse" : ""}>
                          {item.title}
                        </p>
                      </SubMenuInner>
                      {item.subMenu && (
                        <SubMenu className="is-show">
                          {item.subMenu.map((inner: any, index: number) => (
                            <li key={index}>
                              <div>
                                <NavLink to={inner.href}>
                                  <SubMenuInner>
                                    <NavIcon>
                                      <img
                                        className="normal"
                                        src={inner.iconNormal}
                                        alt="normal"
                                      />
                                      <img
                                        className="active"
                                        src={inner.iconActive}
                                        alt="active"
                                      />
                                    </NavIcon>
                                    <p className={collapse ? "collapse" : ""}>
                                      {inner.subTitle}
                                    </p>
                                  </SubMenuInner>
                                </NavLink>
                              </div>
                            </li>
                          ))}
                        </SubMenu>
                      )}
                    </NavLink>
                  </div>
                </li>
              );
            })}
          </SidebarLinks>
          <BottomBox setCollapse={setCollapse} collapse={collapse} />
        </SidebarContent>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
