import { BorderAnimation } from "../../Layout/styled";

export default function OurItem({ data }: any) {
  return (
    <div className="our-item" data-aos="zoom-in">
      <BorderAnimation />
      <div style={{
        width: '23px'
      }} className="image">
        <img style={{
          width: '100%'
        }} src={data.image} alt="icon-version" />
      </div>
      <h3>
        {data.title}
      </h3>
      <p>
        {data.content}
      </p>
    </div>
  )
}
