import styled from "styled-components";

export const ContentModal = styled.div`
  padding: 12px;
  .image-detail {
    max-width: 327px;
    width: 100%;
    height: 313px;
    margin: 0 auto 15px;
    border-radius: 12px;
    overflow: hidden;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`