import styled from "styled-components";
import swap_icon from "../../../assets/Dashboard/Swap/swap_icon.svg";
import star_green from "../../../assets/Dashboard/Swap/star_green.svg";
import star_yellow from "../../../assets/Dashboard/Swap/star_yellow.svg";
import BgBox from "../../../assets/Swap/BgBox.png";
import {
  InfoListStyle2,
  InfoValue,
} from "../../../components/Common/Info/styled";
import { SelectContainer } from "../../../components/Common/Select/styled";

export const SwapContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  .ant-radio-group .ant-radio-wrapper {
    color: #fff;
    margin-bottom: 5px;
    .ant-radio-inner {
      border-color: #ff60ef;
      background-color: #ff60ef;
      margin-top: -5px;
    }
  }
  @media screen and (min-width: 1200px) {
    ${InfoListStyle2} {
      & > li {
        width: calc(33.33% - 10px);
      }
      ${InfoValue} {
        font-size: 16px;
      }
    }
    ${SelectContainer} {
      width: 120px;
    }
  }
`;
export const SwapListBoxDestop = styled.div`
  width: 100%;
  background-image: url(${BgBox});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 16px;
  margin-bottom: 20px;
  @media only screen and (max-width: 919px) {
    display: none;
  }
`;
export const SwapListBoxMobile = styled.div`
  display: none;
  margin-top: 10px;
  @media only screen and (max-width: 919px) {
    display: block;
  }
`;
export const ButtonMax = styled.div`
  cursor: pointer;
  width: 30px;
  flex-shrink: 0;
  background: linear-gradient(98deg, #ceb6ff 2.24%, #864aff 112.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
`;
export const SwapWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 50px;
  padding-top: 10px;
  @media screen and (max-width: 767px) {
    align-items: normal;
    padding-bottom: 0;
  }
  @media only screen and (max-width: 919px) {
    flex-direction: column-reverse;
  }
`;
export const SwapLeft = styled.div`
  width: calc(100% - 373px);
  table,
  td {
    vertical-align: middle !important;
  }
  @media only screen and (max-width: 919px) {
    width: 100%;
  }
`;
export const TableMobile = styled.div`
  display: none;
  @media only screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
export const SwapRight = styled.div`
  position: relative;
  max-width: 358px;
  width: 358px;
  padding: 30px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #344054;
  background: #000;
  box-shadow: 0px 0px 12.5px 0px rgba(0, 0, 0, 0.12);
  &::before {
    content: "";
    position: absolute;
    top: 20px;
    right: 20px;
    background: url(${swap_icon}) no-repeat center / 100% auto;
    width: 34px;
    height: 34px;
  }
  h6 {
    color: var(--Colors-Primary-Colors-Gray-200, #eaecf0);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 90% */
  }
  @media screen and (min-width: 768px) and (max-width: 1300px) {
    /* width: 300px; */
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (max-width: 919px) {
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const SwapSmallText = styled.p`
  padding-left: 10px;
  color: var(--Colors-Primary-Colors-Gray-500, #667085);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  margin-bottom: 20px;
`;
export const SwapForm = styled.div``;
export const ExplainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a4a7bb;
  font-size: 14px;
  letter-spacing: 0.42px;
  & > div {
    display: flex;
    align-items: center;
    & > img {
      margin-left: 5px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  & > p {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    &::before {
      content: "";
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      background-color: #667085;
      border-radius: 50%;
    }
  }
`;
export const SwapExplain = styled.div`
  margin-bottom: 20px;
`;
export const SwapButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 44px;
  border-radius: 10px;
  background: #28112b;
  outline: none;
  border: none;
  color: #ee46bc;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;
export const FormExchange = styled.div`
  position: relative;
  color: #fff;
  margin-bottom: 20px;
`;
export const ExchangeBlock = styled.div`
  padding: 15px;
  background: #12101d;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 255, 0.1);
  transition: all 0.3 ease;
  &:hover {
    border-color: #8762ed;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const ExchangeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const SwapExchangeIcon = styled.figure`
  width: 27px;
  height: 27px;
  margin: 0 auto;
  margin: -20px auto -10px;
  transition: all .15s linear;
  cursor: pointer;
  &:hover {
    transform: rotate(180deg);
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
export const ExchangeContent = styled.div`
  font-size: 13px;
`;
export const ExchangeCurrency = styled.div`
  display: flex;
  align-items: center;
  color: #a4a7bb;
  font-size: 14px;
  position: relative;
  border-radius: 9px;
  background: #1d1b2a;
  padding: 5px var(--Parameters-Radius-sm, 6px);
  gap: 8px;
  img {
    width: 17px;
    height: 17px;
  }
`;
export const ExchangeTokentList = styled.div<{ isshow?: any; length?: number }>`
  display: flex;
  position: absolute;
  border-radius: 9px;
  background: #1d1b2a;
  display: inline-flex;
  padding: ${({ isshow }) =>
    isshow ? "5px var(--Parameters-Radius-sm, 6px)" : "0px"};
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  bottom: 0px;
  left: 0px;
  transform: translateY(calc(100% + 5px));
  width: max-content;
  z-index: 2;
  height: ${({ isshow, length }) =>
    isshow && length ? `${length * 19 + (length - 1) * 2 + 12}px` : "0px"};
  border: ${({ isshow }) => (isshow ? "1px" : "0px")} solid
    rgba(226, 232, 255, 0.1);
  overflow: hidden;
  transition: all 0.3s linear;
  button {
    display: flex;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: max-content;
    color: #e5e5e5;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.42px;
  }
`;
export const ExchangeInput = styled.input`
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border-radius: 7px;
`;
export const ExchangeMax = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const MaxButton = styled.div`
  position: relative;
  font-size: 15px;
  font-weight: 600;
  background: linear-gradient(98deg, #ceb6ff 2.24%, #864aff 112.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    left: -8px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #9fa2b9;
  }
`;
export const ExchangeText = styled.p``;
export const ExchangeValue = styled.p`
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(98deg, #ceb6ff 2.24%, #864aff 112.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const TextStatus = styled.p`
  border-radius: 21px;
  background: #53389e;
  color: var(--Colors-Primary-Colors-Primary-200, #e9d7fe);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  text-align: center;
  padding: 6px 8px;
`;
