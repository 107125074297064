import React, { useContext, useEffect, useState } from "react";
import {
  ArtificialButtonRegenerate,
  ArtificialButtons,
  ArtificialButtonSave,
  ArtificialContentInner,
  ArtificialContentLeft,
  ArtificialContentRight,
  ArtificialTitleInfo,
  ArtificialWrapper,
} from "../../ArtificialIntelligenceVideo/styled";
import {
  HistoryContainer,
  HistoryList,
  HistoryListRightBlockDetail,
  HistoryListRightInner,
  HistoryListRightInnerImage,
} from "./styled";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { DashboardTitle } from "../../../../../Layout/styled";
import { saveAs } from "file-saver";
import { ContextProviderWrapper } from "../../../../../components/Context";
import ModalMobile from "./ModalMobile";
import { v4 as uuidv4 } from "uuid";

const GET_AIGENS = gql`
  query aigens {
    aigens {
      id
      accountID
      credit
      AIType
      status
      resultUrl
      text
      createdAt
      updatedAt
    }
  }
`;

const History = () => {
  const [mediaDetail, setMediaDetail] = useState(
    "/assets/artificial/img_dommy_02.jpg"
  );
  const [textDescription, setTextDescription] = useState("...");
  const [mediaType, setMediaType] = useState<any>("image");
  const [dataTime, setDataTime] = useState<any>();
  const [showCredit, setShowCredit] = useState<number>();
  const [dataItem, setDataItem] = useState<any>();
  const [newData, setNewData] = useState<any>()
  const { isMobile } = useContext(ContextProviderWrapper)!;

  const handleShowDetail = (item: any) => {
    setDataItem(item);
    if (isMobile) {
      showModal();
    }
    setDataTime(item?.createdAt);
    setShowCredit(item?.credit);
    const url = item?.resultUrl
      ? item?.resultUrl
      : "/assets/artificial/img_dummy.png";
    setMediaDetail(url);
    setTextDescription(item?.text ? item?.text : item?.AIType);
    if (url.endsWith(".jpg") || url.endsWith(".png")) {
      setMediaType("image");
    } else if (url.endsWith(".mp4")) {
      setMediaType("video");
    }
    setImgList([url]);
  };

  //handle download image
  const [imgList, setImgList] = useState<any>(null);
  const handleDownloadImage = (images: any) => {
    const downLoad = async (file: any) => {
      try {
        await saveAs(file, `${uuidv4()}`);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    images.forEach(async (element: any) => {
      await downLoad(element);
    });
  };

  //api get history
  const { data: dataGens } = useQuery(GET_AIGENS, {
    fetchPolicy: "network-only",
  });

  // Modal detail mobile
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (dataGens) {
      const newDate = [...dataGens?.aigens]?.reverse();
      setNewData(newDate)
    }
  }, [dataGens]);

  return (
    <>
      <HistoryContainer>
        <ArtificialWrapper>
          <ArtificialContentLeft>
            <ArtificialTitleInfo>
              <DashboardTitle background="#FFE11E">
                Your Generated History
              </DashboardTitle>
              <div className="image">
                <img src="/assets/artificial/icn_img_01.svg" alt="" />
              </div>
            </ArtificialTitleInfo>
            <ArtificialContentInner>
              <HistoryList>
                {newData?.map((item: any, index: number) => (
                  <li key={index} onClick={() => handleShowDetail(item)}>
                    {(item?.resultUrl?.endsWith(".jpg") ||
                      item?.resultUrl?.endsWith(".png")) && (
                      <img src={item.resultUrl} alt={item.__typename} />
                    )}
                    {item?.resultUrl?.endsWith(".mp4") && (
                      <>
                        <div className="icon-play">
                          <img
                            src="/assets/artificial/icon_play_mobile.png"
                            alt="Icon"
                          />
                        </div>
                        <video controls={false}>
                          <source src={item?.resultUrl} type="video/mp4" />
                        </video>
                      </>
                    )}
                    {!isMobile && <><p>{item?.text ? item?.text : item?.AIType}</p> <time>{moment(parseInt(item?.createdAt)).format("MMM, Do YYYY")}</time></>}
                  </li>
                ))}
              </HistoryList>
            </ArtificialContentInner>
          </ArtificialContentLeft>
          {!isMobile && (
            <ArtificialContentRight>
              <ArtificialTitleInfo>
                <p>Image Details</p>
                <div className="image">
                  <img src="/assets/artificial/icn_paper.svg" alt="" />
                </div>
              </ArtificialTitleInfo>
              <HistoryListRightInner>
                <HistoryListRightInnerImage>
                  {mediaType === "image" && <img src={mediaDetail} alt="" />}
                  {mediaType === "video" && (
                    <video key={mediaDetail} width="750" height="500" controls>
                      <source src={mediaDetail} type="video/mp4" />
                    </video>
                  )}
                </HistoryListRightInnerImage>

                <HistoryListRightBlockDetail>
                  {dataTime && (
                    <div className="content-time">
                      <p>{moment(parseInt(dataTime)).format("MMM, Do YYYY")}</p>
                      <p className="count-creadit">Credit: {showCredit}</p>
                    </div>
                  )}
                  <h3>Description</h3>
                  <p>{textDescription}</p>
                </HistoryListRightBlockDetail>
                <ArtificialButtons>
                  <ArtificialButtonRegenerate>
                    Re-generate
                  </ArtificialButtonRegenerate>
                  <ArtificialButtonSave
                    onClick={() => handleDownloadImage(imgList)}
                  >
                    Save {mediaType === "image" ? "Image" : "Video"}
                  </ArtificialButtonSave>
                </ArtificialButtons>
              </HistoryListRightInner>
            </ArtificialContentRight>
          )}
        </ArtificialWrapper>
      </HistoryContainer>
      {isMobile && (
        <ModalMobile
          isModalOpen={isModalOpen}
          dataItem={dataItem}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

const listData = [
  {
    id: 1,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 1",
  },
  {
    id: 2,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 2",
  },
  {
    id: 3,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 3",
  },
  {
    id: 4,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 4",
  },
  {
    id: 5,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 5",
  },
  {
    id: 6,
    img: "/assets/artificial/img_dummy.png",
    text: "Text Dommy 6",
  },
];

export default History;
