import {
  AddressText,
  AddressTitle,
  BlockLogo,
  FooterAIMETAFI,
  FooterAddress,
  FooterBlock,
  FooterWrapper,
  LogoFooter,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import social_1 from "../../assets/Footer/social_1.svg";
import social_2 from "../../assets/Footer/social_2.svg";
import social_3 from "../../assets/Footer/social_3.svg";
import social_4 from "../../assets/Footer/social_4.svg";
import social_5 from "../../assets/Footer/social_5.svg";
import social_6 from "../../assets/Footer/social_6.svg";
import { useTranslation } from "react-i18next";

const Footer = ({ footerLayout, footerWrapper }: any) => {
  const { t } = useTranslation();
  const LIST_LINK = [
    {
      title: `${t("Navigation")}`,
      links: [
        {
          label: `${t("Home")}`,
          link: "/",
        },
        {
          label: `${t("About")}`,
          link: "/",
        },
        {
          label: `${t("Features")}`,
          link: "/",
        },
        {
          label: `${t("Integration")}`,
          link: "/",
        },
      ],
    },
    {
      title: `${t("Get started")}`,
      links: [
        {
          label: `${t("Create Account")}`,
          link: "/",
        },
        {
          label: `${t("Sign In")}`,
          link: "/",
        },
        {
          label: `${t("Forgot Password")}`,
          link: "/",
        },
      ],
    },
    {
      title: `${t("Policy")}`,
      links: [
        {
          label: `${t("Terms and Conditions")}`,
          link: "/",
        },
        {
          label: `${t("Privacy policy")}`,
          link: "/",
        },
      ],
    },
  ];

  return (
    <FooterAIMETAFI style={footerLayout}>
      <FooterWrapper style={footerWrapper}>
        <BlockLogo>
          <LogoFooter>
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </LogoFooter>
          <p>AIMETAFI © 2024</p>
          <p>
            {t(
              "AIMETAFI is a groundbreaking platform that merges AI, DeFi, and social features to create a dynamic ecosystem where users can connect, create, invest, and shop in a decentralized and empowering way. Think of it as your gateway to a future where you own your data, control your finances, and unleash your creativity."
            )}
          </p>
        </BlockLogo>
        <div className="block-list-link">
          {LIST_LINK.map((item: any, index: number) => {
            return (
              <div key={index} className="list-link">
                <h3>{item.title}</h3>
                <ul>
                  {item.links.map((link: any, kdex: number) => {
                    return (
                      <li key={kdex}>
                        <a href={link.link}>{link.label}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <FooterAddress>
          <FooterBlock>
            <AddressTitle>{t("AIMETAFI Address:")}</AddressTitle>
            <AddressText>
              {t(
                "101 Upper Cross Street, People's Park Center #05-29, Singapore 058357"
              )}
            </AddressText>
          </FooterBlock>
          <FooterBlock>
            <AddressTitle>{t("License Registration Address:")}</AddressTitle>
            <AddressText>
              {t("18 Sin Ming Lane, Midview City, #07-13 Singapore 573960")}
            </AddressText>
          </FooterBlock>
        </FooterAddress>
        <div className="list-social">
          <div className="icon-social">
            {footerSocial.map((item: any, index: number) => {
              return (
                <a key={index} href={item.link}>
                  <img src={item.img} alt="img" />
                </a>
              );
            })}
          </div>
          <div className="description">
            <p>{t("AIMETAFI All right Reserved")}</p>
          </div>
        </div>
      </FooterWrapper>
    </FooterAIMETAFI>
  );
};

const footerSocial = [
  {
    id: 1,
    img: social_1,
    link: "https://t.me/aimetafichannel",
  },
  {
    id: 2,
    img: social_2,
    link: "https://twitter.com/ai_metafi",
  },
  {
    id: 3,
    img: social_6,
    link: "https://www.youtube.com/@AiMetaFi",
  },
  {
    id: 4,
    img: social_3,
    link: "https://link3.to/aimetafi",
  },
  {
    id: 5,
    img: social_4,
    link: "https://galxe.com/AIMetaFi",
  },
  {
    id: 6,
    img: social_5,
    link: "https://zealy.io/c/aimetafi/questboard",
  },
];

export default Footer;
