import Marquee from "react-fast-marquee";
import {
  PartnerContainer,
  PartnerItem,
  PartnerMarquee,
  PartnerTitle,
  PartnerWrapper,
} from "./styled";
import partner_1 from "../../../assets/Home/partner/partner_1.png";
import partner_2 from "../../../assets/Home/partner/partner_2.png";
import partner_3 from "../../../assets/Home/partner/partner_3.png";
import partner_4 from "../../../assets/Home/partner/partner_4.png";
import partner_5 from "../../../assets/Home/partner/partner_5.png";
import { AnimatedText } from "../../../components/TextAnimation";
import { useTranslation } from "react-i18next";

const Partner = () => {
  const { t } = useTranslation();
  return (
    <PartnerContainer>
      <PartnerWrapper>
        <PartnerTitle data-aos="fade-right">
          <AnimatedText
            el="p"
            text={`${t("Our Partners")}`}
            repeatDelay={10000}
          />
        </PartnerTitle>
        <PartnerMarquee>
          <Marquee direction="left">
            {partnerData.map((item, index) => {
              return (
                <PartnerItem key={index}>
                  <a href={item.href} rel="noreferrer" target="_blank">
                    <img src={item.img} alt="logo" />
                  </a>
                </PartnerItem>
              );
            })}
          </Marquee>
        </PartnerMarquee>
      </PartnerWrapper>
    </PartnerContainer>
  );
};

const partnerData = [
  {
    img: partner_1,
    href: "#",
  },
  {
    img: partner_2,
    href: "#",
  },
  {
    img: partner_3,
    href: "#",
  },
  {
    img: partner_4,
    href: "#",
  },
  {
    img: partner_5,
    href: "#",
  },
  {
    img: partner_1,
    href: "#",
  },
  {
    img: partner_2,
    href: "#",
  },
  {
    img: partner_3,
    href: "#",
  },
  {
    img: partner_4,
    href: "#",
  },
];

export default Partner;
