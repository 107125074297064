import { IPFS } from "../contract/ipfs";

export const convertIpfsToObjHash = (ipfs: string) => {
  let objHash = null;
  switch (ipfs) {
    case IPFS.brozen.key:
      objHash = IPFS.brozen;
      break;
    case IPFS.silver.key:
      objHash = IPFS.silver;
      break;
    case IPFS.gold.key:
      objHash = IPFS.gold;
      break;

    default:
      objHash = IPFS.gold;
      break;
  }
  return objHash;
};
