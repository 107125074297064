import { styled } from "styled-components";

export const EcosystemStyle = styled.div`
  position: relative;
  margin-bottom: 300px;
  z-index: 5;
  /* overflow: hidden; */
  @media screen and (max-width: 767px) {
    margin-bottom: 200px;
  }
  .container {
    max-width: 1160px;
    margin: 0 auto;
  }
  .title {
    margin-top: 60px;
    padding: 60px 0;
    padding-bottom: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .eco-system {
      width: 50%;
      button {
        margin-bottom: 18px;
      }
    }
    .checkout-prod {
      p {
        max-width: 324px;
        color: #a0aee5;
        font-size: 12px;
        letter-spacing: 0.268px;
        margin-bottom: 10px;
      }
      .btn-and-icon-light {
        button {
          width: 176.72px;
          height: 41.018px;
        }
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    @media screen and (max-width: 1023px) {
      padding: 60px 20px;
      .sm {
        display: none;
      }
    }
    @media screen and (max-width: 769px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .eco-system,
      .checkout-prod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          text-align: center;
        }
        .btn-and-icon-light {
          margin-top: 20px;
          .image {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 481px) {
      display: flex;
      flex-direction: column;
      padding: 60px 20px;
      gap: 20px;
    }
  }
  .content {
    position: relative;
    z-index: 3;
    .row {
      display: flex;
      justify-content: center;
      gap: 22px;
      &:nth-child(2) {
        margin-top: 22px;
        flex-direction: row-reverse;
      }
      .card-style {
        padding: 5px;
        transition: all.3s;
        &:nth-child(1) {
          max-width: 605px;
        }
        &:nth-child(2) {
          max-width: calc(100% - 605px);
        }
        &:hover {
          transition: all.3s;
          background-size: cover;
          background-repeat: no-repeat;
          /* background-image: url("/assets/background/bg-navbar.png"); */
        }
        .header {
          padding: 22px 22px 0;
          margin-bottom: 20px;
          h3 {
            color: #ecf0ff;
            font-size: 20.888px;
            font-style: normal;
            font-weight: 400;
            margin: 10px 0;
          }
          p {
            color: #a0aee5;
            font-size: 14px;
          }
        }
        .image {
          width: 100%;
          border-radius: 16px;
          img {
            width: 100%;
            border-radius: 16px;
          }
        }
      }
      @media screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        gap: 16px;
        &:nth-child(2) {
          flex-direction: column;
          align-items: center;
          padding: 0 20px;
        }
        .card-style {
          &:nth-child(1) {
            max-width: 100%;
          }
          &:nth-child(2) {
            max-width: 100%;
          }
        }
      }
    }
  }
`;

export const EcosystemBackground = styled.div`
  position: absolute;
  top: -110px;
  width: 100%;
  .img-round {
    width: 100%;
    position: relative;
    z-index: 3;
    &:after {
      z-index: 4;
      position: absolute;
      content: "";
      background: linear-gradient(
        90deg,
        #74bfff 11.01%,
        #a7d6f1 25.65%,
        #fac4b8 37.61%,
        #ffaf96 53.68%,
        #ff9bab 69.04%,
        #f09df0 81.09%,
        #c78fff 94.12%
      );
      opacity: 0.3;
      filter: blur(227px);
      width: 100%;
      height: 350px;
      position: absolute;
      bottom: -520px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
    }
  }
  .img-purple {
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 5px;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    top: -10vw;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    top: -60px;
  }
  @media screen and (max-width: 767px) {
    top: -20vw;
  }
`;
export const EcosystemStarRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 767px) {
    top: 0;
  }
`;
