import { gql, useMutation } from "@apollo/client";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import { RegisterContainer } from "./styled";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useMemo, useState } from "react";
import { TextForgot } from "../Login/styled";
import {
  AuthBlock,
  AuthForm,
  AuthLogo,
  ErrorState,
} from "../../../Layout/Auth/styled";
import auth_logo from "../../../assets/Auth/form_icon.png";
import InputCommon from "../../../components/Common/Input";
import { ButtonStyle } from "../../Homev2/styled";
import { BorderAnimation } from "../../../Layout/styled";
import StarUp from "../../../components/StarUp";
import Fingerprint2 from "fingerprintjs2";
import LoadingIcon from "../../../components/LoadingIcon";
import { useTranslation } from "react-i18next";

type FormValuesProps = {
  email: string;
  password: string;
  refCode: string;
};

const CHECK_REFCODE = gql`
  mutation checkRefcode($refCode: String!, $token: String!) {
    checkRefcode(refCode: $refCode, token: $token)
  }
`;

const UPSERT_USER = gql`
  mutation UpsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input)
  }
`;

const Register = () => {
  const { t } = useTranslation();
  const [checkRefcode] = useMutation(CHECK_REFCODE);
  const [upsertUser] = useMutation(UPSERT_USER);
  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password should be at least 6 characters"),
    refCode: yup.string().required("Referral is required"),
  });

  const [refCode, setRefCode] = useState("");
  const [mode, setMode] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const refCodeParam = searchParams.get("refCode");
    const modeParam = searchParams.get("mode");
    if (refCodeParam && modeParam) {
      setRefCode(refCodeParam);
      setMode(modeParam);
      setValue("refCode", refCodeParam);
    }
  }, []);

  const defaultValues = useMemo(
    () => ({
      email: "",
      password: "",
      refCode: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refCode]
  );

  const navigate = useNavigate();

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Verify Email
  const verifyEmail = async () => {
    await sendEmailVerification(auth.currentUser!);
    toast.success("Email Verification Sent. Please confirm email!");
  };

 

  // RECAPTCHA
  const [token, setToken] = useState("");
  const onRecaptchaChange = (token: any) => {
    setToken(token);
  };

  // Fingerprint2
  const [fingerprint, setFingerprint] = useState(null);
  useEffect(() => {
    Fingerprint2.get((components: Fingerprint2.Component[]) => {
      const fingerprint: any = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      setFingerprint(fingerprint);
    });
  }, []);

  // SUBMIT
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      // Check referral Code
      const checkRefCode = await checkRefcode({
        variables: {
          refCode: data.refCode,
          token:token,
        },
      }).catch((error) => {
        setLoading(false);
        if (
          error.message ===
          "Cannot return null for non-nullable field Mutation.checkRefcode."
        ) {
          toast.error("Invalid referral code");
        }
      });

      if (checkRefCode === undefined) {
        return;
      }

      // Create user
      await createUserWithEmailAndPassword(
        auth,
        data.email.trim(),
        data.password.trim()
      )
        .then((userCredential) => {
          const user = userCredential.user;
          if (user) {
            // Verify email
            verifyEmail();
            // Upser User
            upsertUser({
              variables: {
                input: {
                  email: auth.currentUser?.email,
                  photoURL: auth.currentUser?.photoURL,
                  refCode: refCode ? refCode : data.refCode,
                  token: token,
                  mode: mode ? mode : "left",
                  fingerprint,
                },
              },
            });
            setLoading(false);
            toast.success("Register success!!");
            navigate("/auth/login");
          }
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.message;
          if (errorMessage === "Firebase: Error (auth/email-already-in-use).") {
            toast.error("Email already exists");
          } else if (
            errorMessage === "Firebase: Error (auth/too-many-requests)."
          ) {
            toast.error("Register failed!. Please try again");
          }
        });
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
      if (error.message === "Firebase: Error (auth/too-many-requests).") {
        toast.error("Register failed!. Please try again");
      }
    }
  };

  return (
    <RegisterContainer>
      <AuthForm>
        <AuthLogo>
          <img src={auth_logo} alt="logo" />
        </AuthLogo>
        <BorderAnimation />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <AuthBlock>
                <div>
                  <label>
                    {t("Email")}
                    <span>*</span>
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        status={errors.email ? "error" : ""}
                        name="email"
                        placeHolder={t("Email")}
                      />
                    )}
                  />
                </div>
                {errors.email && (
                  <ErrorState>{errors.email?.message}</ErrorState>
                )}
              </AuthBlock>
              <AuthBlock>
                <div>
                  <label>
                    {t("Password")}
                    <span>*</span>
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        password
                        status={errors.password ? "error" : ""}
                        name="password"
                        placeHolder={t("Password")}
                      />
                    )}
                  />
                </div>
                {errors.password && (
                  <ErrorState>{errors.password?.message}</ErrorState>
                )}
              </AuthBlock>
              <AuthBlock>
                <div>
                  <label>
                    {t("Referral Code")}
                    <span>*</span>
                  </label>
                  <Controller
                    name="refCode"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        status={errors.refCode ? "error" : ""}
                        name="refCode"
                        placeHolder={t("Referral Code")}
                      />
                    )}
                  />
                </div>
                {errors.refCode && (
                  <ErrorState>{errors.refCode?.message}</ErrorState>
                )}
              </AuthBlock>
            </div>
            
            <ReCAPTCHA
              theme="dark"
              size="normal"
              sitekey="6Lfdo0spAAAAAHUy2jw7VLSVyyLUOBqJF-ksD9zG"
              onChange={onRecaptchaChange}
            />
            

         
          </div>
          <ButtonStyle
            style={{
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <BorderAnimation />
            <button type="submit">
              <StarUp />
              <span>
                {t("Register")}
                {loading && <LoadingIcon />}
              </span>
            </button>
          </ButtonStyle>
        </form>
        <TextForgot>
          {t("Already have an account?")}{" "}
          <NavLink to="/auth/login">{t("Sign In")}</NavLink>
        </TextForgot>
      </AuthForm>
    </RegisterContainer>
  );
};

export default Register;
