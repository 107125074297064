import { styled } from "styled-components";
import { BorderAnimation } from "../../Layout/styled";

export const HomeWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 1023px) {
    overflow: hidden;
  }
`;

export const TokenNoMicWrapper = styled.div`
  position: relative;
  z-index: 3;
  background-color: #000;
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: -1;
    bottom: 100px;
    width: 100%;
    height: 1500px;
    background: radial-gradient(
      47.71% 47.71% at 50% 60%,
      rgba(83, 35, 144, 0.67) 0%,
      rgba(65, 73, 137, 0) 100%
    );
    filter: blur(200px);
  }
  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 1200px;
    width: 100%;
    height: 350px;
    background: linear-gradient(
      90deg,
      #74bfff 11.01%,
      #a7d6f1 25.65%,
      #180a23 37.61%,
      #150f2f 53.68%,
      #ff9bab 69.04%,
      #f09df0 81.09%,
      #c78fff 94.12%
    );
    filter: blur(330px);
  }
  @media screen and (min-width: 1024px) {
    border-top-left-radius: 12vw;
    border-top-right-radius: 12vw;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    border-top-left-radius: 19vw;
    border-top-right-radius: 19vw;
  }
  @media screen and (max-width: 767px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const HomeStyle = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    max-width: 800px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 15px !important;
    }
  }
`;
export const ButtonStyle = styled.div`
  position: relative;
  button {
    cursor: pointer;
    display: flex;
    padding: 5.966px 14.916px 5.959px 14.916px;
    justify-content: center;
    align-items: center;
    gap: 2.983px;
    border-radius: 44.747px;
    background: rgba(188, 200, 255, 0.01);
    box-shadow: 0px 0px 29.831px 0px rgba(153, 146, 217, 0.23) inset;
    backdrop-filter: blur(6px);
    border: none;
    overflow: hidden;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #a1aee5;
      text-align: center;
      font-size: 12px;
      line-height: 15.214px;
      letter-spacing: 0.254px;
    }
  }
`;

export const TitleCommon = styled.h1`
  font-size: 36px;
  line-height: 44.747px; /* 124.372% */
  white-space: pre-line;
  background: linear-gradient(0deg, #b6c2f1 0%, #ebefff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 769px) {
    font-size: 32px;
  }
`;

export const CardStyle = styled.div`
  border-radius: 20px;
  border: 1px solid rgba(161, 174, 229, 0.16);
  overflow: hidden;
  position: relative;
  z-index: 0;
  opacity: 0;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  ${BorderAnimation} {
    z-index: 5;
  }
  &.ai-metafi {
    opacity: 1 !important;
  }
  @media screen and (max-width: 1023px) {
    opacity: 1;
  }
`;
export const HomeSpline = styled.div`
  position: relative;
  height: 150vh;
`;
export const HomeSplineWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  transition: all 0.35s;
  .spline {
    position: absolute;
    left: 0;
    top: 0;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;
