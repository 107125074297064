import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";

export const HeaderBody = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.5s ease;
  border-bottom: 1px solid #42307d;
  background: rgba(7, 4, 38, 0.9);
  backdrop-filter: blur(13);
`;
export const HeaderContainer = styled.header`
  position: relative;
  max-width: 1420px;
  padding: 22px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    width: calc(100% - 20px);
  }
  @media screen and (max-width: 767px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
`;
export const HeaderLogo = styled.div`
  width: 210px;
  height: auto;
  flex-shrink: 0;
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
  }
  @media screen and (max-width: 767px) {
    width: 110px;
  }
`;
export const HeaderLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  & > li {
    display: block;
    a {
      display: block;
      text-decoration: none;
      font-size: 20px;
      transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      color: #fff;
      &:hover {
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
          0 0 10px rgba(255, 255, 255, 0.5);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    gap: 50px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      a {
        font-size: 40px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      a {
        font-size: 30px;
      }
    }
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  &.menu-fixed {
    position: fixed;
    left: 0;
    top: 95px;
    flex-direction: column;
    height: calc(100vh - 95px);
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(13px);
    color: #fff;
    transition: transform 0.75s ease;
    transform: translateX(150%);
    z-index: -1;
    overflow-y: auto;
    ${HeaderLinks} {
      flex-direction: column;
    }
    ${ButtonCommon} {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767px) {
    &.menu-fixed {
      top: 80px;
      height: calc(100vh - 75px);
    }
  }
`;
export const HeaderInner = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
  @media screen and (max-width: 1023px) {
    overflow-y: auto;
    width: 100%;
    padding: 50px 0;
  }
`;
export const WrapperHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  min-height: 40px;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border: 1px solid rgba(161, 174, 229, 0.2);
  cursor: pointer;
  border-radius: 50px;
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const ButtonHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 20px;
  height: 20px;
  z-index: 99;
  & > span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: transform 0.25s ease;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  &.active {
    & > span {
      &:nth-child(1) {
        top: 4px;
        transform: rotateZ(45deg);
      }
      /* &:nth-child(2) {
        transform: translateX(10px) scale(0);
      } */
      &:nth-child(2) {
        top: -4px;
        transform: rotateZ(-45deg);
      }
    }
  }
`;
export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  ${ButtonCommon} {
    flex-shrink: 0;
  }
  @media screen and (max-width: 767px) {
    ${ButtonCommon} {
      width: 150px;
      height: 38px;
      font-size: 16px;
    }
  }
`;
export const ButtonWhiteList = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-height: 38px;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  background: #7f56d9;
`;
