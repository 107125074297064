import {
  AuthBlock,
  AuthForm,
  AuthLogo,
  ErrorState,
} from "../../../../Layout/Auth/styled";
import { BorderAnimation } from "../../../../Layout/styled";
import { LoginContainer, TextForgot } from "../styled";
import auth_logo from "../../../../assets/Auth/form_icon.png";
import { Controller, useForm } from "react-hook-form";
import InputCommon from "../../../../components/Common/Input";
import { ButtonStyle } from "../../../Homev2/styled";
import StarUp from "../../../../components/StarUp";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";
import LoadingIcon from "../../../../components/LoadingIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type FormValuesProps = {
  code: string;
};

const VERIFY_2FA_LOGIN = gql`
  mutation verify2FALogin($token: String!) {
    verify2FALogin(token: $token)
  }
`;

const Login2FA = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth: any = getAuth();
  const [verify2FALogin] = useMutation(VERIFY_2FA_LOGIN);
  const schema = yup.object().shape({
    code: yup.string().required("Code 2FA is required"),
  });

  const defaultValues = {
    code: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Submit
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken(true);
        await verify2FALogin({
          variables: {
            token: data.code,
          },
        });
        setLoading(false);
        localStorage.setItem("idToken", idToken);
        navigate("/dashboard");
      }
    } catch (error: any) {
      setLoading(false);
      let errMsg = error.toString().replaceAll("ApolloError:", "");
      toast.error(errMsg);
    }
  };

  return (
    <LoginContainer>
      <AuthForm>
        <AuthLogo>
          <img src={auth_logo} alt="logo" />
        </AuthLogo>
        <BorderAnimation />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <AuthBlock>
                <div>
                  <label>{t("Two-Factor Authentication")}</label>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        status={errors.code ? "error" : ""}
                        name="email"
                        placeHolder={t("Enter 2FA Code")}
                      />
                    )}
                  />
                </div>
                {errors.code && <ErrorState>{errors.code?.message}</ErrorState>}
              </AuthBlock>
            </div>
          </div>
          <ButtonStyle
            style={{
              marginBottom: "20px",
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <BorderAnimation />
            <button type="submit">
              <StarUp />
              <span>
                {t("Verify")}
                {loading && <LoadingIcon />}
              </span>
            </button>
          </ButtonStyle>
          <ButtonStyle>
            <BorderAnimation />
            <button
              onClick={() => {
                navigate("/auth/login");
              }}
            >
              <StarUp />
              <span>{t("Back To Login")}</span>
            </button>
          </ButtonStyle>
        </form>
        <TextForgot>
          <Link to="/auth/reset-2fa">{t("Go to Reset 2FA")}</Link>
        </TextForgot>
      </AuthForm>
    </LoginContainer>
  );
};

export default Login2FA;
