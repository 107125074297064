import { styled } from "styled-components";

export const BannerStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  .container {
    max-width: 1160px;
    margin: 0 auto;
    .button-update {
      max-width: 240px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      &:nth-child(5) {
        img {
          width: 100%;
        }
      }
    }
    .banner-title {
      h1 {
        text-align: left;
        font-size: 45.155px;
        font-style: normal;
        font-weight: 400;
        line-height: 55.486px;
        letter-spacing: -0.925px;
        background: linear-gradient(0deg, #a1aee5 0%, #ecf0ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .text-inline {
        display: flex;
        justify-content: center;
      }
      .earn-big {
        margin-left: 15px;
        display: inline;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 55.486px;
        padding: 0px 16px;
        border-radius: 78px;
        color: #fff;
        background: rgba(106, 104, 166, 0.22);
        span {
          background: linear-gradient(0deg, #a1aee5 0%, #ecf0ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        color: #a0aee5;
        text-align: center;
        font-family: Inter;
        font-size: 13.634px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.119px;
        letter-spacing: 0.298px;
      }
    }
    .banner-img {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 20px;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 20px;
        border: 1px solid rgba(186, 207, 247, 0.12);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 1023px) {
      max-width: 800px;
    }

    @media screen and (max-width: 830px) {
      max-width: 600px;
    }

    @media screen and (max-width: 481px) {
      max-width: 320px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .banner-title {
        h1 {
          font-size: 32px;
          line-height: 48px;
          flex-direction: column;
          gap: 20px;
          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 0;
            margin-bottom: 21px;
          }
        }
        p {
          &:nth-child(4) {
            margin-bottom: 10px;
          }
        }
        .earn-big {
          margin: 10px auto;
        }
        .text-inline {
          flex-direction: column;
        }
      }
    }
  }
`;
export const LogoKeyvisual = styled.div`
  position: relative;
  z-index: 10;
  max-width: 148px;
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
`;
export const BannerContainer = styled.div`
  padding-top: 170px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
`;

export const BannerContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
  width: 100%;
  @media screen and (max-width: 1075px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const BannerLeftStyle = styled.div`
  h1 {
    margin-top: 20px;
    text-align: left;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #a1aee5 0%, #ecf0ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    display: flex;
    width: 355.68px;
    flex-direction: column;
    justify-content: center;
    color: #a0aee5;
    font-size: 15px;
    line-height: 23.119px;
    letter-spacing: 0.298px;
  }
  @media screen and (max-width: 1075px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1,
    h2,
    p {
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 28px;
    }
  }
`;

export const BannerRightStyle = styled.div`
  position: relative;
`;

export const BannerImgLeftStyle = styled.div`
  position: absolute;
  bottom: 50%;
  left: -50px;
  max-width: 60px;
  animation: upAndDown 3s infinite linear;
  @keyframes upAndDown {
    0% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-50px);
    }
  }
`;
export const BannerImgCenterStyle = styled.div`
  animation: upAndDownCenter 5s infinite linear;
  max-width: 450px;
  @keyframes upAndDownCenter {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 300px;
  }
`;

export const BannerImgRightStyle = styled.div`
  position: absolute;
  right: -20px;
  top: 0;
  max-width: 90px;
  animation: upAndDownRight 3s infinite linear;
  @keyframes upAndDownRight {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

export const ButtonScrollDownWraperStyle = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1075px) {
    justify-content: center;
  }
`

export const ButtonScrollDownStyle = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px dashed rgb(187, 70, 255, 0.8);
    background: #0E0C25;
    cursor: pointer;
  }
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    background: linear-gradient(0deg, #A1AEE5 0%, #ECF0FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1075px) {
    margin-left: 0;
    margin-top: 30px;
  }
`