import React from "react";
import {
  ArtificialContentRight,
  ArtificialContentRightInner,
  ArtificialListVideo,
  ArtificialTitleInfo,
} from "../ArtificialIntelligence/ArtificialIntelligenceVideo/styled";

const Example = () => {
  return (
    <ArtificialContentRight>
      <ArtificialTitleInfo>
        <p>Our Example</p>
        <div className="image">
          <img src="/assets/artificial/icn_paper.svg" alt="" />
        </div>
      </ArtificialTitleInfo>
      <ArtificialContentRightInner>
        <ArtificialListVideo>
          {listVideo.map((item: any, index: number) => (
            <li key={index}>
              <img src={item.img} alt={item.text} width={175} height={175} />
              <p>{item.text}</p>
            </li>
          ))}
        </ArtificialListVideo>
      </ArtificialContentRightInner>
    </ArtificialContentRight>
  );
};

const listVideo = [
  {
    img: "/assets/artificial/Example_01.jpeg",
    text: "A masterpiece in the form of a wood forest world inside a beautiful, illuminated miniature forest",
  },
  {
    img: "/assets/artificial/Example_02.jpeg",
    text: "gigantic storm dragon, teeth, horns, long tail, wings, mountains, lightning aura,fantasy,solo focus,epic",
  },
  {
    img: "/assets/artificial/Example_03.jpeg",
    text: "enchanting scene, a steaming cup of coffee, the liquid within the cup forms a swirling cosmic galaxy, The coffee surface is a mesmerizing mix of deep purples and blues and pinks dotted with stars and nebulae, fit the vastness of space inside the cup, The cup rests on a saucer that reflects the celestial patterns, surrounded by tiny star-shaped cookies and cosmic-themed decorations, The atmosphere is magical and dreamy,",
  },
  {
    img: "/assets/artificial/Example_04.jpeg",
    text: "A futuristic cityscape with towering neon skyscrapers, flying vehicles, and holographic advertisements, set against a twilight sky with distant planets visible.",
  },
  {
    img: "/assets/artificial/Example_05.jpeg",
    text: "A dreamlike scene where gravity is reversed, with floating objects and twisted landscapes merging in impossible ways under a sky of swirling colors.",
  },
  {
    img: "/assets/artificial/Example_06.jpeg",
    text: "A vibrant marketplace in an ancient civilization, bustling with people in period-appropriate attire, with traditional architecture and colorful goods displayed.",
  },
];

export default Example;
