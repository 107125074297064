import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  color: #fff;
  input {
    color: #fff;
  }
`;
export const TextForgot = styled.p`
  color: #a0aee5;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-top: 20px;
  a {
    color: #a0aee5;
    font-weight: 700;
  }
`;
