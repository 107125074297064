import { useWaitForTransaction } from "wagmi";
const useTransactionHash = (hashTx: any) => {
  const { data, isError, isLoading } = useWaitForTransaction({
    hash: hashTx,
  });

  return {
    isLoading,
    isError,
    data,
  };
};

export default useTransactionHash;
