import { bsc } from "viem/chains";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { parseGwei } from "viem";

const useMintNFT = (payment: any, address: any, abi: any) => {
  const { config, isError: isPrepareError } = usePrepareContractWrite({
    address,
    abi,
    functionName: "mint",
    args: [1],
    value: payment,
    chainId: bsc.id,
    gas: BigInt(1_000_000),
    gasPrice: parseGwei("3"),
  });

  const { data, isLoading, isSuccess, isError, error, writeAsync, write } =
    useContractWrite(config);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    writeAsync,
    write,
    isPrepareError,
  };
};

export default useMintNFT;
