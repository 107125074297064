import { useState } from "react";
import {
  FaqAnswer,
  FaqLeft,
  FaqList,
  FaqWrapper,
  FaqWrapperInner,
} from "./styled";
import { TextCommon } from "../../../Layout/styled";
import { ButtonStyle } from "../../Homev2/styled";
import StarUp from "../../../components/StarUp";
import { AnimatedText } from "../../../components/TextAnimation";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [idClicked, setIdClicked] = useState<any>();

  const faqData = [
    {
      id: 1,
      title: `${t("What is AIMETAFI?")}`,
      answer: {
        title: "",
        content:
        `${t("AIMETAFI is a groundbreaking platform that merges AI, DeFi, and social features to create a dynamic ecosystem where users can connect, create, invest, and shop in a decentralized and empowering way. Think of it as your gateway to a future where you own your data, control your finances, and unleash your creativity.")}`,
      },
    },
    {
      id: 2,
      title: `${t("What are some key features of AIMETAFI?")}`,
      answer: {
        title: "",
        list: [
          `${t("AI-powered content creation: Craft captivating articles, visuals, and videos with the help of our intelligent AI assistants.")}`,
          `${t("SocialFi: Build meaningful connections, collaborate on projects, and share profits through a decentralized economic model.")}`,
          `${t("AI-powered crypto exchange: Trade confidently with our AI-driven bot and data-driven insights.")}`,
          `${t("Multichain wallet: Manage your digital assets across various blockchains securely and seamlessly.")}`,
          `${t("AI shopping assistant: Discover products, compare prices, and make informed purchases with personalized recommendations.")}`,
        ],
      },
    },
    {
      id: 3,
      title: `${t("How does AIMETAFI benefit creators?")}`,
      answer: {
        title: `${t("AIMETAFI empowers creators by:")}`,
        list: [
          `${t("Boosting their productivity and creativity with AI tools")}`,
          `${t("Connecting them directly with their audience and fostering community")}`,
          `${t("Providing opportunities to monetize their content and co-own projects")}`,
        ],
      },
    },
    {
      id: 4,
      title: `${t("How does AIMETAFI benefit investors?")}`,
      answer: {
        title: `${t("AIMETAFI offers investors:")}`,
        list: [
          `${t("A secure and transparent crypto exchange with AI-powered insights.")}`,
          `${t("Access to diverse investment opportunities across multiple blockchains.")}`,
          `${t("The chance to participate in a growing and dynamic ecosystem with early-mover advantages.")}`,
        ],
      },
    },
    {
      id: 5,
      title: `${t("What are $AMFI tokens and how can I use them?")}`,
      answer: {
        title:
          `${t("$AMFI tokens are the native currency of the AIMETAFI ecosystem. You can use them for:")}`,
        list: [
          `${t("Staking and earning rewards.")}`,
          `${t("Making purchases within the platform.")}`,
          `${t("Participating in governance decisions.")}`,
        ],
      },
    },
  ];
  return (
    <FaqWrapper>
      <FaqWrapperInner>
        <FaqLeft>
          <div className="title">
            <AnimatedText
              data-aos="fade-right"
              el="h2"
              text={t("Frequently Asked \nQuestions")}
            />
          </div>
          <div className="content">
            <p data-aos="fade-right">
              {t(
                "Explore our FAQs and get ready to dive deeper into the AIMETAFI ecosystem"
              )}
            </p>
            <ButtonStyle data-aos="fade-right">
              <button>
                <StarUp />
                <span>{t("Get Started")}</span>
                <img
                  src="/assets/icons/icon-go-staking.png"
                  alt="icon-go-staking"
                />
              </button>
            </ButtonStyle>
          </div>
        </FaqLeft>
        <FaqList data-aos="fade-left" clicked={clicked}>
          {faqData.map((item, index) => {
            return (
              <li
                className={clicked && item.id === idClicked ? "active" : ""}
                key={index}
                onClick={() => {
                  setClicked(item.id === idClicked ? !clicked : true);
                  setIdClicked(item.id);
                }}
              >
                <TextCommon>{item.title}</TextCommon>
                <FaqAnswer clicked={clicked}>
                  {clicked && item.id === idClicked ? (
                    <>
                      {item.answer.content ? (
                        item.answer.content
                      ) : (
                        <div className="answer-with-list">
                          <p
                            style={{
                              fontSize: "13px",
                              marginBottom: "5px",
                              lineHeight: "150%",
                            }}
                          >
                            {t(`${item.answer.title}`)}
                          </p>
                          <ul
                            style={{
                              listStyleType: "disc",
                              lineHeight: "150%",
                              paddingLeft: "20px",
                            }}
                          >
                            {item.answer.list?.map(
                              (ans: any, index: number) => {
                                return <li key={index}>{ans}</li>;
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </FaqAnswer>
              </li>
            );
          })}
        </FaqList>
      </FaqWrapperInner>
    </FaqWrapper>
  );
};

export default Faq;
