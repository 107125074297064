import { styled } from "styled-components";

export const ClearlyRoadmapStyle = styled.div`
  position: relative;
  padding: 0 80px;
  @media screen and (max-width: 1023px) {
    padding: 0px;
  }
  .gif-for-ai-metafi {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("/assets/background/bg-of-road.png");
    background-position: center;
    background-size: 100% 120%;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: 10px;
    .gif {
      width: 320px;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: -100px;
      }
    }
  }
  .title-amfi-token {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: -100px;
    h1 {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    p {
      color: #a0aee5;
      text-align: center;
      font-family: Inter;
      font-size: 12.061px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.405px; /* 169.183% */
      letter-spacing: 0.268px;
    }
  }
  .list-card {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    .card-item {
      position: relative;
      background: rgba(161, 174, 229, 0.1);
      background-image: url("/assets/background/bg-card.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 22px;
      width: calc(33.33% - 20px);
      border-radius: 20px;
      transition: all.3s;
      &:hover {
        transition: all.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/assets/background/bg-navbar.png");
      }
      .image {
        width: 22px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      h3 {
        color: #ecf0ff;
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      p {
        color: #a0aee5;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.239px;
      }
    }
    @media screen and (max-width: 767px) {
      gap: 16px;
      align-items: unset;
      padding: 0 10px;
      .card-item {
        padding: 15px;
        h3 {
          margin-bottom: 10px;
        }
        p {
          font-size: 10px !important;
          max-width: 220px;
          letter-spacing: normal;
        }
        height: auto;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          width: calc(50% - 10px);
          word-break: break-word;
        }
        &:nth-child(3),
        &:nth-child(6) {
          width: 100%;
        }
      }
    }
  }
  .button-Enhanced {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
  }
`;

export const ImageLeftStyle = styled.div`
  position: absolute;
  top: -280px;
  left: -650px;
  transition: all 0.5s ease-in-out;
  animation: upDownAndRotate 20s infinite linear;
  @keyframes upDownAndRotate {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(50px) rotate(180deg);
    }
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ImageRightStyle = styled.div`
  position: absolute;
  right: 0;
  top: -300px;
  opacity: 0.8;
  pointer-events: none;
`;

export const ImageRightV2Style = styled.div`
  position: absolute;
  right: -450px;
  top: -400px;
  animation: upAndDown 5s infinite linear;
  pointer-events: none;
  @keyframes upAndDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
