import { ButtonStyle } from "../../page/Homev2/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import {
  AMFITeamCartImageStyle,
  AMFITeamCartNameStyle,
  AMFITeamCartStyle,
  AMFITeamCartStyleInner,
  AMFITeamContainerStyle,
  AMFITeamListCartStyle,
  AMFITeamStyle,
  AMFITeamTitleStyle,
} from "./styled";
import twitterICON from "../../assets/Footer/social_2.svg";
import { useTranslation } from "react-i18next";

export default function AMFITeam() {
  const { t } = useTranslation();
  const TEAM_MEMBER = [
    {
      name: "Christoper Danker",
      image: "/assets/images/img-danker.png",
      position: "Founder & CEO",
      linkSocial: {
        twitter: "https://twitter.com/SeIenaGomez__",
      },
    },
    {
      name: "Alex Morgan",
      image: "/assets/images/img-morgan.png",
      position: "CMO & Co-Founder",
      linkSocial: {
        twitter: "https://twitter.com/AlexMor9an",
      },
    },
    {
      name: "Lisa Kim",
      image: "/assets/images/img-kim.png",
      position: `${t("Chief Business Development Officer")}`,
      linkSocial: {
        twitter: "#",
      },
    },
  ];
  return (
    <AMFITeamStyle>
      <AMFITeamContainerStyle>
        <AMFITeamTitleStyle>
          <ButtonStyle data-aos="fade-up">
            <button>
              <StarUp />
              <span>{t("Enhanced Solutions")}</span>
              <img
                src="/assets/icons/icon-go-staking.png"
                alt="icon-go-staking"
              />
            </button>
          </ButtonStyle>
          <AnimatedText data-aos="fade-up" el="h2" text={t("AIMETAFI Team")} />
        </AMFITeamTitleStyle>
        <AMFITeamListCartStyle>
          {TEAM_MEMBER.map((item: any, index: number) => {
            return (
              <AMFITeamCartStyle
                data-aos="fade-up"
                data-aos-delay={index * 150}
              >
                <AMFITeamCartStyleInner>
                  <AMFITeamCartImageStyle>
                    <img src={item.image} alt={item.name} />
                  </AMFITeamCartImageStyle>
                  <AMFITeamCartNameStyle>
                    <h1>{item.name}</h1>
                    <h4>{item.position}</h4>
                    <a
                      href={item.linkSocial.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterICON} alt="twitter" />
                    </a>
                  </AMFITeamCartNameStyle>
                </AMFITeamCartStyleInner>
              </AMFITeamCartStyle>
            );
          })}
        </AMFITeamListCartStyle>
      </AMFITeamContainerStyle>
    </AMFITeamStyle>
  );
}
