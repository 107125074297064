import { styled } from "styled-components";
import { ButtonStyle } from "../../page/Homev2/styled";

export const VersionAndMissionStyle = styled.div`
  padding: 60px 84px;
  ${ButtonStyle} {
    display: flex;
    justify-content: center;
  }
  h1 {
    text-align: center;
    margin-top: 26px;
    font-size: 36px;
    line-height: 44.747px;
    background: linear-gradient(0deg, #b6c2f1 0%, #ebefff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;
  }
  .btn-we-see {
    button {
      display: flex;
      padding: 5.966px 14.916px 5.959px 14.916px;
      justify-content: center;
      align-items: center;
      gap: 2.983px;
      border-radius: 44.747px;
      background: rgba(188, 200, 255, 0.01);
      box-shadow: 0px 0px 29.831px 0px rgba(153, 146, 217, 0.23) inset;
      backdrop-filter: blur(5px);
      border: none;
      span {
        color: #a1aee5;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.254px;
      }
    }
  }
  .version-mission {
    display: flex;
    justify-content: center;
    gap: 21px;
    width: 100%;
    .our-item {
      max-width: 412px;
      border-radius: 20px;
      border: 1px solid rgba(183, 194, 241, 0.12);
      background: url("/assets/background/bg-our-item.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 32px 28px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      position: relative;
      &:hover {
        background: url("/assets/background/bg-our-item-hover.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        transition: all.3s;
      }
      h3 {
        font-size: 15px;
        font-weight: 700;
        line-height: 22.672px;
        letter-spacing: 0.298px;
        background: linear-gradient(180deg, #b6c2f1 0%, #ebefff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        color: #a0aee5;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.3px;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-top: 12px;
      margin-bottom: 30px;
    }
    .version-mission {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  @media screen and (max-width: 481px) {
    .version-mission {
      .our-item {
        max-width: 100%;
      }
    }
  }
`;
