import { useTranslation } from "react-i18next";
import { BorderAnimation } from "../../Layout/styled";
import { ButtonStyle, CardStyle } from "../../page/Homev2/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import { EcosystemStarRight, EcosystemStyle } from "./styled";

export default function Ecosystem() {
  const { t } = useTranslation();
  const ROW_ONE = [
    {
      icon: "/assets/icons/icon-social.png",
      title: `${t("AIMETAFI SocialFi Mobile App")}`,
      content: `${t(
        "Your All-in-One web3 social hub. AIMETAFI SocialFi App transcends the limitations of conventional social platforms, offering a multitude of features that foster meaningful connections, empower creators, and unlock the potential of web3 for everyone"
      )}`,
      image: "/assets/images/img-social.png",
    },
    {
      icon: "/assets/icons/icon-crytal.png",
      title: `${t("AIMETAFI Crypto Exchange & Swap")}`,
      content: `${t(
        "Trade smarter, faster, and more fun. Spot/future trading platform that seamlessly integrates AI-Powered bot trading technology."
      )}`,
      image: "/assets/images/img-macbook.png",
    },
  ];
  const ROW_TWO = [
    {
      icon: "/assets/icons/icon-stacking.png",
      title: `${t("AIMETAFI Staking")}`,
      content: `${t(
        "Unlock the earning potential of your $AMFI tokens and NFTs with AIMETAFI Staking. This innovative feature allows you to contribute to the stability and growth of the AIMETAFI ecosystem while generating passive income in the form of $AMFI tokens."
      )}`,
      image: "/assets/images/img-mac2.png",
    },
    {
      icon: "/assets/icons/icon-wallet.png",
      title: `${t("AIMETAFI Wallet")}`,
      content: `${t(
        "Own your digital future with AIMETAFI Wallet. This decentralized, multi-chain wallet puts you in control of your cryptocurrencies and NFTs"
      )}`,
      image: "/assets/images/img-ip.png",
    },
  ];
  return (
    <EcosystemStyle>
      <EcosystemStarRight>
        <img src="/assets/images/img-star-purple.png" alt="img-star-purple" />
      </EcosystemStarRight>
      <div className="container">
        <div className="title">
          <div className="eco-system">
            <ButtonStyle data-aos="fade-right">
              <button>
                <StarUp />
                <span>{t("Ecosystem")}</span>
                <img
                  src="/assets/icons/icon-go-staking.png"
                  alt="icon-go-staking"
                />
              </button>
            </ButtonStyle>
            <AnimatedText data-aos="fade-right" el="p" text={t("AIMETAFI")} />
            <AnimatedText data-aos="fade-right" el="p" text={t("Ecosystem")} />
          </div>
          <div className="checkout-prod" data-aos="fade-left">
            <p>
              {t(
                "Unleash your creative potential. Create, share, trade, shop and thrive in the AIMETAFI ecosystem"
              )}
            </p>
            <div className="btn-and-icon-light">
              <ButtonStyle>
                <button>
                  <StarUp />
                  <span>{t("Check Our Products")}</span>
                  <img
                    src="/assets/icons/icon-go-staking.png"
                    alt="icon-go-staking"
                  />
                </button>
              </ButtonStyle>
              <div
                style={{
                  width: "28px",
                }}
                className="image"
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src="/assets/icons/icon-light.png"
                  alt="icon-go-staking"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            {ROW_ONE.map((item: any, index: number) => {
              return (
                <CardStyle
                  key={index}
                  className="card-style"
                  data-aos="fade-up"
                >
                  <BorderAnimation />
                  <div className="header">
                    <div
                      style={{
                        width: "23px",
                      }}
                      className="image"
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={item.icon}
                        alt="icon-social"
                      />
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                  <div className="image">
                    <img src={item.image} alt="img-social" />
                  </div>
                </CardStyle>
              );
            })}
          </div>
          <div className="row">
            {ROW_TWO.map((item: any, index: number) => {
              return (
                <CardStyle
                  key={index}
                  className="card-style"
                  data-aos="fade-up"
                >
                  <BorderAnimation />
                  <div className="header">
                    <div
                      style={{
                        width: "23px",
                      }}
                      className="image"
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={item.icon}
                        alt="icon-social"
                      />
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                  <div className="image">
                    <img src={item.image} alt="img-social" />
                  </div>
                </CardStyle>
              );
            })}
          </div>
        </div>
      </div>
    </EcosystemStyle>
  );
}
