import styled from "styled-components";
import linear_layer from "../assets/Common/polygon.png";
import bg_before from "../assets/Common/bg_before.png";
import { Link } from "react-router-dom";

export const TitleCommon = styled.h2`
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #fff;
  @media screen and (max-width: 1023px) {
    font-size: 32px;
  }
`;
export const TextCommon = styled.p`
  display: block;
  color: #fff;
  font-size: 21px;
  font-weight: 300;
  line-height: 26px;
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;
export const ButtonCommon = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  border-radius: 7.835px;
  background-color: #fcfcfd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 38px;
  border: none;
  outline: none;
  cursor: pointer;
  span {
    font-weight: bold;
    font-family: "Inter", sans-serif;
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1080px) {
    width: 150px;
    height: 38px;
  }
`;
export const ButtonCommonSubmit = styled.button`
  font-size: 16px;
  font-weight: 600;
  border-radius: 7.835px;
  background-color: #fcfcfd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 38px;
  border: none;
  outline: none;
  cursor: pointer;
  span {
    font-weight: bold;
    font-family: "Inter", sans-serif;
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1080px) {
    width: 150px;
    height: 38px;
  }
`;
export const ButtonCommonNoLink = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-radius: 7.835px;
  background-color: #fcfcfd;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 38px;
  border: none;
  outline: none;
  cursor: pointer;
  span {
    font-weight: bold;
    font-family: "Inter", sans-serif;
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1080px) {
    width: 150px;
    height: 38px;
  }
`;
export const LinearGardientLayer = styled.div<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  width?: string;
  height?: string;
  layer_1?: boolean;
  layer_2?: boolean;
}>`
  position: absolute;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  pointer-events: none;
  &::before {
    content: ${({ layer_1 }) => (layer_1 ? `""` : "none")};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 936px;
    height: 936px;
    background: linear-gradient(
      143deg,
      #8000ff 8.37%,
      rgba(0, 41, 255, 0) 91.49%
    );
    filter: blur(132px);
  }
  &::after {
    content: ${({ layer_2 }) => (layer_2 ? `""` : "none")};
    position: absolute;
    left: 50%;
    top: 50%;
    background: url(${linear_layer}) no-repeat center / cover;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
`;
export const DashboardTitle = styled.h2<{ background?: string }>`
  position: relative;
  color: #eaecf0;
  font-size: 20px;
  padding-left: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 25px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: ${({ background }) => background};
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
    padding-left: 10px;
  }
`;
export const DashboardBlock = styled.div<{ background?: string }>`
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(
    262deg,
    rgba(56, 0, 65, 0.28) 0.29%,
    rgba(33, 23, 94, 0.17) 49.79%,
    rgba(53, 0, 120, 0.05) 99.3%
  );
  backdrop-filter: blur(6px);
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(${bg_before}) no-repeat top / 100% 100%;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
    border-radius: 16px;
  }
  @media screen and (max-width: 1300px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: 767px) {
    background: #27282C;
    backdrop-filter: unset;
    &::before {
      content: none;
    }
  }
`;
export const ButtonSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 38px;
  border-radius: 8px;
  background: #7f56d9;
  color: #fcfaff;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  outline: none;
  border: none;
  cursor: pointer;
`;
export const SwitchCommon = styled.div`
  .ant-switch-checked {
    background-color: #34c759 !important;
    &:hover {
      background-color: #34c759 !important;
    }
  }
  .ant-switch-inner {
    border: 1px solid #fff;
  }
`;
export const LoadingOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  transition: all 0.5s;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(17, 25, 39, 0.9);
  color: #fff;
`;
export const BorderAnimation = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 20px;
  filter: drop-shadow(0 0 10px #fff);
  opacity: 0.5;
  animation: RotateLine 12s linear infinite, borderOpacity 3s linear infinite;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: conic-gradient(
      from calc(var(--angle) + var(--start-angle)),
      transparent 0,
      #fff 20%,
      transparent 25%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: RotateLine 12s linear infinite, borderOpacity 3s linear infinite;
  }
  @keyframes RotateLine {
    to {
      --angle: 360deg;
    }
  }
  @keyframes borderOpacity {
    0% {
      opacity: 0.75;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }
`;
export const CheckBoxContainer = styled.div`
  .ant-checkbox-wrapper {
    color: rgb(160, 174, 229);
  }
  .ant-checkbox-inner {
    background-color: #9e77ed !important;
    border-color: #9e77ed !important;
  }
`;
export const AutoCompleteContainer = styled.div`
  
`