import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ArtificialButtonRegenerate,
  ArtificialButtons,
  ArtificialButtonSave,
  ArtificialContainer,
  ArtificialContentInner,
  ArtificialContentInnerBottom,
  ArtificialContentInnerBottomInner,
  ArtificialContentInnerTop,
  ArtificialContentInnerTopTetArea,
  ArtificialContentLeft,
  ArtificialContentRight,
  ArtificialContentRightInner,
  ArtificialContentText,
  ArtificialListButton,
  ArtificialListVideo,
  ArtificialTitleInfo,
  ArtificialToken,
  ArtificialWrapper,
  ButtonSurprise,
} from "./styled";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { convertFormatNumber } from "../../../../utils/convertFormatNumber";
import { dispatch } from "../../../../redux/store";
import { IsLoadingRedux, UserOverview } from "../../../../redux/slices/user";
import LoadingCube from "../../../../components/LoadingCube";
import { DashboardTitle } from "../../../../Layout/styled";
import { ContextProviderWrapper } from "../../../../components/Context";

const ArtificialIntelligenceVideo = () => {
  const [textArea, setTextArea] = useState("");
  const [countCharactor, setCountCharactor] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);
  const [dataTextToImgae, setDataTextToImage] = useState<any>();
  const { userProfile }: any = useSelector((state: any) => state.user);
  const [currentToken, setCurrentToken] = useState<any>();
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const intervalRef = useRef<any>();

  //handle random surprise
  const surpriseText = [
    "Portrait of a man in a city in the sunset with light and buildings around, model face, ambient lighting, 4k, realistic photography, intricate details, realistic, highly detailed, excellent, kodak",
    "Human colony on an unknown planet, bright vibrant colored vegetation, bioluminescent planet, hyperrealism, highly detailed, insanely detailed, trending on artstation",
    "A photorealistic render of a metallic crystalline neotribal tetrahedron as floating sculpture breaking and transmuting into fire, made of red hot metal and black obsidian, c 4 d, plain neutral background, futuristic poster scifi, hard edges, sharp focus, highly detailed, wide angle, hyper realistic, 8 k, global illumination, studio lighting, octane render",
    "1 teenager, close-up focused portrait of handsome prince model with very light aurora blonde hair, in chiffon sparkling royal costume character. Detailed angel face, sharp focus, photorealistic,8K high definition, vivid star colors, sparkly, incredibly detailed, intricate, elegant, trending on Artstation, dynamic moonlights, volumetric starry lighting. Pretty lips. In the middle of the sky, with lots of light.",
    "Splash, intricate raven bursting out, swirling liquid, holding a red rose, liquid steel background, soft box, rule of thirdâ€™s composition, trending on artstation, sharp focus, studio photo, intricate details, highly detailed",
    "Super cute house, cinematic shot, cinematic lighting, hyperrealism, 12k resolution, abstract, isometric 3d fantasy, smooth 3d illustration, cinematic matte painting, soft render, handpainted texture, blender",
    "Rome city. Modigliani style painting., Surreal. Line drawing color.",
    "Cottagecore, lots of furniture, wall art, plants, flowers, cozy, chaotic, messy, disheveled. Apartment interior design, marble floor, high ceilings, art deco, photorealistic, cinematic lighting",
    "Rainbow, floral bouquet, rainbows, fantasy, highly detailed, digital painting, stylized, trending on artstation, vibrant, moody, colorful, physically based rendering, still life, rainbow celebration, wallpaper",
    "Splash, intricate raven bursting out, swirling liquid, holding a red rose, liquid steel background, soft box, rule of thirdâ€™s composition, trending on artstation, sharp focus, studio photo, intricate details, highly detailed",
    "Human colony on an unknown planet, bright vibrant colored vegetation, bioluminescent planet, hyperrealism, highly detailed, insanely detailed, trending on artstation",
    "A beautiful grassy meadow, at sunset, birds, lake, psychedelic colorful, highly detailed, dream-like, colorful light, bright, vibrant colors, diffused light, photorealistic, volumetric lighting, 4K",
    "Wonderland fairytale valley landscape, crossroad, beauty, vibrant, digital, matte, dynamic rainbow radiating a bokeh color filter harmony, trending on artstation, cgsociety, 8k",
    "Vintage 90's anime style environmental wise shot of a chaotic arcade at night; a boy wearing streetwear;  sci-fi, colors, neon lights. Line art. Environmental arcade art.",
    "A magic lamp, game asset, digital painting, detailed, contrast, dark mood, concept art, cell shaded, 4k, colorful, trending on artstation",
    "3 d photographic render of symmetric metallic tree sculpture with thorns, bioluminescent chrome type, made of liquid metal, cyberpunk, raytracing, hyper realistic, volumetric lightning, 8 k",
    "A stunning {ARMOUR}, videogame asset, fantasy, beautiful lighting, trending on artstation, detailed fantasy illustration style, iridescent, extreme detail, octane render, high sample, blue glow, game asset by professional studio",
    "Cute chibi cat, purple, white, black, and pink pastel colors, minimalistic, digital art, wallpaper",
    "Portrait art of a beautiful girl, artistic, photorealistic, midnight aura, dreamy, stars, glowing, backlit, glamour, glimmer, shadows, smooth, ultra high definition, 8k, ultra sharp focus, intricate, ominous, movie poster, kodak",
    "Forest, trees, branches, flowers, red trees, flaming, high detail, sharp focus, mountain, village, fantasy, highly detailed, dirt road, castle, dramatic landscape, cottages, streets, town, ornate shops, dark buildings,  matte painting trending on artstation HQ",
  ];

  const getRandomResearchTitle = () => {
    if (surpriseText.length === 0) {
      return "All items have been used!";
    }
    const randomIndex = Math.floor(Math.random() * surpriseText.length);
    const randomResearchTitle = surpriseText[randomIndex];
    // Remove the selected item from the array
    surpriseText.splice(randomIndex, 1);
    return randomResearchTitle;
  };

  const handleGenerate = () => {
    const randomResearchTitle = getRandomResearchTitle();
    setTextArea(randomResearchTitle);
  };

  const onChangeTextArea = (e: any) => {
    const newValue = e.target.value;
    if (newValue.length <= 734) {
      setTextArea(newValue);
      setCountCharactor(newValue.length);
    }
  };

  useEffect(() => {
    setCountCharactor(textArea.length);
  }, [textArea]);

  //api gen text to video
  const TEXT_TO_VIDEO = gql`
    mutation textToVideo($text: String!) {
      textToVideo(text: $text)
    }
  `;

  const [textToVideo] = useMutation(TEXT_TO_VIDEO);

  // api check data
  const GET_AIGEN = gql`
    query aigen($id: String!) {
      aigen(id: $id) {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;

  const [FetchDataGen, { data: dataGen }] = useLazyQuery(GET_AIGEN, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (dataGen?.aigen?.resultUrl) {
      clearInterval(intervalRef.current);
      setLoadingImage(false);
      refetchGens();
      toast.success("Gen Video Success");
      refetch();
      dispatch(UserOverview(data));
    }
  }, [dataGen]);

  const handleGenTextToVideo = useCallback(async () => {
    setLoadingImage(true);
    setTextArea("");
    try {
      const res: any = await textToVideo({
        variables: {
          text: textArea,
        },
      });
      if (res) {
        setDataTextToImage(res);
        intervalRef.current = setInterval(() => {
          FetchDataGen({
            variables: {
              id: res?.data?.textToVideo,
            },
          });
        }, 10000);
      }
    } catch (error: any) {
      console.log(error);
      setLoadingImage(false);
    }
  }, [textArea, textToVideo, FetchDataGen]);

  // api history gen
  const GET_AIGENS = gql`
    query aigens {
      aigens {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;
  const { data: dataGens, refetch: refetchGens } = useQuery(GET_AIGENS, {
    fetchPolicy: "network-only",
  });

  // Get user
  const GET_USER = gql`
    query {
      user {
        email
        refCode
        phoneNumber
        accountID
        secret
        createdAt
        children
        levelAMFI
        packUSDT
        verified2FA
        totalSales
        totalSalesLeft
        totalSalesRight
        totalLeftDirectLine
        totalRightDirectLine
        directCommission
        rightReserve
        leftReserve
        totalRevenue
        modeTree
        bonusVerify
        totalBonus
        totalWeakBranch
        totalStaking
        maxOutUSDT
        totalDepositAMFI
        totalDepositUSDT
        parentEmail
        wallet {
          aimetafi {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          usdt {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          point {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          revenue {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          bonus {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
        }
        affiliate {
          displayName
          email
          createdAt
          branch
          accountID
          packAMFI
          packUSDT
          parentID
          children
          levelAMFI
          refCode
        }
        transactionRevenue {
          amount
          id
          note
          createdAt
          transactionType
        }
        packInvestingAMFI {
          price
          rate
          priceAMFI
          expireDate
          packDatetime
          paymentCurrency
        }
      }
      tickets {
        id
        subject
        status
        messages {
          text
          author
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      kyc {
        status
        accountID
        frontURL
        backURL
        documentID
        country
      }
      settings {
        tag
        config
      }
    }
  `;
  const { data, refetch, loading: isFetchUser } = useQuery(GET_USER);

  useEffect(() => {
    setCurrentToken(userProfile?.wallet?.aimetafi?.balance);
  }, [userProfile, refetch]);

  return (
    <ArtificialContainer>
      <ArtificialWrapper>
        <ArtificialContentLeft>
          <ArtificialTitleInfo>
            <DashboardTitle background="#D032D5">
              Video Generate AI
            </DashboardTitle>
            <ArtificialToken>
              <span>Credit:</span>
              {userProfile?.user
                ? `${convertFormatNumber(
                    userProfile?.user?.wallet.aimetafi.balance.toFixed(4)
                  )}`
                : "--"}
            </ArtificialToken>
          </ArtificialTitleInfo>
          <ArtificialContentInner>
            {!isMobile && (
              <p className="text-bio">
                Dream it, and use text-to-video to visualize it. Simply type
                your text description, and our AI generator lets you create
                videos in seconds.
              </p>
            )}
            <ArtificialContentInnerTop>
              <ArtificialContentInnerTopTetArea>
                <textarea
                  name="prompt"
                  value={textArea}
                  onChange={onChangeTextArea}
                  disabled={countCharactor >= 735}
                ></textarea>
                <ArtificialContentText>Your Prompt</ArtificialContentText>
                <span className="number-charactor">{countCharactor}/735</span>
              </ArtificialContentInnerTopTetArea>
              <ArtificialListButton>
                <li>
                  <ButtonSurprise onClick={handleGenerate}>Surprise me</ButtonSurprise>
                </li>
                <li
                  onClick={handleGenTextToVideo}
                  className={textArea === "" ? "disable" : ""}
                >
                  <span>Generate (10 credits)</span>
                </li>
              </ArtificialListButton>
            </ArtificialContentInnerTop>
            <ArtificialContentInnerBottom>
              <ArtificialTitleInfo
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <p>
                  Generated Video
                  <span>Check the video below</span>
                </p>
                <div className="image">
                  <img src="/assets/artificial/icn_play.svg" alt="" />
                </div>
              </ArtificialTitleInfo>
              <ArtificialContentInnerBottomInner>
                <div className="block-video">
                  {!loadingImage ? (
                    dataGen?.aigen?.resultUrl ? (
                      <video controls>
                        <source
                          src={
                            dataGen?.aigen?.resultUrl
                              ? dataGen?.aigen?.resultUrl
                              : "/assets/artificial/icn_play.png"
                          }
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img src="/assets/artificial/icn_play.png"></img>
                    )
                  ) : (
                    <LoadingCube />
                  )}
                </div>
                {!isMobile && (
                  <div className="block-text">
                    <ArtificialContentInnerTopTetArea>
                      <ArtificialContentText className="is-description">
                        Video Description
                      </ArtificialContentText>
                      <p className="text">{dataGen?.aigen?.text}</p>
                    </ArtificialContentInnerTopTetArea>
                    <button>Re-generate</button>
                  </div>
                )}
              </ArtificialContentInnerBottomInner>
            </ArtificialContentInnerBottom>
            <ArtificialButtons>
              {isMobile && (
                <ArtificialButtonRegenerate>
                  Re-generate
                </ArtificialButtonRegenerate>
              )}
              <ArtificialButtonSave>Save Video</ArtificialButtonSave>
            </ArtificialButtons>
          </ArtificialContentInner>
        </ArtificialContentLeft>
        {!isMobile && (
          <ArtificialContentRight>
            <ArtificialTitleInfo>
              <p>Our Example</p>
              <div className="image">
                <img src="/assets/artificial/icn_paper.svg" alt="" />
              </div>
            </ArtificialTitleInfo>
            <ArtificialContentRightInner>
              <ArtificialListVideo>
                {listVideo.map((item: any, index: number) => (
                  <li key={index}>
                    <img
                      src={item.img}
                      alt={item.text}
                      width={175}
                      height={175}
                    />
                    <p>{item.text}</p>
                  </li>
                ))}
              </ArtificialListVideo>
            </ArtificialContentRightInner>
          </ArtificialContentRight>
        )}
      </ArtificialWrapper>
    </ArtificialContainer>
  );
};

const listVideo = [
  {
    img: "/assets/artificial/Example_01.jpeg",
    text: "A masterpiece in the form of a wood forest world inside a beautiful, illuminated miniature forest",
  },
  {
    img: "/assets/artificial/Example_02.jpeg",
    text: "gigantic storm dragon, teeth, horns, long tail, wings, mountains, lightning aura,fantasy,solo focus,epic",
  },
  {
    img: "/assets/artificial/Example_03.jpeg",
    text: "enchanting scene, a steaming cup of coffee, the liquid within the cup forms a swirling cosmic galaxy, The coffee surface is a mesmerizing mix of deep purples and blues and pinks dotted with stars and nebulae, fit the vastness of space inside the cup, The cup rests on a saucer that reflects the celestial patterns, surrounded by tiny star-shaped cookies and cosmic-themed decorations, The atmosphere is magical and dreamy,",
  },
  {
    img: "/assets/artificial/Example_04.jpeg",
    text: "A futuristic cityscape with towering neon skyscrapers, flying vehicles, and holographic advertisements, set against a twilight sky with distant planets visible.",
  },
  {
    img: "/assets/artificial/Example_05.jpeg",
    text: "A dreamlike scene where gravity is reversed, with floating objects and twisted landscapes merging in impossible ways under a sky of swirling colors.",
  },
  {
    img: "/assets/artificial/Example_06.jpeg",
    text: "A vibrant marketplace in an ancient civilization, bustling with people in period-appropriate attire, with traditional architecture and colorful goods displayed.",
  },
];

export default memo(ArtificialIntelligenceVideo);
