import { useInView, useMotionValueEvent, useScroll } from "framer-motion";
import { BorderAnimation } from "../../Layout/styled";
import { ButtonStyle, CardStyle } from "../../page/Homev2/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import {
  ExpressBody,
  ExpressWrapper,
  ExpressYourLimitationStyle,
  SplineContainer,
} from "./style";
import { useContext, useRef, useState } from "react";
import { ContextProviderWrapper } from "../Context";
import Spline from "@splinetool/react-spline";
import { useTranslation } from "react-i18next";

export default function ExpressYourLimitation() {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const isInView = useInView(ref);
  const { scrollYProgress } = useScroll({
    target: ref,
  });
  const [stateControl, setStateControl] = useState(0);
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const convertValue: any = Number(latest.toFixed(1));
    if (isInView && isDesktop) {
      if (convertValue > 0.2) {
        setStateControl(1);
      } else {
        setStateControl(0);
      }
    }
  });

  return (
    <ExpressYourLimitationStyle ref={ref}>
      <ExpressWrapper>
        <ButtonStyle>
          <button>
            <StarUp />
            <span>{t("Artificial Intelligence")}</span>
            <img
              src="/assets/icons/icon-go-staking.png"
              alt="icon-go-staking"
            />
          </button>
        </ButtonStyle>
        <AnimatedText
          el="p"
          text={
            isDesktop
              ? `${t("The Power Of Artificial Intelligence")}`
              : `${t("The Power Of Artificial \nIntelligence")}`
          }
        />
        <p>
          {t(
            "Imagine a world where AI fuels your financial success and unleashes your creative potential. That's the future AIMETAFI is building, with a suite of powerful AI features that empower you like never before"
          )}{" "}
        </p>
        <ExpressBody className="container-ai">
          {isDesktop && (
            <SplineContainer>
              <Spline scene="https://prod.spline.design/7rUHnfCyVa3KbmnY/scene.splinecode" />
            </SplineContainer>
          )}
          <div className="ai-row">
            {isMobile && (
              <CardStyle className="container-card ai-gen-image mix-text-chat">
                <BorderAnimation />
                <div className="diagonal-ai-gen-image">
                  <div className="title">
                    <h3>{t("AI Text Chat")}</h3>
                    <p>
                      {t("AI-Powered")} <br className="sm" />{" "}
                      {t("text content")}
                    </p>
                  </div>
                </div>
                <div className="image">
                  <img src="/assets/images/img-child.png" alt="img-child" />
                </div>
              </CardStyle>
            )}
            <CardStyle
              style={{
                opacity:
                  stateControl >= 1 && isDesktop ? "1" : !isDesktop ? "1" : "0",
                transform:
                  stateControl >= 1 && isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : !isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : "translate(350px, 100px) scale(0) rotate(100deg)",
              }}
              className="container-card ai-text-chat"
            >
              <BorderAnimation />
              <div className="image">
                <img src="/assets/images/img-child.png" alt="img-child" />
              </div>
              <div className="diagonal-ai-text">
                <div className="title">
                  <h3>{t("AI Text Chat")}</h3>
                  <p>{t("AI-Powered")}</p>
                  <p>{t("text content")}</p>
                </div>
              </div>
            </CardStyle>
            <div className="ai-center">
              <CardStyle
                className="container-card ai-gen-image"
                style={{
                  opacity:
                    stateControl >= 1 && isDesktop
                      ? "1"
                      : !isDesktop
                      ? "1"
                      : "0",
                  transform:
                    stateControl >= 1 && isDesktop
                      ? "translate(0) scale(1) rotate(0deg)"
                      : !isDesktop
                      ? "translate(0) scale(1) rotate(0deg)"
                      : "translateY(200px) scale(0) rotate(100deg)",
                }}
              >
                <BorderAnimation />
                <div className="diagonal-ai-gen-image">
                  <div className="title">
                    <h3>{t("AI Image Generate")}</h3>
                    <p>{t("Create stunning, professional-")}</p>
                    <p>{t("quality websites")}</p>
                  </div>
                </div>
                <div className="image">
                  <img src="/assets/images/img-gen-img.png" alt="img-gen" />
                </div>
              </CardStyle>
              <CardStyle className="container-card ai-metafi">
                <BorderAnimation />
                <div className="image">
                  <img
                    src="/assets/images/img-logo-ai-metafi.png"
                    alt="img-gen"
                  />
                </div>
                <ButtonStyle>
                  <button>
                    <span>{t("Artificial Intelligence")}</span>
                    <img
                      src="/assets/icons/icon-start.png"
                      alt="icon-go-staking"
                    />
                  </button>
                </ButtonStyle>
              </CardStyle>
            </div>
            <CardStyle className="container-card ai-gen-image mix-video">
              <BorderAnimation />
              <div className="diagonal-ai-gen-image">
                <div className="title">
                  <h3>{t("AI Video")}</h3>
                  <p>{t("Create stunning,")}</p>
                  <p>{t("professional-quality")}</p>
                </div>
              </div>
              <div className="image">
                <img src="/assets/images/img-ai-video.png" alt="img-ai-video" />
              </div>
            </CardStyle>
            <CardStyle
              style={{
                opacity:
                  stateControl >= 1 && isDesktop ? "1" : !isDesktop ? "1" : "0",
                transform:
                  stateControl >= 1 && isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : !isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : "translate(-350px, 100px) scale(0) rotate(100deg)",
              }}
              className="container-card ai-video"
            >
              <BorderAnimation />
              <div className="diagonal-ai-video">
                <div className="title">
                  <h3>{t("AI Video")}</h3>
                  <h4>{t("Beautiful AI")}</h4>
                  <p>{t("Create stunning,")}</p>
                  <p>{t("professional-quality")}</p>
                  <p>{t("video")}</p>
                </div>
              </div>
              <div className="image">
                <img src="/assets/images/img-ai-video.png" alt="img-ai-video" />
              </div>
            </CardStyle>
          </div>
          <div className="ai-row">
            <CardStyle
              style={{
                opacity:
                  stateControl >= 1 && isDesktop ? "1" : !isDesktop ? "1" : "0",
                transform:
                  stateControl >= 1 && isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : !isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : "translate(250px, -200px) scale(0) rotate(100deg)",
              }}
              className="container-card ai-voice"
            >
              <BorderAnimation />
              <div className="image">
                <img src="/assets/images/img-ai-voice.png" alt="img-ai-voice" />
              </div>
              <div className="title">
                <h3>{t("AI Voice")}</h3>
                <div className="description">
                  <p>{t("AI powered audio creation")}</p>
                  <p>{t("& editing")}</p>
                </div>
              </div>
            </CardStyle>
            <CardStyle
              style={{
                opacity:
                  stateControl >= 1 && isDesktop ? "1" : !isDesktop ? "1" : "0",
                transform:
                  stateControl >= 1 && isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : !isDesktop
                    ? "translate(0) scale(1) rotate(0deg)"
                    : "translate(-250px, -200px) scale(0) rotate(100deg)",
              }}
              className="container-card ai-voice ai-bot-trading"
            >
              <BorderAnimation />
              <div className="image">
                <img src="/assets/images/img-ai-bot.png" alt="img-ai-voice" />
              </div>
              <div className="title">
                <h3>{t("AI Bot Trading")}</h3>
                <div className="description">
                  <p>{t("Market analysis and providing")}</p>
                  <p>{t("spot trading signals")}</p>
                </div>
              </div>
            </CardStyle>
          </div>
        </ExpressBody>
      </ExpressWrapper>
    </ExpressYourLimitationStyle>
  );
}
