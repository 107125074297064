import styled from "styled-components";

const LoadingBuy = () => {
  return (
    <LoadingContainer>
      <LoadingContent>
        <p>Please wait until process is done</p>
        <Bouncing>
          <div></div>
          <div></div>
          <div></div>
        </Bouncing>
      </LoadingContent>
    </LoadingContainer>
  );
};

export default LoadingBuy;

const LoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  section {
    height: auto;
    margin-bottom: 30px;
  }
  p {
    font-size: 23px;
    font-weight: 700;
  }
  @media screen and (max-width: 743px) {
    width: calc(100% - 8vw);
    p {
      margin-bottom: 5.3vw;
      font-size: 5.3vw;
    }
  }
`;
const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const Bouncing = styled.div`
  display: flex;
  margin-left: 5px;
  & > div {
    position: relative;
    top: 6px;
    width: 8px;
    height: 8px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-10px);
    }
  }
  @media screen and (max-width: 743px) {
    & > div {
      width: 2.6vw;
      height: 2.6vw;
    }
  }
`;
