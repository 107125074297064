import styled from "styled-components";
import {
  ArtificialButtonSave,
  ArtificialContentInnerBottomInner,
  ArtificialContentInnerTopTetArea,
} from "../../ArtificialIntelligenceVideo/styled";

export const ContentUploadImage = styled.div`
  cursor: pointer;
  .text-upload {
    color: #b1b1b1;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    padding-left: 23px;
    margin-bottom: 5px;
    &:before {
      position: absolute;
      content: "";
      top: 1px;
      left: 0;
      background: url("/assets/artificial/icn_img_04.svg") center / contain
        no-repeat;
      width: 16px;
      height: 16px;
    }
    span {
      display: block;
      color: #797979;
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
    }
  }
  img {
    margin-bottom: 3px;
  }
  .text-drop {
    color: #fff;
    font-size: 12px;
    span {
      color: #d07fff;
      font-size: 12px;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
`;

export const ImageTransferContainer = styled.div`
  ${ArtificialContentInnerTopTetArea} {
    &.is-flex {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }
  }
  .block-bio {
    width: 70%;
    position: relative;
  }
  .block-upload-image {
    width: 30%;
    border-radius: var(--Parameters-Radius-lg, 10px);
    border: 1px solid #353535;
    background: #484a53;
    height: 9.6vw;
    overflow: hidden;
    .ant-upload-list-item {
      color: #fff;
    }
    .ant-upload.ant-upload-select {
      border: unset;
      margin: 0;
      width: 100%;
      height: 100%;
      background-color: #1b1c20;
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item,
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item-container {
      width: 100%;
      height: 100%;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item-thumbnail,
    .ant-upload-wrapper .ant-upload-list {
      width: 100%;
      height: 100%;
      display: block;
    }
    .ant-upload-list-item.ant-upload-list-item-error {
      padding: 0;
    }
    .ant-tooltip {
      display: none !important;
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload-list.ant-upload-list-picture-card
      .ant-upload-list-item::before {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 767.98px) {
    ${ArtificialContentInnerTopTetArea} {
      &.is-flex {
        display: block;
      }
    }
    .block-bio,
    .block-upload-image {
      width: 100%;
    }
    .block-upload-image {
      margin-bottom: 10px;
      height: 165px;
    }
  }
  /* faceswap page */
  &.is-faceswap {
    ${ArtificialContentInnerBottomInner} {
      .block-action {
        width: 45%;
        margin-left: 10px;
        h3 {
          color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 3px;
        }
        p {
          color: #fff;
          font-size: 16px;
        }
        .block-button {
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          .re-generate {
            outline: none;
            border: none;
            border-radius: 9px;
            background: #1b1c20;
            cursor: pointer;
            padding: 9px 16px;
            min-height: 44px;
            color: #dfdfdf;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
          }
          ${ArtificialButtonSave} {
            margin-left: unset;
          }
        }
      }
    }
    ${ArtificialContentInnerTopTetArea} {
      .block-upload-image {
        width: 50%;
        height: 246px;
        position: relative;
      }
    }
    ${ContentUploadImage} {
      .text-upload {
        position: absolute;
        top: 15px;
        left: 15px;
        text-align: left;
      }
    }
    @media screen and (max-width: 767.98px) {
      ${ArtificialContentInnerBottomInner} {
        .block-action {
          width: 100%;
        }
      }
      ${ArtificialContentInnerTopTetArea} {
        .block-upload-image {
          width: 100%;
        }
      }
    }
  }
`;

