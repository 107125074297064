import { AuthForm, AuthLogo } from "../../Layout/Auth/styled";
import { BorderAnimation } from "../../Layout/styled";
import ABI_SWAP from "../../contract/ABI/ABI_SWAP.json";
import ABI_USDT from "../../contract/ABI/ABI_USDT.json";
import {
  SwapAMFIContainer,
  SwapBalancePercentage,
  SwapBlock,
  SwapBlockInfo,
  SwapBlockText,
  SwapBlockWrapper,
  SwapButtonWrapper,
  SwapExchange,
  SwapInputExchange,
  SwapTitle,
} from "./styled";
import toast from "react-hot-toast";
import swap_logo from "../../assets/Swap/swap_logo.png";
import swap_exchange_icon from "../../assets/Swap/swap_exchange_icon.svg";
import { ExchangeInput } from "../Dashboard/Swap/styled";
import { ButtonStyle } from "../Homev2/styled";
import StarUp from "../../components/StarUp";
import LoadingIcon from "../../components/LoadingIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount, useBalance, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { switchNetwork } from "@wagmi/core";
import { formatEther } from "ethers";
import {
  ADDRESS_SWAP_CONTRACT,
  ADDRESS_TOKEN,
  ADDRESS_USDT_CONTRACT,
} from "../../contract/address";
import { parseEther } from "viem";
import web3 from "web3";
import { convertFromWei } from "../../utils/convertNumber";
import { shortenAddress } from "../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../assets/BuyAMFI/copy.svg";
import { Link } from "react-router-dom";
import { convertFixed } from "../../utils/formatNumber";

declare const window: Window & typeof globalThis & { ethereum: any };
const SwapAMFI = () => {
  const w3 = new web3(window.ethereum);
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const percentageData = [25, 50, 75, 100];
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [reloadBalance, setReloadBalance] = useState(false);
  const [amountUSDT, setAmountUSDT] = useState<any>(0);
  const [amountAMFI, setAmountAMFI] = useState<any>();
  // Get balance USDT
  const getBalanceUSDT: any = useBalance({
    address: address,
    token: ADDRESS_USDT_CONTRACT,
    watch: reloadBalance ? true : false,
  });

  const convertBalance =
    getBalanceUSDT?.data &&
    Number(formatEther(getBalanceUSDT?.data?.value)).toFixed(2);

  // Get balance BOT
  const getBalanceAMFI: any = useBalance({
    address: address,
    token: ADDRESS_TOKEN,
    watch: reloadBalance ? true : false,
  });

  const convertBalanceAMFI =
    getBalanceAMFI?.data &&
    Number(formatEther(getBalanceAMFI?.data?.value)).toFixed(2);

  // Handle switch chain
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const handleChangeNetwork = async () => {
    await switchNetwork({
      chainId: 56,
    }).catch((e) => {
      toast.error("User rejected the request.");
    });
  };

  // Handle exchange
  const handleExchange = () => {
    setAmountAMFI(Number(amountUSDT));
    setAmountUSDT(amountAMFI.toString());
  };

  // Get fee
  const [feeSwap, setFeeSwap] = useState<any>("0");
  const handleGetFeeSwap = async () => {
    const SwapContract: any = new w3.eth.Contract(
      ABI_SWAP,
      ADDRESS_SWAP_CONTRACT
    );
    const fee: any = await SwapContract.methods.feeSwap().call();
    setFeeSwap(fee);
  };

  // Handle Swap
  const [swapTransaction, setSwapTransaction] = useState("");
  const handleSwap = async () => {
    setLoading(true);
    if (Number(convertBalanceAMFI) === 0) {
      setLoading(false);
      return toast.error("Insufficient balance", { id: "balanceAMFI" });
    }
    if (
      Number(balancePoolState) <
      amountUSDT - (amountUSDT * Number(feeSwap.toString())) / 100
    ) {
      setLoading(false);
      return toast.error("Pool didn't have enough balance", {
        id: "poolBalance",
      });
    }
    try {
      const SwapContract: any = new w3.eth.Contract(
        ABI_SWAP,
        ADDRESS_SWAP_CONTRACT
      );
      const gasPrice = await w3.eth.getGasPrice();
      const gasEstimate = await SwapContract.methods
        .swap(parseEther(Number(amountAMFI).toString()))
        .estimateGas({
          from: address,
          to: ADDRESS_SWAP_CONTRACT,
          gasPrice: gasPrice,
        });
      SwapContract.methods
        .swap(parseEther(Number(amountAMFI).toString()))
        .send({
          from: address,
          gas: gasEstimate,
          gasPrice: gasPrice,
        })
        .then(async (receipt: any) => {
          toast.success("Swap successfully");
          setSwapTransaction(receipt.transactionHash);
          setLoading(false);
          setReloadBalance(true);
        })
        .catch((e: any) => {
          console.log(e);
          toast.error("You denied transaction");
          setLoading(false);
        });
      setReloadBalance(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message);
      setLoading(false);
    }
  };

  const [swapRate, setSwapRate] = useState<any>({
    swapRate: 0,
    ratio: 0,
  });

  const handlePriceToken = async () => {
    const SwapContract: any = new w3.eth.Contract(
      ABI_SWAP,
      ADDRESS_SWAP_CONTRACT
    );
    const priceToken: any = await SwapContract.methods
      .getInfoSwapRatio()
      .call();
    setSwapRate({
      swapRate: Number(priceToken.SwapRate),
      ratio: Number(priceToken.Ratio),
    });
  };

  useEffect(() => {
    if (address && chainId === 56) {
      handlePriceToken();
      handleGetFeeSwap();
    }
  }, [address, chainId]);

  // Allowance
  const [valueAllowance, setValueAllowance] = useState<any>("0");
  const AllowanceUnit = async () => {
    const TokenContract: any = new w3.eth.Contract(
      ABI_USDT,
      ADDRESS_TOKEN
    );
    const allowance: any = await TokenContract.methods
      .allowance(address, ADDRESS_SWAP_CONTRACT)
      .call();
    const convertAllowance = await convertFromWei(allowance, 18);
    setValueAllowance(convertAllowance);
  };

  useEffect(() => {
    if (address && chainId === 56) {
      AllowanceUnit();
    }
  }, [valueAllowance, chainId]);

  // Approve
  const ApproveSwap = async () => {
    setLoading(true);
    try {
      const TokenContract: any = new w3.eth.Contract(
        ABI_USDT,
        ADDRESS_TOKEN
      );
      const gasPrice = await w3.eth.getGasPrice();
      // const supply = await TokenContract.methods.totalSupply().call();
      await TokenContract.methods
        .approve(
          ADDRESS_SWAP_CONTRACT,
          w3.utils.toWei(amountAMFI.toString(), "ether")
        )
        .send({
          from: address,
          gasPrice: gasPrice,
        })
        .then(async (res: any) => {
          if (res.transactionHash) {
            await AllowanceUnit();
            toast.success("Approve successfully");
            setLoading(false);
          }
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setLoading(false);
          toast.error("You denied approved");
        });
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toast.error("Failed");
    }
  };

  useEffect(() => {
    if (amountAMFI > 0 && address && chainId === 56) {
      setAmountUSDT((swapRate.swapRate / swapRate.ratio) * amountAMFI);
    } else {
      setAmountUSDT(0);
    }
  }, [amountAMFI]);

  // get balance pool
  const [balancePoolState, setBalancePoolState] = useState<any>("0");
  const getBalancePool = async () => {
    const TokenContract: any = new w3.eth.Contract(
      ABI_USDT,
      ADDRESS_USDT_CONTRACT
    );
    const balancePool = await TokenContract.methods
      .balanceOf(ADDRESS_SWAP_CONTRACT)
      .call();
    setBalancePoolState(convertFromWei(balancePool, 18));
  };

  useEffect(() => {
    if (chainId === 56) getBalancePool();
  }, [chainId]);

  return (
    <SwapAMFIContainer>
      <SwapTitle>BOT {t("Swap")}</SwapTitle>
      <AuthForm>
        <BorderAnimation />
        <AuthLogo>
          <img src={swap_logo} alt="logo" />
        </AuthLogo>
        <SwapBlockWrapper>
          <SwapBlock>
            <SwapBlockText>
              <p>{t("You’re sending")}</p>
              <p>
                BOT {t("Balance")}:
                <span>{convertBalanceAMFI || "--"} BOT</span>
              </p>
            </SwapBlockText>
            <SwapInputExchange>
              <span>BOT</span>
              <ExchangeInput
                value={amountAMFI}
                type="number"
                placeholder="0"
                min={0}
                onChange={(e: any) => setAmountAMFI(e.target.value)}
              />
            </SwapInputExchange>
            <SwapBalancePercentage>
              {percentageData.map((item, index) => {
                return (
                  <li
                    onClick={() => {
                      setAmountAMFI((Number(convertBalanceAMFI) * item) / 100);
                    }}
                    key={index}
                  >
                    {item === 100 ? "MAX" : item + "%"}
                  </li>
                );
              })}
            </SwapBalancePercentage>
          </SwapBlock>
          <SwapExchange
            onClick={() => {
              handleExchange();
            }}
          >
            <figure>
              <img src={swap_exchange_icon} alt="icon" />
            </figure>
          </SwapExchange>
          <SwapBlock>
            <SwapBlockText>
              <p>{t("You’ll receive")}</p>
              <p>
                USDT {t("Balance")}:<span>{convertBalance || "--"} USDT</span>
              </p>
            </SwapBlockText>
            <SwapInputExchange>
              <span>USDT</span>
              <ExchangeInput
                value={convertFixed(Number(amountUSDT))}
                disabled
                type="text"
                placeholder="0"
                onChange={(e: any) => setAmountUSDT(e.target.value)}
              />
            </SwapInputExchange>
          </SwapBlock>
        </SwapBlockWrapper>
        <SwapBlockInfo>
          <div>
            <p>{t("Amount receive")}</p>
            <span>
              {address
                ? convertFixed(
                    amountUSDT - (amountUSDT * Number(feeSwap.toString())) / 100
                  )
                : "0"}{" "}
              USDT
            </span>
          </div>
          <div>
            <p>{t("Swap fee")}</p>
            <span>{address ? Number(feeSwap.toString()) : "0"}%</span>
          </div>
          {swapTransaction && (
            <div>
              <p>{t("Transaction hash")}</p>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                <Link
                  to={`https://bscscan.com/tx/${swapTransaction}`}
                  target="_blank"
                  rel="noreffer"
                >
                  {shortenAddress(swapTransaction)}
                </Link>
                <CopyToClipboard
                  text={swapTransaction}
                  onCopy={() => toast.success("Coppied !")}
                >
                  <img
                    style={{ marginLeft: "5px" }}
                    width={16}
                    height={16}
                    src={copy}
                    alt="icon"
                  />
                </CopyToClipboard>
              </span>
            </div>
          )}
        </SwapBlockInfo>
        <SwapButtonWrapper>
          <ButtonStyle
            onClick={() => {
              if (address && chainId !== 56) {
                handleChangeNetwork();
              } else {
                open();
              }
            }}
          >
            <BorderAnimation />
            <button>
              <StarUp />
              <span>
                <>
                  {address ? (
                    <>{shortenAddress(address)}</>
                  ) : (
                    <>{t("Connect Wallet")}</>
                  )}
                </>
              </span>
            </button>
          </ButtonStyle>
          <ButtonStyle
            style={{
              pointerEvents: amountAMFI > 0 && !loading ? "auto" : "none",
            }}
            onClick={() => {
              if (address && chainId === 56) {
                Number(amountAMFI) <= Number(valueAllowance)
                  ? handleSwap()
                  : ApproveSwap();
              } else if (address && chainId !== 56) {
                handleChangeNetwork();
              }
            }}
          >
            <BorderAnimation />
            <button>
              <StarUp />
              <span>
                {loading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    {address && chainId === 56 ? (
                      <>
                        {Number(amountAMFI) > Number(valueAllowance)
                          ? `${t("Approve")}`
                          : `${t("Swap")}`}
                      </>
                    ) : (
                      <>{t("Swap")}</>
                    )}
                  </>
                )}
              </span>
            </button>
          </ButtonStyle>
        </SwapButtonWrapper>
      </AuthForm>
    </SwapAMFIContainer>
  );
};

export default SwapAMFI;
