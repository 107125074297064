import {
  ButtonHamburger,
  HeaderAction,
  HeaderBody,
  HeaderContainer,
  HeaderContent,
  HeaderInner,
  HeaderLinks,
  HeaderLogo,
  WrapperHamburger,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import { Link } from "react-router-dom";
import { ButtonCommon } from "../../Layout/styled";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const Header = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [openHamburger, setOpenHamburger] = useState(false);
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  return (
    <HeaderBody>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <HeaderContent
          style={
            !isDesktop && openHamburger ? { transform: "translateX(0)" } : {}
          }
          className={!isDesktop ? "menu-fixed" : ""}
        >
          <HeaderInner>
            <HeaderLinks>
              {linkData.map((item, index) => {
                if (item.scrollTo) {
                  return (
                    <li key={index}>
                      <HashLink
                        smooth
                        to={item.href}
                        onClick={() => {
                          setOpenHamburger(false);
                        }}
                      >
                        {item.title}
                      </HashLink>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      {item.externalLink ? (
                        <>
                          <a target="_blank" rel="noreferrer" href={item.href}>
                            {item.title}
                          </a>
                        </>
                      ) : (
                        <>
                          <NavLink
                            onClick={() => setOpenHamburger(false)}
                            to={item.href}
                          >
                            {item.title}
                          </NavLink>
                        </>
                      )}
                    </li>
                  );
                }
              })}
            </HeaderLinks>
          </HeaderInner>
          <HeaderAction>
            <ButtonCommon to="/dashboard">
              <span>Buy AMFI</span>
            </ButtonCommon>
          </HeaderAction>
        </HeaderContent>
        {!isDesktop && (
          <WrapperHamburger>
            Menu
            <ButtonHamburger
              className={openHamburger ? "active" : ""}
              onClick={() => {
                handleOpenHamburger();
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </ButtonHamburger>
          </WrapperHamburger>
        )}
      </HeaderContainer>
    </HeaderBody>
  );
};

export const linkData = [
  {
    title: "Swap",
    href: "/swap",
    scrollTo: false,
    externalLink: false,
    delay: "0.1s",
  },
  {
    title: "AI Chat",
    href: "/ai-chat",
    scrollTo: false,
    externalLink: false,
    delay: "0.2s",
  },
  {
    title: "Buy AIFI",
    href: "/buy-aifi",
    scrollTo: false,
    externalLink: false,
    delay: "0.3s",
  },
  {
    title: "Ai Box",
    href: "/ai-box",
    scrollTo: false,
    externalLink: false,
    delay: "0.4s",
  },
  {
    title: "Audit Certik",
    href: "https://skynet.certik.com/projects/aimetafi",
    scrollTo: false,
    externalLink: true,
    delay: "0.5s",
  },
  {
    title: "Docs",
    href: "https://whitepaper.aimetafi.io/",
    scrollTo: false,
    externalLink: true,
    delay: "0.6s",
  },
];

export default Header;
