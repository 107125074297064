import { Input } from "antd";
import { InputContainer } from "./styled";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const InputCommon = ({
  value,
  defaultValue,
  placeHolder,
  onChange,
  disabled,
  suffix,
  prefix,
  type,
  password,
  accept
}: any) => {
  return (
    <InputContainer>
      {password ? (
        <Input.Password
          value={value}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeHolder}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      ) : (
        <Input
          accept={accept}
          value={value}
          prefix={prefix}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeHolder}
          onChange={onChange}
          suffix={suffix}
          type={type}
        />
      )}
    </InputContainer>
  );
};

export default InputCommon;
