import { styled } from "styled-components";

export const AMFITokenStyle = styled.div`
  position: relative;
  .gif-for-ai-metafi {
    margin: 0 auto;
    max-width: 1075px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;
    .gif {
      position: relative;
      width: 320px;
      height: 320px;
      margin-bottom: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -100px;
      &::before {
        content: "";
        position: absolute;
        left: -60px;
        top: 150px;
        z-index: -1;
        background-image: url("/assets/background/bg-of-gif.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 459px;
        height: 339px;
      }
      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: -60px;
      }
    }
    @media screen and (max-width: 767px) {
      margin-right: 0px;
      .gif {
        width: 300px;
        height: 300px;
        margin-left: 0px;
        &::before {
          left: -75px;
          top: 130px;
        }
      }
    }
    @media screen and (max-width: 481px) {
      margin-right: 0px;
      background-position: 54%;
      background-size: 200%;
      .gif {
        width: 280px;
        height: 280px;
        margin-left: 0px;
      }
    }
  }
  .amfi-token-1 {
    margin-top: -120px;
  }
  .list-1,
  .list-2 {
    margin-bottom: 100px !important;
  }
  .title-amfi-token {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 20px;
    h1 {
      margin: 20px 0;
    }
    p {
      color: #a0aee5;
      text-align: center;
      font-size: 12px;
      line-height: 20.405px;
      letter-spacing: 0.268px;
    }
  }
  .list-card {
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      background: rgba(161, 174, 229, 0.1);
      background-image: url("/assets/background/bg-card.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 22px;
      width: calc(33.33% - 20px);
      height: 120px;
      border-radius: 20px;
      transition: all.3s;
      &:hover {
        transition: all.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/assets/background/bg-navbar.png");
      }
      .image {
        width: 22px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      h3,
      a {
        color: #ecf0ff;
        font-size: 19px;
        line-height: 24px;
      }
      p {
        color: #a0aee5;
        font-size: 19px;
        line-height: 18px;
        letter-spacing: 0.239px;
      }
    }
    @media screen and (max-width: 767px) {
      gap: 16px;
      padding: 0 10px;
      .card-item {
        height: auto;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          width: calc(50% - 10px);
          word-break: break-word;
        }
        &:nth-child(3),
        &:nth-child(6) {
          width: 100%;
        }
        p {
          font-size: 14px;
        }
        a,h3 {
          font-size: 12px;
        }
      }
    }
  }
  .button-Enhanced {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
    button {
      padding: 15px 26px;
    }
  }
`;
export const AMFITokenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(13px);
  }
`;
export const AMFIModalClose = styled.div`
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    width: 100%;
  }
`;
