import styled from "styled-components";
import auth_bg from "../../assets/Auth/auth_bg.png";
import auth_form_bg from "../../assets/Auth/auth_form_bg.png";
import { InputContainer } from "../../components/Common/Input/styled";
import { ButtonStyle } from "../../page/Homev2/styled";
import shape_1 from "../../assets/Auth/shape_1.png";
import shape_2 from "../../assets/Auth/shape_2.png";
import { BorderAnimation } from "../styled";

export const AuthContainer = styled.div`
  overflow: hidden;
  ${ButtonStyle} {
    ${BorderAnimation} {
      &::before {
        border-radius: 50px;
      }
    }
  }
`;
export const AuthWrapper = styled.div`
  position: relative;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100vh;
  background: url(${auth_bg}) no-repeat center / cover;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: radial-gradient(
      47.71% 47.71% at 50% 50%,
      rgba(252, 118, 255, 0.77) 0%,
      rgba(60, 81, 255, 0) 100%
    );
    width: 1421px;
    height: 1230px;
    opacity: 0.4;
  }
  @media screen and (max-width: 767px) {
    padding: 120px 10px 80px;
    min-height: auto;
  }
`;
export const AuthAnimation = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  span {
    position: absolute;
    background: no-repeat center / 100% auto;
    transition: all 0.5s ease-in-out;
    &.register {
      animation-name: registerShape;
      animation-duration: 8s;
    }
    &.reset:nth-child(2) {
      animation-name: resetShape;
      animation-duration: 8s;
    }
  }
`;
export const AuthForm = styled.div`
  position: relative;
  padding: 60px 24px 35px;
  max-width: 456px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid rgba(49, 49, 49, 0);
  background: linear-gradient(
    262deg,
    rgba(87, 0, 101, 0.28) 0.29%,
    rgba(26, 21, 59, 0.17) 49.79%,
    rgba(112, 0, 255, 0.05) 99.3%
  );
  backdrop-filter: blur(6px);
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(${auth_form_bg}) no-repeat center / 100% 100%;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    pointer-events: none;
  }
  ${InputContainer} {
    background-color: #19102c;
    border-color: #475467;
    border-radius: 10px;
  }
  ${ButtonStyle} {
    button {
      width: 100%;
      min-height: 50px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 0px 18.108px 0px rgba(255, 255, 255, 0.06) inset;
      span {
        font-size: 14px;
      }
    }
  }
`;
export const AuthLogo = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-left: -35px;
  z-index: 5;
  img {
    width: 100%;
  }
`;
export const AuthBlock = styled.div`
  label {
    display: block;
    margin-bottom: 5px;
    color: #a0aee5;
    span {
      color: #d90c0c;
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const ErrorState = styled.p`
  margin-top: 10px;
  color: #d90c0c;
  font-size: 14px;
`;
