import { styled } from "styled-components";

export const AMFITeamStyle = styled.div`
  width: 100%;
`;

export const AMFITeamContainerStyle = styled.div`
  max-width: 1075px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
`;

export const AMFITeamTitleStyle = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
  p {
    color: #a0aee5;
    text-align: center;
    font-size: 121px;
    line-height: 20.405px;
    letter-spacing: 0.268px;
  }
`;

export const AMFITeamListCartStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-top: 40px;
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
`;

export const AMFITeamCartStyle = styled.div``;

export const AMFITeamCartStyleInner = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid transparent;
  background: #000;
  transition: all 0.25s;
  &:hover {
    border-color: #7f56d9;
    box-shadow: 0px 0px 25px 0px rgba(127, 56, 183, 0.29);
  }
  @media screen and (max-width: 1023px) {
    border-color: #7f56d9;
    box-shadow: 0px 0px 25px 0px rgba(127, 56, 183, 0.29);
  }
`;

export const AMFITeamCartImageStyle = styled.div`
  max-width: 280px;
  margin-bottom: 10px;
`;

export const AMFITeamCartNameStyle = styled.div`
  h1 {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  h4 {
    color: rgba(240, 240, 240, 0.92);
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  a {
    display: block;
    width: 25px;
    height: 25px;
    margin: 10px auto 0;
  }
`;
