import styled from "styled-components";

export const HeaderDashboardContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 292px);
  margin-left: auto;
  border-bottom: 1px solid #344054;
  background: #1b1c20;
  transition: all 0.25s ease-in;
  z-index: 500;
  &.collapse {
    width: calc(100% - 100px);
  }
  @media screen and (min-width: 1024px) and (max-width: 1221px) {
    width: calc(100% - 220px);
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    min-height: auto;
    z-index: 1000;
    height: auto;
  }
`;
export const HDWrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    padding: 20px;
  }
`;
export const HDLeft = styled.div`
  span {
    color: #667085;
    display: block;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;
export const HDPageTitle = styled.p`
  color: #fcfcfd;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
`;
export const HDRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  @media screen and (max-width: 1023px) {
    margin-left: auto;
    margin-right: 15px;
  }
`;
export const ProfileInfo = styled.div`
  p {
    color: #fcfcfd;
    font-size: 18px;
    font-weight: 700;
  }
  span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #667085;
  }
`;
export const ProfileIcon = styled.div`
  width: 37px;
  height: 37px;
  background-color: #6941c6;
  border-radius: 50%;
  cursor: pointer;
`;
