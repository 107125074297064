import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuth, signOut } from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import { IsLoadingRedux } from "../../redux/slices/user";
import { dispatch } from "../../redux/store";

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth();
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      if (idToken) {
        dispatch(IsLoadingRedux(true));
        return {
          headers: {
            ...headers,
            Authorization: `${idToken}`,
          },
        };
      }
    }
  } catch (error) {
    console.error("Error retrieving idToken:", error);
  }

  const storedToken = localStorage.getItem("idToken");
  if (storedToken) {
    const decodedToken = jwtDecode(storedToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      localStorage.removeItem("idToken");
      signOut(auth)
      try {
        const refreshedToken = await auth?.currentUser?.getIdToken(true);
        if (refreshedToken) {
          localStorage.setItem("idToken", refreshedToken);
          return {
            headers: {
              ...headers,
              Authorization: `${refreshedToken}`,
            },
          };
        }
      } catch (refreshError) {
        console.error("Error refreshing idToken:", refreshError);
      }
    } else {
      return {
        headers: {
          ...headers,
          Authorization: `${storedToken}`,
        },
      };
    }
  }
  return { headers };
});

const httpLink = createHttpLink({
  uri: "https://graph.aimetafi.co/graphql/",
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {},
      },
      Mutation: {
        fields: {},
      },
    },
  }),
});
