import React, {
  useEffect,
  useState,
  useRef,
  memo,
  useCallback,
  useContext,
} from "react";
import {
  ArtificialButtonRegenerate,
  ArtificialButtons,
  ArtificialButtonSave,
  ArtificialContainer,
  ArtificialContentInner,
  ArtificialContentInnerBottom,
  ArtificialContentInnerBottomInner,
  ArtificialContentInnerTop,
  ArtificialContentInnerTopTetArea,
  ArtificialContentLeft,
  ArtificialContentText,
  ArtificialTitleInfo,
  ArtificialToken,
  ArtificialWrapper,
  ButtonSurprise,
} from "../../ArtificialIntelligenceVideo/styled";
import Example from "../../../Example";
import { ContentWork, ContentWorkList, TitleArtWork } from "./styled";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { convertFormatNumber } from "../../../../../utils/convertFormatNumber";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../redux/store";
import { IsLoadingRedux, UserOverview } from "../../../../../redux/slices/user";
import LoadingCube from "../../../../../components/LoadingCube";
import { DashboardTitle } from "../../../../../Layout/styled";
import { ContextProviderWrapper } from "../../../../../components/Context";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

const TextToImage = () => {
  const [textArea, setTextArea] = useState("");
  const [countCharactor, setCountCharactor] = useState(0);
  const [dataTextToImgae, setDataTextToImage] = useState<any>();
  const [loadingImage, setLoadingImage] = useState(false);
  const intervalRef = useRef<any>();
  const { userProfile }: any = useSelector((state: any) => state.user);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [currentCredit, setCurrentCredit] = useState<any>();

  //handle random surprise
  const surpriseText = [
    "Vintage 90's anime style environmental wise shot of a chaotic arcade at night; a boy wearing streetwear;  sci-fi, colors, neon lights. Line art. Environmental arcade art.",
    "Hyper detailed alien planet white and blue with green mountain and pink clouds, 90' anime vintage style, in the center of the image, high quality",
    "Island with Fortress and Treasures Pirate, color pop style, perfect composition, beautiful, detailed, octane render, trending on artstation, concept art, game, soft natural volumetric cinematic perfect light, masterpiece, by raphael, caravaggio",
    "Queen of hearts, beautiful, roses, throne, heart jewelry, red hair, Alice in Wonderland, red queen, realistic, ultra-realistic photo, queen beautiful face, intricate, highly detailed, smooth, sharp focus",
    "Highly detailed epic photo of candy land with a chocolate river, intricate, dystopian, magic, extremely detailed, digital painting, cartoon, concept art, smooth, sharp focus, artstation",
    "Macro rose, ink, photo, realistic, fine detail, detail, vibrant, a multicolored rose floating through the vibrant microscopic quantum world, intricate details, insane composition, hdr 8k",
    "Billie Eilish, gothic, street gangster, gorgeous and beautiful, queen Billie Eilish, highly detailed, realistic, photorealistic",
    "A contemporary glass-walled luxury residential retreat,  in a forest oasis with a waterfall atop the mount at dusk. Amber incandescent light streams from the structure in its magenta and deep blue environment. Glowing, radiating energy. Pink windows.",
    "A pink baby turtle, playing the guitar and singing into the microphone, highly detailed, 3 d, dreamy, raytracing, trending on artstation",
    "Chinese style, water stream, mountains, mist, hyper realistic, lush gnarly plants, 8 k, denoised, cinematic, fengshui",
    "Human colony on an unknown planet, bright vibrant colored vegetation, bioluminescent planet, hyperrealism, highly detailed, insanely detailed, trending on artstation",
    "Cut paper sculpture horse, forest background, highly detailed",
    "House in the style of 90's vintage anime, outdoor, line art, pastel colors, 8 k",
    "Jellyfish, forest, underwater, glow, full of colour, cinematic lighting, biology, masterpiece, fantasy, radiant light, ultra detailed, cinematic, sharp focus",
    "Summer, gothic, dark, background, stars, sun, moon, pink, forest",
    "Treacherous path, difficult path, surreal, mountains, dark, adventure, light on the horizon, ultra 4k",
    "Intricately detailed grunge and fantasy stuffed cute orange cat as a pirate, pirate treasure, diamonds, colorful gemstones, glowing light from behind, furry, complex extremely hyperdetailed, perfect composition, gorgeously, complex, insanely, detailed, octane rendering, trend on artstation, award-winning photography, masterpiece, depth of field, cinematic, ultra detailed, 12k, 84 mm",
  ];

  const getRandomResearchTitle = () => {
    if (surpriseText.length === 0) {
      return "All items have been used!";
    }
    const randomIndex = Math.floor(Math.random() * surpriseText.length);
    const randomResearchTitle = surpriseText[randomIndex];
    // Remove the selected item from the array
    surpriseText.splice(randomIndex, 1);
    return randomResearchTitle;
  };

  const handleGenerate = () => {
    const randomResearchTitle = getRandomResearchTitle();
    setTextArea(randomResearchTitle);
  };

  //api gen text to image
  const TEXT_TO_IMAGE = gql`
    mutation textToImage($text: String!) {
      textToImage(text: $text)
    }
  `;
  const [textToImage] = useMutation(TEXT_TO_IMAGE);

  // api check data
  const GET_AIGEN = gql`
    query aigen($id: String!) {
      aigen(id: $id) {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;

  const [FetchDataGen, { data: dataGen }] = useLazyQuery(GET_AIGEN, {
    fetchPolicy: "network-only",
  });

  // api history gen
  const GET_AIGENS = gql`
    query aigens {
      aigens {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;
  const { data: dataGens, refetch: refetchGens } = useQuery(GET_AIGENS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    dispatch(UserOverview(data));
    if (dataGen?.aigen?.resultUrl) {
      clearInterval(intervalRef.current);
      setLoadingImage(false);
      refetchGens();
      toast.success("Gen Image Success");
      refetch();
      setImgList([dataGen?.aigen?.resultUrl]);
    }
  }, [dataGen]);

  const handleGenTextToImage = useCallback(async () => {
    setLoadingImage(true);
    setTextArea("");
    refetchGens();
    try {
      const res: any = await textToImage({
        variables: {
          text: textArea,
        },
      });
      if (res) {
        await setDataTextToImage(res);
        intervalRef.current = setInterval(() => {
          FetchDataGen({
            variables: {
              id: res?.data?.textToImage,
            },
          });
        }, 5000);
      }
    } catch (error: any) {
      console.log(error);
      setLoadingImage(false);
    }
  }, [textArea, textToImage, FetchDataGen]);

  //handle change textArea
  const onChangeTextArea = (e: any) => {
    const newValue = e.target.value;
    if (newValue.length <= 734) {
      setTextArea(newValue);
      setCountCharactor(newValue.length);
    }
  };

  useEffect(() => {
    setCountCharactor(textArea.length);
  }, [textArea]);

  // handle reload data user
  const GET_USER = gql`
    query {
      user {
        email
        refCode
        phoneNumber
        accountID
        secret
        createdAt
        children
        levelAMFI
        packUSDT
        verified2FA
        totalSales
        totalSalesLeft
        totalSalesRight
        totalLeftDirectLine
        totalRightDirectLine
        directCommission
        rightReserve
        leftReserve
        totalRevenue
        modeTree
        bonusVerify
        totalBonus
        totalWeakBranch
        totalStaking
        maxOutUSDT
        totalDepositAMFI
        totalDepositUSDT
        parentEmail
        wallet {
          bot {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          aimetafi {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          usdt {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          point {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          revenue {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          bonus {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
        }
        affiliate {
          displayName
          email
          createdAt
          branch
          accountID
          packAMFI
          packUSDT
          parentID
          children
          levelAMFI
          refCode
        }
        transactionRevenue {
          amount
          id
          note
          createdAt
          transactionType
        }
        packInvestingAMFI {
          price
          rate
          priceAMFI
          expireDate
          packDatetime
          paymentCurrency
        }
      }
      tickets {
        id
        subject
        status
        messages {
          text
          author
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      kyc {
        status
        accountID
        frontURL
        backURL
        documentID
        country
      }
      settings {
        tag
        config
      }
    }
  `;
  const { data, refetch, loading: isFetchUser } = useQuery(GET_USER);

  //get credit current
  useEffect(() => {
    setCurrentCredit(userProfile?.user?.wallet?.aimetafi?.balance);
  }, [userProfile, FetchDataGen]);

  //handle download image
  const [imgList, setImgList] = useState<any>(null);
  const handleDownloadImage = (images: any) => {
    const downLoad = async (file: any) => {
      try {
        await saveAs(file, `${uuidv4()}`);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    images.forEach(async (element: any) => {
      await downLoad(element);
    });
  };

  return (
    <>
      <ArtificialContainer>
        <ArtificialWrapper>
          <ArtificialContentLeft>
            <ArtificialTitleInfo>
              <DashboardTitle background="#32D583">
                AI Text To Image
              </DashboardTitle>
              <ArtificialToken>
                <span>Credit:</span>
                <span>
                  {currentCredit
                    ? convertFormatNumber(currentCredit?.toFixed(4))
                    : "--"}
                </span>
              </ArtificialToken>
              {/* <div className="image">
                <img src="/assets/artificial/icn_img_01.svg" alt="" />
              </div> */}
            </ArtificialTitleInfo>
            <ArtificialContentInner>
              {!isMobile && (
                <p className="text-bio">
                  Dream it, and use text-to-image to visualize it. Simply type
                  your text description, and our AI generator lets you create
                  images in seconds.
                </p>
              )}
              <ArtificialContentInnerTop>
                <ArtificialContentInnerTopTetArea>
                  <textarea
                    name="prompt"
                    value={textArea}
                    onChange={onChangeTextArea}
                    disabled={countCharactor >= 735}
                  ></textarea>
                  <ArtificialContentText>Your Prompt</ArtificialContentText>
                  <span className="number-charactor">{countCharactor}/735</span>
                </ArtificialContentInnerTopTetArea>
                <ContentWork>
                  {/* <TitleArtWork>Artwork size</TitleArtWork>
                  <ContentWorkList>
                    {listData.map((item: any, index: number) => (
                      <li
                        className={active === index ? "active" : ""}
                        key={index}
                        onClick={() => {
                          if (active === index) {
                            setActive("");
                          } else {
                            setActive(index);
                          }
                        }}
                      >
                        <div className={`item-inner`}>
                          <span className="title">{item.title}</span>
                          <span className="number">{item.number}</span>
                        </div>
                      </li>
                    ))}
                  </ContentWorkList> */}
                  <ButtonSurprise onClick={handleGenerate}>Surprise me</ButtonSurprise>
                  <ArtificialButtonSave
                    onClick={handleGenTextToImage}
                    disabled={textArea === "" ? true : false}
                  >
                    Generate (1 Credit)
                  </ArtificialButtonSave>
                </ContentWork>
              </ArtificialContentInnerTop>
              <ArtificialContentInnerBottom>
                <ArtificialTitleInfo
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <p>
                    Generated Image
                    <span>Check the image below</span>
                  </p>
                  <div className="image">
                    <img src="/assets/artificial/icn_img_02.svg" alt="" />
                  </div>
                </ArtificialTitleInfo>
                <ArtificialContentInnerBottomInner>
                  <div className="block-video">
                    {!loadingImage ? (
                      <img
                        className="image-icon"
                        src={
                          dataGen?.aigen?.resultUrl
                            ? dataGen?.aigen?.resultUrl
                            : "/assets/artificial/icn_img_03.png"
                        }
                        alt=""
                      />
                    ) : (
                      <LoadingCube />
                    )}
                  </div>
                  {!isMobile && (
                    <div className="block-text">
                      <ArtificialContentInnerTopTetArea>
                        <ArtificialContentText className="is-description">
                          Image Description
                        </ArtificialContentText>
                        <p className="text">{dataGen?.aigen?.text}</p>
                      </ArtificialContentInnerTopTetArea>
                      <button>Re-generate</button>
                    </div>
                  )}
                </ArtificialContentInnerBottomInner>
              </ArtificialContentInnerBottom>
              <ArtificialButtons>
                {isMobile && (
                  <ArtificialButtonRegenerate>
                    Re-generate
                  </ArtificialButtonRegenerate>
                )}
                <ArtificialButtonSave
                  onClick={() => handleDownloadImage(imgList)}
                >
                  Save Image
                </ArtificialButtonSave>
              </ArtificialButtons>
            </ArtificialContentInner>
          </ArtificialContentLeft>
          {!isMobile && <Example />}
        </ArtificialWrapper>
      </ArtificialContainer>
    </>
  );
};

const listData = [
  {
    id: 1,
    title: "Portrait",
    number: "9:16",
  },
  {
    id: 2,
    title: "Square",
    number: "1:1",
  },
  {
    id: 3,
    title: "Landscape",
    number: "16:9",
  },
];

export default memo(TextToImage);
