import { useContractRead } from "wagmi";
import { bsc } from "wagmi/chains";

function useGetPriceBox(address: any, abi: any) {
  try {
    return useContractRead({
      address,
      abi,
      functionName: "getPriceMintPhase2",
      chainId: bsc.id,
    });
  } catch (error) {
    console.log(error);
  }
}

export default useGetPriceBox;
