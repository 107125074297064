import {
  ButtonCommonSubmit,
  LinearGardientLayer,
  LoadingOverlay,
} from "../../Layout/styled";
import {
  BlockBig,
  BlockCopy,
  BlockSub,
  BuyContainer,
  BuyContent,
  BuyDecor,
  BuyInfo,
  BuyPresale,
  BuyText,
  BuyTitle,
  BuyValue,
  BuyWrapper,
  ConvertCurrency,
  ConvertValue,
  InfoBlock,
  PresaleCW,
  PresaleConvert,
  PresaleCurrency,
  PresaleHint,
  PresaleInput,
  PresaleInputWrapper,
  PresaleLogo,
  PresalePrice,
  PresaleTitle,
  PresaleValuePick,
  SubInfo,
} from "./styled";
import decor from "../../assets/BuyAMFI/decor.png";
import copy from "../../assets/BuyAMFI/copy.svg";
import icon_convert from "../../assets/BuyAMFI/icon_convert.svg";
import logo_presale from "../../assets/BuyAMFI/logo_presale.png";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { useAccount, useBalance, useChainId } from "wagmi";
import { shortenAddress } from "../../utils/addressUser";
import { AIMETAFI_API } from "../../services/api";
import { formatEther } from "ethers";
import LoadingBuy from "../../components/LoadingBuy";
import { parseEther } from "viem";
import { switchNetwork } from "@wagmi/core";
import useTransfer from "../../hooks/useTransferToken";
import useTransactionHash from "../../hooks/useTransactionHash";
import { convertFormatNumber } from "../../utils/convertFormatNumber";

const BuyAMFI = () => {
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [isLoading, setIsLoading] = useState(false);
  const [valueBuy, setValueBuy] = useState<any>(1);
  const isContract = "0xb88C4bcD76276434fd1984599DDF4CaC6665a8A3";
  const handleChangeValue = (value: any) => {
    setValueBuy(value);
  };

  // Get balance
  const getBalance: any = useBalance({
    address: address,
    token: isContract,
    watch: isLoading ? true : false,
  });
  const convertBalance =
    getBalance?.data && Number(formatEther(getBalance?.data?.value)).toFixed(4);

  // Get Token price
  const [tokenPrice, setTokenPrice] = useState<any>(0);
  const getPriceToken = async () => {
    try {
      const res: any = await AIMETAFI_API.getPriceToken();
      if (res && res?.status === 200) {
        setTokenPrice(res?.data?.Data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Wallet Owner
  const [walletOwner, setWalletOwner] = useState<any>();
  const getWalletOwner = async () => {
    try {
      const res = await AIMETAFI_API.getWalletOwner();
      if (res && res?.status === 200) {
        setWalletOwner(res?.data?.Data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPriceToken();
    getWalletOwner();
  }, []);

  // Handle switch chain
  const chainId = useChainId();
  const handleChangeNetwork = async () => {
    await switchNetwork({
      chainId: 56,
    });
  };

  // handle login
  const handleLogin = async () => {
    try {
      const params: any = {
        Address: address,
        Signature: "",
        ReferalCode: "",
      };
      const res: any = await AIMETAFI_API.login(params);
      if (res && res?.data.StatusCode === 200) {
        localStorage.setItem("Token", res?.data.Data.Token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (address) {
      handleLogin();
    }
  }, [address]);

  // Handle buy
  const [stateTxReceipt, setStateTxReceipt] = useState<any>();
  const { data: dataHash } = useTransactionHash(stateTxReceipt);
  const [ValueSubmit, setValueSubmit] = useState({
    InfoPayment: {},
    amount: 0,
    Type: "usdt",
  });
  const [maxUSDT, setMaxUSDT] = useState(0);
  const { writeAsync: buyAMFI } = useTransfer(
    walletOwner,
    parseEther(Number(valueBuy).toString())
  );

  const buyInvesUsdt = async () => {
    setIsLoading(true);
    try {
      await buyAMFI?.().then(async (txReceipt: any) => {
        setStateTxReceipt(txReceipt.hash);
      });
    } catch (error: any) {
      toast.error("You've rejected");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataHash) {
      setValueSubmit((prev) => ({
        ...prev,
        InfoPayment: JSON.stringify({
          blockHash: dataHash && dataHash?.blockHash,
          blockNumber: dataHash && Number(dataHash?.blockNumber.toString()),
          contractAddress: dataHash && dataHash?.contractAddress,
          confirmations: dataHash && 1,
          status: dataHash && 1,
          from: dataHash && dataHash?.from,
          to: dataHash && dataHash?.to,
          txhash: dataHash && dataHash?.transactionHash,
        }),
        amount: valueBuy,
        Type: ValueSubmit.Type,
      }));
    }
  }, [dataHash]);

  const handleBuyAmfi = async () => {
    setIsLoading(true);
    try {
      const res = await AIMETAFI_API.buyAMFI(ValueSubmit);
      if (res && res?.data.StatusCode === 200) {
        res?.data.StatusCode === 200
          ? toast.success(res?.data.Meg)
          : toast.success("Bought successfully");
        if (Number(window.localStorage.getItem("maxUSDT")) < enableICO?.Max) {
          setMaxUSDT((prev) => prev + valueBuy);
        }
      }
    } catch (error: any) {
      toast.error("An error has occurred");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const _length = Object.keys(ValueSubmit.InfoPayment).length;
    if (_length > 0 && localStorage.getItem("Token")!!) {
      handleBuyAmfi();
    }
  }, [ValueSubmit]);

  // Catch buyMax
  useEffect(() => {
    if (maxUSDT <= enableICO?.Max) {
      window.localStorage.setItem("maxUSDT", maxUSDT.toString());
    }
  }, [maxUSDT]);

  // Clear buyMax when change wallet
  useEffect(() => {
    if (address) {
      setMaxUSDT(0);
      window.localStorage.setItem("maxUSDT", "0");
    }
  }, [address]);

  // Validate when buy
  const handleValidateValue = () => {
    if (valueBuy <= enableICO?.Max && valueBuy <= convertBalance) {
      if (Number(window.localStorage.getItem("maxUSDT")) < enableICO?.Max) {
        buyInvesUsdt();
      } else {
        toast.error(`You have bought maximum $${enableICO?.Max}`);
      }
    } else if (valueBuy > enableICO?.Max) {
      toast.error(
        `Min is $${enableICO?.Min} and Maximum is $${enableICO?.Max}`
      );
    } else {
      toast.error("insufficient balance");
    }
  };

  // enable buy ICO
  const [enableICO, setEnableICO] = useState<any>();
  const valueData = [enableICO?.Min, enableICO?.Max];
  const handleEnableBuyICO = async () => {
    try {
      const res = await AIMETAFI_API.enableBuyICO();
      if (res) {
        setEnableICO(res?.data.Data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleEnableBuyICO();
  }, []);

  return (
    <>
      <BuyContainer>
        <LinearGardientLayer
          left="unset"
          right="-700px"
          top="-440px"
          bottom="unset"
          width="1048px"
          height="1110px"
          layer_1={true}
          layer_2={true}
        />
        <BuyWrapper>
          <BuyTitle>
            Buy
            <span>AIFI</span>
          </BuyTitle>
          <BuyContent>
            <BuyInfo>
              <p>AIFI Token Information</p>
              <InfoBlock>
                <BlockBig>
                  <BuyDecor>
                    <img src={decor} alt="decor" />
                  </BuyDecor>
                  <BuyText>Total Supply</BuyText>
                  <BuyValue>1,200,000,000</BuyValue>
                </BlockBig>
                <BlockSub>
                  <SubInfo>
                    <BuyDecor>
                      <img src={decor} alt="decor" />
                    </BuyDecor>
                    <BuyText>Name</BuyText>
                    <BuyValue>AIMETAFI</BuyValue>
                  </SubInfo>
                  <SubInfo>
                    <BuyDecor>
                      <img src={decor} alt="decor" />
                    </BuyDecor>
                    <BuyText>Symbol</BuyText>
                    <BuyValue>AIFI</BuyValue>
                  </SubInfo>
                </BlockSub>
              </InfoBlock>
              <InfoBlock style={{ flexDirection: "row-reverse" }}>
                <BlockBig>
                  <CopyToClipboard
                    text={isContract}
                    onCopy={() => toast.success("Coppied !")}
                  >
                    <BlockCopy>
                      <img src={copy} alt="copy" />
                    </BlockCopy>
                  </CopyToClipboard>
                  <BuyDecor>
                    <img src={decor} alt="decor" />
                  </BuyDecor>
                  <BuyText>Contract</BuyText>
                  <BuyValue>0xb88C...5a8A3</BuyValue>
                </BlockBig>
                <BlockSub>
                  <SubInfo>
                    <BuyDecor>
                      <img src={decor} alt="decor" />
                    </BuyDecor>
                    <BuyText>Network</BuyText>
                    <BuyValue>BSC</BuyValue>
                  </SubInfo>
                  <SubInfo>
                    <BuyDecor>
                      <img src={decor} alt="decor" />
                    </BuyDecor>
                    <BuyText>Demical</BuyText>
                    <BuyValue>18</BuyValue>
                  </SubInfo>
                </BlockSub>
              </InfoBlock>
            </BuyInfo>
            <BuyPresale>
              <PresaleLogo>
                <img src={logo_presale} alt="logo" />
              </PresaleLogo>
              <PresaleTitle>AIFI Pre-sale</PresaleTitle>
              <PresalePrice>
                <span>Price Token:</span>
                {tokenPrice || 0}$
              </PresalePrice>
              <PresaleHint>
                Each wallet can purchase a maximum of ${enableICO?.Max}
              </PresaleHint>
              <PresaleCW
                onClick={() => {
                  open();
                }}
              >
                {address ? shortenAddress(address) : "Connect Wallet"}
              </PresaleCW>
              <PresalePrice>
                <span>Your Balance:</span>
                {convertBalance || 0} USDT
              </PresalePrice>
              <PresaleInputWrapper>
                <PresaleInput>
                  <input
                    disabled
                    value={valueBuy}
                    placeholder="Please fill value..."
                    type="number"
                  />
                </PresaleInput>
                <PresaleCurrency>
                  <p>USDT</p>
                </PresaleCurrency>
              </PresaleInputWrapper>
              <PresaleValuePick>
                {valueData.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        handleChangeValue(item);
                      }}
                      key={index}
                    >
                      {item}
                    </div>
                  );
                })}
              </PresaleValuePick>
              <PresaleConvert>
                <ConvertValue>
                  <img src={icon_convert} alt="icon" />
                  <p>
                    {convertFormatNumber(Number(valueBuy / tokenPrice)).split(
                      ".00"
                    ) || 0}
                  </p>
                </ConvertValue>
                <ConvertCurrency>AIFI</ConvertCurrency>
              </PresaleConvert>
              <ButtonCommonSubmit
                onClick={() => {
                  address && chainId === 56
                    ? handleValidateValue()
                    : address && chainId === 56 && !enableICO?.IsEnable
                    ? toast.error("Cannot buy now")
                    : address && chainId !== 56
                    ? handleChangeNetwork()
                    : open();
                }}
                disabled={isLoading ? true : false}
              >
                <span>{isLoading ? "Loading..." : "Buy AIFI"}</span>
              </ButtonCommonSubmit>
            </BuyPresale>
          </BuyContent>
        </BuyWrapper>
      </BuyContainer>
      <LoadingOverlay
        style={{
          opacity: isLoading ? "1" : "0",
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <LoadingBuy />
      </LoadingOverlay>
    </>
  );
};

export default BuyAMFI;
