import styled from "styled-components";
import { ExchangeInput } from "../Dashboard/Swap/styled";
import line_exchange from "../../assets/Swap/line_exchange.png";
import { ButtonStyle } from "../Homev2/styled";

export const SwapAMFIContainer = styled.div`
  width: 456px;
`;
export const SwapTitle = styled.h2`
  background: linear-gradient(0deg, #a1aee5 0%, #ecf0ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 45px;
  margin-bottom: 50px;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;
export const SwapBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  ${ExchangeInput} {
    border: 1px solid #9e77ed;
    padding: 15px 70px 15px 10px;
  }
`;
export const SwapBlockText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 5px;
  p {
    color: #9e77ed;
    font-size: 16px;
    span {
      color: #d0d5dd;
      margin-left: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 13px;
    }
  }
`;
export const SwapBlockWrapper = styled.div`
  margin-bottom: 20px;
`;
export const SwapBalancePercentage = styled.ul`
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 10px;
  & > li {
    width: calc(25% - 3px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    color: #8e7ac7;
    border: 1px solid #7f56d9;
    transition: all 0.15s linear;
    cursor: pointer;
    &:hover {
      background: #7f56d9;
      color: #fcfcfd;
    }
  }
`;
export const SwapExchange = styled.div`
  position: relative;
  background: url(${line_exchange}) no-repeat center / 100% auto;
  margin-bottom: 10px;
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.15s linear;
    &:hover {
      transform: rotate(180deg);
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
export const SwapBlockInfo = styled.div`
  margin-bottom: 15px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    & > p {
      color: #a0aee5;
      letter-spacing: 0.268px;
    }
    & > span {
      color: #fff;
      letter-spacing: 0.268px;
      a {
        color: #fff;
      }
    }
  }
`;
export const SwapInputExchange = styled.div`
  position: relative;
  span {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const SwapButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${ButtonStyle} {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 60%;
    }
  }
`;
