import styled from "styled-components";
import special_item_hover from "../../../assets/Common/Info/special_item_hover.png";
import special_item_normal from "../../../assets/Common/Info/special_item_normal.png";
import rectangle_before from "../../../assets/Common/Info/rectangle_before.png";

export const InfoContainer = styled.div``;
export const InfoWrapper = styled.div``;
export const InfoImage = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-bottom: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const InfoImage2 = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-bottom: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const InfoTitle = styled.p`
  color: #fcfcfd;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    font-size: 13px;
    margin-bottom: 5px;
  }
`;
export const InfoTitle2 = styled.p`
  color: #ff60ef;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  padding-bottom: 5px;
  width: fit-content;
  padding-right: 15px;
`;
export const InfoValue = styled.p`
  color: #b692f6;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  transition: all 0.3s ease;
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
`;
export const InfoContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const InfoItem = styled.li`
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / cover;
    transition: all 0.3s ease;
  }
  &::after {
    opacity: 0;
  }
  &.original {
    background: #000;
    &::before,
    &::after {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    &::before {
      background-image: url(${special_item_normal});
    }
    &::after {
      background-image: url(${special_item_hover});
    }
    &:hover {
      background-color: #17002a;
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
    }
  }
  &.glassmorphism {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 0px 51px 0px rgba(161, 174, 229, 0.08) inset;
    &::before {
      top: 0px;
      right: 0px;
      background-image: url(${rectangle_before});
      background-size: 100%;
      background-position: 100px -100px;
      z-index: 1;
    }
    &::after {
      content: none;
    }
    &:hover {
      background: rgba(196, 76, 255, 0.12);
    }
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      content: none;
    }
    &.glassmorphism {
      background-color: #000;
    }
  }
`;
export const InfoList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  & > li {
    position: relative;
    display: flex;
    width: calc((100% - 36px) / 3);
    min-height: 120px;
    padding: 12px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid transparent;
    position: relative;
    transition: background 0.3s ease;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      padding: 0px;
      transition: background width height 0.3s ease;
    }
    &:hover {
      ${InfoValue} {
        color: #fff;
      }
    }
  }
  @keyframes RotateLine {
    to {
      --angle: 360deg;
    }
  }
  @keyframes borderOpacity {
    0% {
      opacity: 0.75;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.75;
    }
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        border-radius: 12px;
        padding: 1px;
        box-sizing: border-box;
        background: conic-gradient(
          from calc(var(--angle) + var(--start-angle)),
          transparent 0,
          #fff 20%,
          transparent 25%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: xor;
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        animation: RotateLine 12s linear infinite,
          borderOpacity 3s linear infinite;
      }
    }
  }
  @media only screen and (max-width: 1221px) {
    & > li {
      width: calc(50% - 6px);
    }
  }
  @media screen and (max-width: 767px) {
    & > li {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 0;
      }
      &:nth-child(3) {
        order: 1;
      }
    }
  }
`;

export const InfoListStyle2 = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  .colum2 {
    background: linear-gradient(
      45deg,
      rgba(139, 137, 139, 0.28),
      rgba(33, 23, 94, 0.25),
      rgba(255, 255, 255, 0.11)
    );
  }
  & > li {
    position: relative;
    display: flex;
    width: calc((100% - 12px) / 2);
    min-height: 120px;
    padding: 12px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid transparent;
    position: relative;
    transition: background 0.3s ease;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      padding: 0px;
      transition: background width height 0.3s ease;
    }

    @keyframes RotateLine {
      to {
        --angle: 360deg;
      }
    }
    @keyframes borderOpacity {
      0% {
        opacity: 0.75;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.75;
      }
    }
    ${InfoValue} {
      color: #fff;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          border-radius: 12px;
          padding: 1px;
          box-sizing: border-box;
          background: conic-gradient(
            from calc(var(--angle) + var(--start-angle)),
            transparent 0,
            #fff 20%,
            transparent 25%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask-composite: xor;
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          animation: RotateLine 12s linear infinite,
            borderOpacity 3s linear infinite;
        }
      }
    }
  }
  @media only screen and (max-width: 1221px) {
    & > li {
      width: 100%;
      background: #000 !important;
    }
  }

  @media screen and (min-width: 1220px) {
    &.assets {
      flex-wrap: nowrap;
      align-items: unset;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1219px) {
    &.assets {
      flex-wrap: wrap;
      .colum2 {
        width: 100%;
      }
    }
  }
`;
export const InfoWrapperButtons = styled.div`
  position: absolute;
  right: 12px;
  top: 24px;
  z-index: 5;
  display: flex;
  gap: 10px;
`;
export const InfoButton = styled.div`
  a {
    border-radius: 42px;
    border: 1px solid rgba(161, 174, 229, 0.2);
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 5px 14.916px 0px rgba(255, 255, 255, 0.1) inset;
    font-size: 14px;
    span {
      background: linear-gradient(180deg, #cdd7ff 0%, #ebefff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
export const SplitInfoItem = styled.div`
  &.info_total {
    display: flex;
    gap: 20px;
    & > ul {
      & > li {
        ${InfoContent} {
          gap: 10px;
        }
        ${InfoTitle} {
          font-size: 14px;
        }
      }
    }
    .total {
      width: 30%;
      ${InfoItem} {
        width: 100%;
        height: 100%;
      }
    }
    .others {
      width: 70%;
      ${InfoItem} {
        width: calc((100% - 15px) / 2);
      }
    }
  }
  @media screen and (max-width: 550px) {
    &.info_total {
      display: block;
      & > ul {
        & > li {
          width: 100% !important;
        }
      }
      .total {
        margin-bottom: 12px;
      }
      .total,
      .others {
        width: 100%;
      }
    }
  }
`;
