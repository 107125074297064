import styled from "styled-components";

export const InputContainer = styled.div`
  .ant-input-password {
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 16px 50px 16px 24px;
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 24px;
    }
  }
  input,
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 16px 24px;
    color: #fff;
    font-size: 16px;
    border-radius: 9px;
    border: 1px solid #475467;
    background-color: transparent;
    &.ant-input-outlined {
      background-color: transparent;
      &:focus {
        border-color: #b692f6;
      }
    }
    &:hover {
      border-color: #b692f6;
    }
    &::placeholder {
      color: #667085;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #667085;
    }
    .ant-input-suffix {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;
