import styled from "styled-components";
import { SelectContainer } from "../Common/Select/styled";
import bg from "../../assets/Kyc/kyc_bg.png";
import { ButtonStyle } from "../../page/Homev2/styled";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in;
`;
export const ModalWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  min-height: 526px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(49, 49, 49, 0);
  background: linear-gradient(
    262deg,
    rgba(87, 0, 101, 0.28) 0.29%,
    rgba(26, 21, 59, 0.17) 49.79%,
    rgba(112, 0, 255, 0.05) 99.3%
  );
  backdrop-filter: blur(6px);
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat center / cover;
  }
  ${SelectContainer} {
    width: 200px;
  }
  ${ButtonStyle} {
    width: 50%;
    margin-left: auto;
    overflow: hidden;
    button {
      width: 100%;
      height: 50px;
      border-radius: 20px;
    }
  }
`;

export const ModalRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

export const ModalRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
`;

export const ModalImage = styled.div`
  max-width: 320px;
  min-height: 200px;
  margin: 0 auto;
`;

export const ModalContent = styled.div`
  position: relative;
  z-index: 2;
  h1 {
    color: #a0aee5;
    font-size: 15px;
    width: 100px;
    margin-bottom: 10px;
  }
  span {
    color: #a0aee5;
    font-size: 15px;
  }
`;
export const ModalTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(0deg, #b6c2f1 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ModalClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
