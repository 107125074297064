import { bsc } from "viem/chains";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { parseGwei } from "viem";
import ABI_AMFI from "../contract/ABI/ABI_AMFI.json";

const useTransfer = (to: any, amount: any) => {
  const addressContract: any = process.env.REACT_APP_CONTRACT_TOKEN_AMFI;
  const { config } = usePrepareContractWrite({
    address: addressContract,
    abi: ABI_AMFI,
    functionName: "transfer",
    args: [to, amount],
    chainId: bsc.id,
    gas: BigInt(1_000_000),
    gasPrice: parseGwei("3"),
  });

  const { data, isLoading, isSuccess, isError, error, writeAsync, write } =
    useContractWrite(config);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    writeAsync,
    write,
  };
};

export default useTransfer;
