import styled from "styled-components";
import { ButtonSurprise } from "../../ArtificialIntelligenceVideo/styled";

export const ContentWork = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  ${ButtonSurprise} {
    height: 44px;
  }
`
export const TitleArtWork = styled.h3`
  color: var(--Colors-Primary-Colors-Gray-25, #FCFCFD);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
`
export const ContentWorkList = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  li {
    width: calc(100% / 3 - 8px);
    border-radius: var(--Parameters-Radius-sm, 6px);
    background: #2D2F37;
    padding: 12px 10px;
    transition: all .26s ease;
    border: 1px solid transparent;
    &.active {
      border-radius: var(--Parameters-Radius-sm, 6px);
      border: 1px solid var(--Gradient, #C44CFF);
      background: #FFF;
      .title {
        color: var(--Base-Black, #000);
      }
    }
    .item-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
      transition: color .26s ease;
    }
    .number {
      font-size: 13px;
      background: linear-gradient(90deg, #C44CFF 0%, #8600FC 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  @media screen and (max-width: 767.98px) {
    display: block;
    li {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`