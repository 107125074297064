import { useTranslation } from "react-i18next";
import { BorderAnimation } from "../../Layout/styled";
import { HomeStyle } from "../../page/Homev2/styled";
import { AnimatedText } from "../TextAnimation";
import { ClearlyRoadmapStyle, ImageLeftStyle, ImageRightStyle } from "./styled";

export default function ClearlyRoadmap() {
  const { t } = useTranslation();
  const LIST_DATA_CARD = [
    {
      content: `${t("Develop idea and Technical")}`,
      title: "Q3 - 2023",
    },
    {
      content:
        `${t("Reveal Website / WPP") + "\n"}` +
        `${t("Develop Smart Contract") + "\n"}` +
        `${t("Community Building") + "\n"}` +
        `${t("Branding")}`,
      title: "Q4 - 2023",
    },
    {
      title: "Q1,Q2 - 2024",
      content:
        `${t("Private sale & Public sale") + "\n"}` +
        `${t("Release AI Chat") + "\n"}` +
        `${t("Release Staking Program")}`,
    },
    {
      title: "Q3-Q4 - 2024",
      content:
        `${t("Launch New AI Applications: AI Image, AI video") + "\n"}` +
        `${t("Listing CEX/DEX") + "\n"}` +
        `${t("Launch Blocktrading Exchange") + "\n"}`,
    },
    {
      content:
        `${t("Launch AIMETAFI SocialFi Beta") + "\n"}` +
        `${t("Beta AMFI Wallet") + "\n"}` +
        `${t("Release NFT Marketplace") + "\n"}`,
      title: "2025",
    },
    {
      title: "2026",
      content:
        `Develop more Products`,
    },
  ];
  return (
    <ClearlyRoadmapStyle id="roadmap">
      <ImageLeftStyle>
        <img src="/assets/images/img-left-road.png" alt="img-left-road" />
      </ImageLeftStyle>
      <ImageRightStyle>
        <img src="/assets/images/img-star-purple.png" alt="img-rolad-road" />
      </ImageRightStyle>
      <HomeStyle>
        <div className="title-amfi-token">
          <AnimatedText data-aos="fade-up" el="h2" text={t("Roadmap")} />
          <p data-aos="fade-up">
            {t(
              "From vision to reality: AIMETAFI's roadmap paves the way for a vibrant ecosystem where creativity"
            )}
          </p>
          <p data-aos="fade-up">{t("blooms and finances flourish.")}</p>
        </div>
        <div className="gif-for-ai-metafi" data-aos="fade-up">
          <div className="gif">
            <img src="/assets/images/img-road.png" alt="img-road" />
          </div>
        </div>
        <div className="list-card">
          {LIST_DATA_CARD.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="card-item"
                data-aos="fade-up"
                data-aos-delay={index * 150}
              >
                <BorderAnimation />
                <h3>{item.title}</h3>
                <p style={{ whiteSpace: "pre-line" }}>{item.content}</p>
              </div>
            );
          })}
        </div>
      </HomeStyle>
    </ClearlyRoadmapStyle>
  );
}
