import { useTranslation } from "react-i18next";
import { ButtonStyle } from "../../page/Homev2/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import OurItem from "./OurItem";
import { VersionAndMissionStyle } from "./styled";

export default function VersionAndMission() {
  const { t } = useTranslation();
  const VERSION_MISSION = [
    {
      image: "/assets/icons/icon-version.png",
      title: `${t("Our Vision")}`,
      content: `${t(
        "Combining the power of AI with Web3 to build an ecosystem where people can connect, create, trade, earn and shop safely, privately, and profitably. Become a top AI-powered gateway to Web3 for 1 billion users"
      )}`,
    },
    {
      image: "/assets/icons/icon-mission.png",
      title: `${t("Our Mission")}`,
      content: `${t(
        "Helping users use AI to create the digital world they want, and from there, fully exploit the profits from the digital assets they create or own, increasing investment opportunities and experience."
      )}`,
    },
  ];
  return (
    <VersionAndMissionStyle id="versionAndMission">
      <ButtonStyle data-aos="fade-up">
        <button>
          <StarUp />
          <span>{t("What we see")}</span>
          <img src="/assets/icons/icon-go-staking.png" alt="icon-go-staking" />
        </button>
      </ButtonStyle>
      <AnimatedText el="h2" text={`${t("Vision & Mission")}`} />
      <div className="version-mission">
        {VERSION_MISSION.map((item: any, index: number) => {
          return <OurItem key={index} data={item} />;
        })}
      </div>
    </VersionAndMissionStyle>
  );
}
