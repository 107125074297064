import styled from "styled-components";
import {
  ArtificialButtonSave,
  ArtificialContentRight,
  ArtificialWrapper,
} from "../../ArtificialIntelligenceVideo/styled";

export const HistoryContainer = styled.div`
  ${ArtificialWrapper} {
    align-items: flex-start;
  }
  ${ArtificialContentRight} {
    position: relative;
  }
`;
export const HistoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  li {
    width: calc(100% / 4 - 10px);
    height: 17vw;
    border-radius: var(--Parameters-Radius-xl, 12px);
    background: #383a41;
    box-shadow: 0px 0px 11.261px 0px rgba(0, 0, 0, 0.09);
    padding: 10px;
    cursor: pointer;
    position: relative;
    img,
    video {
      border-radius: 8.745px;
      width: 100%;
      height: 88%;
      object-fit: cover;
    }
    p,
    time {
      color: #d1d1d1;
      font-size: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .icon-play {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  @media screen and (max-width: 767.98px) {
    li {
      padding: 0;
      width: calc(100% / 3 - 7.32px);
      height: 118px;
      border: 1px solid #868686;
      overflow: hidden;
      img,
      video {
        height: 100%;
      }
      .icon-play {
        top: 66%;
      }
    }
  }
`;
export const HistoryListRightInner = styled.div`
  padding: 16px;
`;
export const HistoryListRightInnerImage = styled.div`
  text-align: center;
  img,
  video {
    border: 1.571px solid #737786;
    border-radius: 12px;
    width: 100%;
  }
`;
export const HistoryListRightBlockDetail = styled.div`
  .content-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    p {
      color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      border-radius: var(--Parameters-Radius-md, 8px);
      border: 1px solid var(--stroke-25-button, #3e3f44);
      background: var(--Control-Active, rgba(248, 248, 248, 0.05));
      background-blend-mode: luminosity;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.3);
      padding: 5px 16px 3px;
    }
  }
  h3 {
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
  }
  p {
    color: #d1d1d1;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin-bottom: 10px;
  }
`;
