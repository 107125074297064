import { useEffect, useState } from "react";
import Token from "../../page/Home/Token";
import { ButtonStyle } from "../../page/Homev2/styled";
import { AMFITokenModal, AMFITokenStyle } from "./styled";
import { BorderAnimation } from "../../Layout/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import { EcosystemBackground } from "../Ecosystem/styled";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import copy from "../../assets/BuyAMFI/copy.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AMFIToken() {
  const { t } = useTranslation()
  const LIST_DATA_CARD = [
    {
      content: `${t("Token’s name")}`,
      title: "AIMETAFI",
    },
    {
      content: `${t("Symbol")}`,
      title: "AIFI",
    },
    {
      title: "1,200,000,000",
      content: `${t("Total Supply")}`,
    },
    {
      title: "18",
      content: `${t("Demical")}`,
    },
    {
      content: `${t("Network")}`,
      title: "Binance Smart Chain",
    },
    {
      title: "0xb88C...65a8A3",
      content: `${t("Contract")}`,
    },
  ];
  const LIST_DATA_CARD_TRADING = [
    {
      content: `${t("Token’s name")}`,
      title: "Block Trading",
    },
    {
      content: `${t("Symbol")}`,
      title: "BOT",
    },
    {
      title: "1,200,000,000",
      content: `${t("Total Supply")}`,
    },
    {
      title: "18",
      content: `${t("Demical")}`,
    },
    {
      content: `${t("Network")}`,
      title: "Binance Smart Chain",
    },
    {
      title: "0xAb4E...48a36",
      content: `${t("Contract")}`,
    },
  ];
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openModal]);

  return (
    <>
      <AMFITokenStyle id="tokenomic">
        <EcosystemBackground>
          <div className="img-purple">
            <img src="/assets/background/bg-purple.png" alt="bg-purple" />
          </div>
          <div className="img-round">
            <img src="/assets/background/bg-round.png" alt="bg-round" />
          </div>
        </EcosystemBackground>
        <div className="gif-for-ai-metafi">
          <div className="gif">
            <img src="/assets/images/gif-ai-metafi.gif" alt="gif-ai-metafi" />
          </div>
        </div>
        <div className="title-amfi-token amfi-token-1">
          <ButtonStyle data-aos="fade-up">
            <button>
              <StarUp />
              <span>{t("$AIFI Token")}</span>
              <img
                src="/assets/icons/icon-go-staking.png"
                alt="icon-go-staking"
              />
            </button>
          </ButtonStyle>
          <AnimatedText data-aos="fade-up" el="h2" text={t("The AIFI Token")} />
          <p data-aos="fade-up">{t("Unleash your creative potential.")}</p>
          <p data-aos="fade-up">
            {t("Create, share, trade, shop and thrive in the AIMETAFI ecosystem")}
          </p>
        </div>
        <div className="list-card list-1">
          {LIST_DATA_CARD.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="card-item"
                data-aos="fade-up"
                data-aos-delay={index * 150}
              >
                <BorderAnimation />
                <p>{item.content}</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {item.content === "Contract" ? (
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to="https://bscscan.com/token/0xb88C4bcD76276434fd1984599DDF4CaC6665a8A3"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <h3>{item.title}</h3>
                  )}
                  <CopyToClipboard
                    text={"0xb88C4bcD76276434fd1984599DDF4CaC6665a8A3"}
                    onCopy={() => toast.success("Coppied !")}
                  >
                    {item.content === "Contract" ? (
                      <img
                        style={{ cursor: "pointer" }}
                        src={copy}
                        alt="copy"
                      />
                    ) : (
                      <></>
                    )}
                  </CopyToClipboard>
                </div>
              </div>
            );
          })}
        </div>

        <div className="title-amfi-token amfi-token-2">
          <ButtonStyle data-aos="fade-up">
            <button>
              <StarUp />
              <span>{t("$BOT Token")}</span>
              <img
                src="/assets/icons/icon-go-staking.png"
                alt="icon-go-staking"
              />
            </button>
          </ButtonStyle>
          <AnimatedText data-aos="fade-up" el="h2" text={t("The BOT Token")} />
          <p data-aos="fade-up">{t("Unleash your creative potential.")}</p>
          <p data-aos="fade-up">
            {t("Create, share, trade, shop and thrive in the Block Trading ecosystem")}
          </p>
        </div>
        <div className="list-card list-2">
          {LIST_DATA_CARD_TRADING.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="card-item"
                data-aos="fade-up"
                data-aos-delay={index * 150}
              >
                <BorderAnimation />
                <p>{item.content}</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {item.content === "Contract" ? (
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to="https://bscscan.com/token/0xAb4E76b1a6857b242D2b77404A75472E4F348a36"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <h3>{item.title}</h3>
                  )}
                  <CopyToClipboard
                    text={"0xAb4E76b1a6857b242D2b77404A75472E4F348a36"}
                    onCopy={() => toast.success("Coppied !")}
                  >
                    {item.content === "Contract" ? (
                      <img
                        style={{ cursor: "pointer" }}
                        src={copy}
                        alt="copy"
                      />
                    ) : (
                      <></>
                    )}
                  </CopyToClipboard>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="button-Enhanced">
          <ButtonStyle data-aos="fade-up">
            <button
              onClick={() => {
                handleOpenModal();
              }}
            >
              <StarUp />
              <span>{t("See the Token Allocation")}</span>
            </button>
          </ButtonStyle>
        </div> */}
      </AMFITokenStyle>
      {/* <AMFITokenModal
        style={{
          opacity: openModal ? "1" : "0",
          visibility: openModal ? "visible" : "hidden",
        }}
      >
        <Token setOpenModal={setOpenModal} />
      </AMFITokenModal> */}
    </>
  );
}
