import { ButtonStyle } from "../../page/Homev2/styled";
import {
  BannerContainer,
  BannerContainerStyle,
  BannerImgCenterStyle,
  BannerImgLeftStyle,
  BannerImgRightStyle,
  BannerLeftStyle,
  BannerRightStyle,
  BannerStyle,
  ButtonScrollDownStyle,
  ButtonScrollDownWraperStyle,
} from "./styled";
import StarUp from "../StarUp";
import { useTranslation } from "react-i18next";

export default function Banner() {
  const {t} = useTranslation()
  return (
    <BannerContainer>
      <BannerStyle id="home">
        <BannerContainerStyle>
          <BannerLeftStyle>
            <ButtonStyle data-aos="fade-right">
              <button>
                <StarUp />
                <span>{t("AI Integration")}</span>
                <img
                  src="/assets/icons/icon-go-staking.png"
                  alt="icon-go-staking"
                />
              </button>
            </ButtonStyle>
            <h1>
              {t("The First AI & Blockchain Social")}
              <br />
              {t("DEFI ECOSYSTEM")}
            </h1>
            <p style={{ textTransform: "capitalize" }}>
            {t("The All-In-One Platform For Creators, Investors And AI Enthusiasts")}
            </p>
            <div
              style={{
                maxWidth: "240px",
                marginTop: "20px",
              }}
            >
              <ButtonStyle>
                <img
                  src="/assets/images/img-rating.png"
                  alt="icon-go-staking"
                />
              </ButtonStyle>
            </div>
          </BannerLeftStyle>
          <BannerRightStyle>
            <BannerImgLeftStyle>
              <img src="/assets/images/img-banner-l.png" alt="banner" />
            </BannerImgLeftStyle>
            <BannerImgCenterStyle>
              <img src="/assets/images/img-banner-main.png" alt="banner" />
            </BannerImgCenterStyle>
            <BannerImgRightStyle>
              <img src="/assets/images/img-banner-r.png" alt="banner" />
            </BannerImgRightStyle>
          </BannerRightStyle>
        </BannerContainerStyle>
        <ButtonScrollDownWraperStyle>
          <ButtonScrollDownStyle href="#versionAndMission">
            <button>
              <img src="/assets/icons/icon-down.png" alt="icon-down" />
            </button>
            <h1>{t("SCROLL DOWN")}</h1>
          </ButtonScrollDownStyle>
        </ButtonScrollDownWraperStyle>
      </BannerStyle>
    </BannerContainer>
  );
}
