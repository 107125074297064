import { useEffect, useState } from "react";
import {
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalImage,
  ModalRow,
  ModalRowBetween,
  ModalTitle,
  ModalWrapper,
} from "./styled";

import close from "../../assets/Kyc/close.svg";
import { ButtonStyle } from "../../page/Homev2/styled";
import { BorderAnimation } from "../../Layout/styled";
import StarUp from "../StarUp";
import { shortenAddress } from "../../utils/addressUser";
import LoadingCube from "../LoadingCube";

const ModalSuccess = ({
  isOpenModal,
  address,
  id,
  level,
  onClose,
  image,
}: any) => {
  const [isShow, setIsShow] = useState(isOpenModal);

  useEffect(() => {
    setIsShow(isOpenModal);
  }, [isOpenModal]);

  return (
    <ModalContainer
      style={{
        opacity: isShow ? "1" : "0",
        visibility: isShow ? "visible" : "hidden",
      }}
    >
      {image && level ? (
        <ModalWrapper>
          <ModalClose
            onClick={() => {
              onClose();
              setIsShow(false);
            }}
          >
            <img src={close} alt="close" />
          </ModalClose>
          <ModalTitle>Congratuation!</ModalTitle>
          <ModalImage>
            <img src={image} alt="image" />
          </ModalImage>
          <ModalContent>
            <ModalRowBetween>
              <h1>ID:</h1>
              <span>{id}</span>
            </ModalRowBetween>
            <ModalRowBetween>
              <h1>TxHash:</h1>
              <a
                href={`https://testnet.bscscan.com/tx/${address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{shortenAddress(address)}</span>
              </a>
            </ModalRowBetween>
            <ModalRowBetween>
              <h1>Level:</h1>
              <span>{level}</span>
            </ModalRowBetween>
          </ModalContent>
          <ModalRow>
            <ButtonStyle
              style={{
                margin: "0 auto",
              }}
              onClick={() => {
                setIsShow(false);
                onClose();
              }}
            >
              <BorderAnimation />
              <button>
                <StarUp />
                <span>OK</span>
              </button>
            </ButtonStyle>
          </ModalRow>
        </ModalWrapper>
      ) : (
        <LoadingCube />
      )}
    </ModalContainer>
  );
};

export default ModalSuccess;
