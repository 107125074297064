import styled from "styled-components";

export const SelectContainer = styled.div`
  .ant-select {
    border-radius: 10px;
    border: 1px solid #b692f6;
    background: #000;
    height: 42px;
    .ant-select-arrow {
      opacity: 1;
    }
    .ant-select-selector {
      background-color: transparent;
      color: #fff;
      border-color: #b692f6;
      border: none;
    }
    .ant-select-selection-item {
      color: #fff;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .ant-select-dropdown {
    background-color: #1b1c20;
    .ant-select-item-option-content {
      color: #fff;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .ant-select-item-option-active {
      background-color: #b692f6 !important;
    }
    .ant-select-item-option.ant-select-item-option-selected {
      background-color: transparent;
    }
  }
  .ant-select-selection-placeholder {
    color: #fff;
  }
`;
