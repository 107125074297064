import {
  ArtificialContainer,
  ArtificialToken,
  ArtificialWrapper,
} from "../ArtificialIntelligenceVideo/styled";
import { Tabs, TabsProps } from "antd";
import TextToImage from "./TextToImage";
import ImageTransfer from "./ImageTransfer";
import Faceswap from "./Faceswap";
import History from "./History";
import { useSelector } from "react-redux";
import { convertFormatNumber } from "../../../../utils/convertFormatNumber";

const ArtificialIntelligenceVideo = () => {
  const { userProfile, tokenPrice }: any = useSelector(
    (state: any) => state.user
  );
  //tabs
  const onChange = (key: string) => {};

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "AI Text to Image",
      children: <TextToImage />,
    },
    {
      key: "2",
      label: "AI Image Style Transfer",
      children: <ImageTransfer />,
    },
    {
      key: "3",
      label: "AI Image Faceswap",
      children: <Faceswap />,
    },
    {
      key: "4",
      label: "Your History",
      children: <History />,
    },
  ];

  return (
    <ArtificialContainer className="tab-image">
      <ArtificialWrapper>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </ArtificialWrapper>
    </ArtificialContainer>
  );
};

export default ArtificialIntelligenceVideo;
