import styled from "styled-components";
import { DashboardTitle } from "../../../../Layout/styled";

export const ArtificialContainer = styled.div`
  &.tab-image {
    .ant-tabs-nav {
      &:before {
        content: none;
      }
    }
    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 5px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      background: #fff;
      .ant-tabs-tab-btn {
        background: var(
          --Gradient,
          linear-gradient(90deg, #5c00fc 0%, #c44cff 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: unset;
        text-shadow: unset;
      }
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      display: none;
    }
    .ant-tabs-tab {
      border-radius: var(--Parameters-Radius-sm, 6px);
      background: #2d2f37;
      min-width: 217px;
      justify-content: center;
    }
    .ant-tabs-tab-btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .ant-tabs {
      width: 100%;
    }
  }
  @media screen and (max-width: 767.98px) {
    .ant-tabs-nav-operations {
      display: none !important;
    }
    /* .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
      white-space: unset;
    } */
    /* .ant-tabs-nav-list {
      width: 100%;
    } */
    .ant-tabs-tab {
      width: 50%;
    }
    /* .ant-tabs-tab {
      min-width: unset !important;
    } */
  }
`;
export const ArtificialWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 767.98px) {
    display: block;
  }
`;
export const ArtificialToken = styled.p`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  border-radius: var(--Parameters-Radius-md, 8px);
  border: 1px solid var(--stroke-25-button, #55565e);
  background: var(--Control-Active, rgba(248, 248, 248, 0.05));
  background-blend-mode: luminosity;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.3);
  padding: 4px 16px;
  color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  img {
    width: 22px;
    height: 22px;
  }
`;
export const ArtificialContentLeft = styled.div`
  background-color: #27282c;
  width: 69.7%;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 24px;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;
export const ArtificialTitleInfo = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  background: #383a42;
  min-height: 57px;
  ${DashboardTitle} {
    margin-bottom: 0;
  }
  p {
    color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    span {
      display: block;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
    }
  }
  .image {
    border-radius: var(--Parameters-Radius-md, 8px);
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: var(--Control-Active, rgba(248, 248, 248, 0.05));
    background-blend-mode: luminosity;
    box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.3);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ArtificialContentInner = styled.div`
  padding: 10px 24px 0;
  height: 860px;
  overflow: auto;
  .text-bio {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767.98px) {
    height: unset;
    padding: 12px;
  }
`;
export const ArtificialContentInnerTop = styled.div`
  position: relative;
  padding-bottom: 11px;
  margin-bottom: 11px;
  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    opacity: 0.3;
    background: linear-gradient(90deg, #1f1f20 0%, #9b9b9b 45.1%, #1e1e1f 100%);
  }
`;
export const ArtificialContentTextSuggest = styled.p`
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 18.75px */
  margin-bottom: 10px;
`;
export const ArtificialContentText = styled.p`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #b1b1b1;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-left: 20px;
  &.is-description {
    top: -25px;
    left: 0;
  }
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: url("/assets/artificial/artificial_text.png") center / contain
      no-repeat;
    width: 16px;
    height: 16px;
  }
  &.is-normal {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 5px;
  }
`;
export const ArtificialContentInnerTopTetArea = styled.div`
  position: relative;
  textarea {
    border-radius: var(--Parameters-Radius-lg, 10px);
    border: 1px solid #353535;
    background: #1b1c20;
    outline: none;
    color: #fff;
    width: 100%;
    height: 9.6vw;
    padding: 33px 16px 22px;
    resize: none;
    font-size: 16px;
  }
  .number-charactor {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #838383;
    text-align: right;
    font-size: 14px;
  }
  @media screen and (max-width: 767.98px) {
    display: flex;
    flex-direction: column-reverse;
    textarea {
      height: 149px;
      font-size: 15px;
    }
  }
`;
export const ArtificialListButton = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 11px;
  li {
    border-radius: 9px;
    cursor: pointer;
    span {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }
    &.disable {
      background-color: grey !important;
      cursor: not-allowed;
    }
    &:nth-child(2) {
      padding: 9px 16px;
      background: var(--Colors-Primary-Colors-Primary-600, #7f56d9);
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: #fff;
      }
    }
  }
`;
export const ArtificialContentInnerBottom = styled.div`
  ${ArtificialTitleInfo} {
    background-color: unset;
    p {
      font-weight: 700;
      span {
        font-weight: 500;
      }
    }
  }
`;
export const ArtificialContentInnerBottomInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 8px;
  height: 22vw;
  padding-bottom: 16px;
  .block-video,
  .block-text {
    border: 1px solid #353535;
    background: #1b1c20;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  .block-video {
    width: 55%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-icon,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .ant-spin-dot-item {
      background-color: #7f56d9;
      opacity: 1;
    }
  }
  .block-text {
    width: 45%;
    padding: 40px 16px 60px;
    position: relative;
    button {
      cursor: pointer;
      position: absolute;
      bottom: 16px;
      right: 16px;
      color: #dfdfdf;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      border-radius: 9px;
      background: #2d2f37;
      padding: 9px 16px;
      outline: none;
      border: none;
      width: 145px;
      height: 44px;
      display: block;
    }
  }
  .text-prompt {
    position: static;
    margin-bottom: 5px;
  }
  .text {
    color: #fff;
    font-size: 16px;
  }
  @media screen and (max-width: 767.98px) {
    height: unset;
    display: block;
    .block-video,
    .block-text {
      width: 100%;
      height: 300px;
    }
    .block-video {
      margin-bottom: 15px;
    }
  }
`;
export const ArtificialButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
export const ArtificialButtonRegenerate = styled.button`
  color: #dfdfdf;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  width: 145px;
  height: 44px;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  gap: var(--Parameters-Radius-sm, 6px);
  border-radius: 9px;
  background: #2d2f37;
  outline: none;
  border: unset;
`;
export const ArtificialButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 205px;
  height: 44px;
  outline: none;
  border: none;
  border-radius: 7.835px;
  background: var(--Colors-Primary-Colors-Primary-600, #7f56d9);
  cursor: pointer;
  padding: 12px 24px;
  color: var(--Colors-Primary-Colors-Primary-25, #fcfaff);
  font-size: 15px;
  font-weight: 600;
  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  @media screen and (max-width: 767.98px) {
    width: calc(100% - 155px);
  }
`;

export const ArtificialContentRight = styled.div`
  background-color: #25262b;
  width: 30.3%;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: 767.98px) {
    width: 100%;
  }
`;
export const ArtificialContentRightInner = styled.div`
  padding: 27px 16px;
`;
export const ArtificialListVideo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  li {
    width: calc(100% / 2 - 6.4px);
    border-radius: 7.722px;
    background: #1b1c20;
    box-shadow: 0px 0px 11.261px 0px rgba(0, 0, 0, 0.09);
    padding: 6px 4.7px;
    min-height: 14vw;
    img {
      width: 100%;
      height: 10.128vw;
      border-radius: 0.5vw;
    }
    p {
      text-align: left;
      color: #a1a1a1;
      font-size: 12px;
      font-weight: 500;
      padding: 0 3px;
      letter-spacing: -0.02em;
      padding-bottom: 3px;
      padding-top: 2px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
  @media screen and (max-width: 767.98px) {
    li {
      img {
        height: 150px;
        border-radius: 8px;
      }
    }
  }
`;

export const ButtonSurprise = styled.button`
  background: #28112b;
  color: var(--Colors-Secondary-Colors-Pink-500, #ee46bc);
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 16px;
  outline: none;
  border: 0;
  border-radius: 9px;
  cursor: pointer;
`;
