import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex, Image, message, Upload } from "antd";
import type { GetProp, UploadProps } from "antd";
import {
  ArtificialButtonRegenerate,
  ArtificialButtons,
  ArtificialButtonSave,
  ArtificialContainer,
  ArtificialContentInner,
  ArtificialContentInnerBottom,
  ArtificialContentInnerBottomInner,
  ArtificialContentInnerTop,
  ArtificialContentInnerTopTetArea,
  ArtificialContentLeft,
  ArtificialContentText,
  ArtificialContentTextSuggest,
  ArtificialTitleInfo,
  ArtificialToken,
  ArtificialWrapper,
  ButtonSurprise,
} from "../../ArtificialIntelligenceVideo/styled";
import Example from "../../../Example";
import { ContentUploadImage, ImageTransferContainer } from "./styled";
import type { UploadFile } from "antd";
import { convertFormatNumber } from "../../../../../utils/convertFormatNumber";
import { useSelector } from "react-redux";
import { ContextProviderWrapper } from "../../../../../components/Context";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { dispatch } from "../../../../../redux/store";
import { UserOverview } from "../../../../../redux/slices/user";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import LoadingCube from "../../../../../components/LoadingCube";
import { DashboardTitle } from "../../../../../Layout/styled";
import { ContentWork } from "../TextToImage/styled";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageTransfer = () => {
  const [textArea, setTextArea] = useState("");
  const [countCharactor, setCountCharactor] = useState(0);
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const { userProfile }: any = useSelector((state: any) => state.user);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const intervalRef = useRef<any>();
  const [loadingImage, setLoadingImage] = useState(false);
  const [imgBase64, setImgBase64] = useState();

  //handle random surprise
  const surpriseText = [
    "Portrait of fire wizard, wizard hat, modern wizard clothes, magic staff, muscular body, handsome, dark fantasy, key visual, intricate, highly detailed,  comprehensive, vivid colors, sharp focus, studio photo, trending on artstation",
    "A matte 3 d low poly mountain covered in sprinkles and frosting, vibrant colors, lat lighting, cute isometric 3 d render, highly detailed, trending on artstation",
    "4D abstract fluid artwork rendered in Unreal Engine 5, colorful, super highly detailed ribbons of color floating and contorting, amazing composition, non-Newtonian fluid dynamics, dynamic shape of the subject, 8k render, octane render, explosive colors, sharp details",
    "Billie Eilish, gothic, street gangster, gorgeous and beautiful, queen Billie Eilish, highly detailed, realistic, photorealistic",
    "An amazing alien landscape with lush vegetation and colorful galaxy foreground, digital art, breathtaking, golden ratio, extremely detailed, hyper-detailed, establishing shot, hyperrealistic, cinematic lighting, particles, unreal engine, environment concept, artstation, octane render, 8k image, just landscape",
    "Cut paper sculpture horse, forest background, highly detailed",
    "Macro rose, ink, photo, realistic, fine detail, detail, vibrant, a multicolored rose floating through the vibrant microscopic quantum world, intricate details, insane composition, hdr 8k",
    "American vintage car, black in color, with a cyberpunk city background, futuristic lighting, cinematic lighting, cozy lighting, 8k, cinematic poster vintage 1800s",
    "Macro photo of a small fairytale house in a fantasy forest, diorama, Nikon, rendered, unity, blender, sharp details, sharp focus, elegant, highly detailed, medium shot, sunny day, unreal engine",
    "Human colony on an unknown planet, bright vibrant colored vegetation, bioluminescent planet, hyperrealism, highly detailed, insanely detailed, trending on artstation",
    "A beautiful grassy meadow, at sunset, birds, lake, psychedelic colorful, highly detailed, dream-like, colorful light, bright, vibrant colors, diffused light, photorealistic, volumetric lighting, 4K",
    "Hyper detailed alien planet white and blue with green mountain and pink clouds, 90' anime vintage style, in the center of the image, high quality",
    "Ornate and intricate mythical fox demon, psychedelic, turbulent fluid, fractal, smokey, symmetrical, sharp focus, vibrant, volumetric fog, studio lighting, depth of field, unreal engine, octane render, raytracing",
    "Billie Eilish, gothic, street gangster, gorgeous and beautiful, queen Billie Eilish, highly detailed, realistic, photorealistic",
    "Macro photo of vibrant cherries with water drops and golden ice, glow, sun rays and glare, high detail, full detail, high resolution, glow, rendering, photorealistic, 55 mm, volumetric lighting, ray tracing, reflections",
    "Sweet giraffe couple in Love, flat design, retro style t-shirt, black background, 4k",
    "1 girl, octane render, hdr,  beautiful girl, ultra detailed eyes, mature, plump, thick, opal drops, paint teardrops, woman made up from paint, entirely paint, splat, splash, long colored hair, kimono made from paint, ultra detailed texture kimono, opalescent paint kimono, paint bulb, water drops, (hair ornaments, earrings, flowers hair ornaments, dragonfly hair ornaments), outdoors, sakura trees, vivid colors",
    "A cute matte low poly isometric cherry blossom forest island, pink waterfalls, mist, soft shadows, trending on artstation, 3d render, monument valley",
    "Space ship castle, mobile game asset, isometric, centralized, low details, game concept art, beautifully backlit, colorful, beautiful, trending on artstation",
    "Portrait art of a beautiful girl, artistic, photorealistic, midnight aura, dreamy, stars, glowing, backlit, glamour, glimmer, shadows, smooth, ultra high definition, 8k, ultra sharp focus, intricate, ominous, movie poster, kodak",
  ];

  const getRandomResearchTitle = () => {
    if (surpriseText.length === 0) {
      return "All items have been used!";
    }
    const randomIndex = Math.floor(Math.random() * surpriseText.length);
    const randomResearchTitle = surpriseText[randomIndex];
    // Remove the selected item from the array
    surpriseText.splice(randomIndex, 1);
    return randomResearchTitle;
  };

  const handleGenerate = () => {
    const randomResearchTitle = getRandomResearchTitle();
    setTextArea(randomResearchTitle);
  };

  const onChangeTextArea = (e: any) => {
    const newValue = e.target.value;
    if (newValue.length <= 734) {
      setTextArea(newValue);
      setCountCharactor(newValue.length);
    }
  };
  useEffect(() => {
    setCountCharactor(textArea.length);
  }, [textArea]);

  //handle base64
  const extractBase64Data = (base64String: any) => {
    const commaPosition = base64String.indexOf(",");
    if (commaPosition === -1) {
      throw new Error("Invalid base64 string");
    }
    return base64String.substring(commaPosition + 1);
  };

  //upload img
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
    file,
  }) => {
    try {
      const currentFileImg: any = {
        ...newFileList[0],
      };
      const imgBase64: any = await getBase64(currentFileImg.originFileObj);
      setImgBase64(imgBase64);
      setFileList([
        {
          ...currentFileImg,
          thumbUrl: imgBase64,
        },
      ]);
    } catch (error) {
      console.log("handleChange1 err", error);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <ContentUploadImage>
            <p className="text-upload">Upload Additional Image</p>
            <img src="/assets/artificial/icn_img_05.svg" alt="" />
            <p className="text-drop">
              Drop & drag or <span>choose file</span>
            </p>
          </ContentUploadImage>
        </>
      )}
    </button>
  );

  // handle reload data user
  const GET_USER = gql`
    query {
      user {
        email
        refCode
        phoneNumber
        accountID
        secret
        createdAt
        children
        levelAMFI
        packUSDT
        verified2FA
        totalSales
        totalSalesLeft
        totalSalesRight
        totalLeftDirectLine
        totalRightDirectLine
        directCommission
        rightReserve
        leftReserve
        totalRevenue
        modeTree
        bonusVerify
        totalBonus
        totalWeakBranch
        totalStaking
        maxOutUSDT
        totalDepositAMFI
        totalDepositUSDT
        parentEmail
        wallet {
          bot {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          aimetafi {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          usdt {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          point {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          revenue {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          bonus {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
        }
        affiliate {
          displayName
          email
          createdAt
          branch
          accountID
          packAMFI
          packUSDT
          parentID
          children
          levelAMFI
          refCode
        }
        transactionRevenue {
          amount
          id
          note
          createdAt
          transactionType
        }
        packInvestingAMFI {
          price
          rate
          priceAMFI
          expireDate
          packDatetime
          paymentCurrency
        }
      }
      tickets {
        id
        subject
        status
        messages {
          text
          author
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      kyc {
        status
        accountID
        frontURL
        backURL
        documentID
        country
      }
      settings {
        tag
        config
      }
    }
  `;
  const { data, refetch, loading: isFetchUser } = useQuery(GET_USER);
  // api check data
  const GET_AIGEN = gql`
    query aigen($id: String!) {
      aigen(id: $id) {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;

  const [FetchDataGen, { data: dataGen }] = useLazyQuery(GET_AIGEN, {
    fetchPolicy: "network-only",
  });

  // api history gen
  const GET_AIGENS = gql`
    query aigens {
      aigens {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;
  const { data: dataGens, refetch: refetchGens } = useQuery(GET_AIGENS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    dispatch(UserOverview(data));
    if (dataGen?.aigen?.resultUrl) {
      clearInterval(intervalRef.current);
      refetchGens();
      toast.success("Gen Image Success");
      refetch();
      setImgList([dataGen?.aigen?.resultUrl]);
      setLoadingImage(false);
    }
  }, [dataGen]);

  //api handle swapface
  const IMAGE_TO_IMAGE = gql`
    mutation imageToImage($file: String!, $fileName: String!, $text: String!) {
      imageToImage(file: $file, fileName: $fileName, text: $text)
    }
  `;
  const [imageToImage] = useMutation(IMAGE_TO_IMAGE);

  const handleImageToImage = async () => {
    setLoadingImage(true);
    try {
      const file1Base64 = imgBase64 ? extractBase64Data(imgBase64) : "";
      const res = await imageToImage({
        variables: {
          file: file1Base64,
          fileName: fileList[0]?.name,
          text: textArea,
        },
      });
      if (res) {
        intervalRef.current = setInterval(() => {
          FetchDataGen({
            variables: {
              id: res?.data?.imageToImage,
            },
          });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle download image
  const [imgList, setImgList] = useState<any>(null);
  const handleDownloadImage = (images: any) => {
    const downLoad = async (file: any) => {
      try {
        await saveAs(file, `${uuidv4()}`);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    images.forEach(async (element: any) => {
      await downLoad(element);
    });
  };

  return (
    <ImageTransferContainer>
      <ArtificialContainer>
        <ArtificialWrapper>
          <ArtificialContentLeft>
            <ArtificialTitleInfo>
              <DashboardTitle background="#32D583">
                AI Image Style Transfer
              </DashboardTitle>
              <ArtificialToken>
                <span>Credit:</span>
                {userProfile?.user
                  ? `${convertFormatNumber(
                      userProfile?.user?.wallet.aimetafi.balance.toFixed(4)
                    )}`
                  : "--"}
              </ArtificialToken>
            </ArtificialTitleInfo>
            <ArtificialContentInner>
              {!isMobile && (
                <p className="text-bio">
                  Various image styles to choose from. Make your own images
                  stylish and artistic easily with style transfer from AMFI. Get
                  various artistic styles for free and turn your images into
                  digital art.
                </p>
              )}
              <ArtificialContentInnerTop>
                <ArtificialContentInnerTopTetArea className="is-flex">
                  <div className="block-bio">
                    <textarea
                      name="prompt"
                      value={textArea}
                      onChange={onChangeTextArea}
                      disabled={countCharactor >= 735}
                    ></textarea>
                    <ArtificialContentText>Your Prompt</ArtificialContentText>
                    <span className="number-charactor">
                      {countCharactor}/735
                    </span>
                  </div>
                  <Flex gap="middle" className="block-upload-image">
                    <>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={() => false}
                      >
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                    </>
                  </Flex>
                </ArtificialContentInnerTopTetArea>
                <ContentWork>
                  <ButtonSurprise onClick={handleGenerate}>
                    Surprise me
                  </ButtonSurprise>
                  <ArtificialButtonSave
                    onClick={handleImageToImage}
                    disabled={textArea === "" ? true : false}
                  >
                    Generate (1 Credit)
                  </ArtificialButtonSave>
                </ContentWork>
              </ArtificialContentInnerTop>
              <ArtificialContentInnerBottom>
                <ArtificialTitleInfo>
                  <p>
                    Generated Image
                    <span>Check the image below</span>
                  </p>
                  <div className="image">
                    <img src="/assets/artificial/icn_img_02.svg" alt="" />
                  </div>
                </ArtificialTitleInfo>
                <ArtificialContentInnerBottomInner>
                  <div className="block-video">
                    {!loadingImage ? (
                      <img
                        className="image-icon"
                        src={
                          dataGen?.aigen?.resultUrl
                            ? dataGen?.aigen?.resultUrl
                            : "/assets/artificial/icn_img_03.png"
                        }
                        alt=""
                      />
                    ) : (
                      <LoadingCube />
                    )}
                  </div>
                  {!isMobile && (
                    <div className="block-text">
                      <ArtificialContentInnerTopTetArea>
                        <ArtificialContentText className="is-description">
                          Your Prompt
                        </ArtificialContentText>
                        <p className="text">{dataGen?.aigen?.text}</p>
                        {/* <p className="text">
                          Chinese style, water stream, mountains, mist, hyper
                          realistic, lush gnarly plants, 8 k, denoised,
                          cinematic, fengshui
                        </p> */}
                      </ArtificialContentInnerTopTetArea>
                      <button>Re-generate</button>
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <ArtificialContentText className="is-normal">
                        Your Prompt
                      </ArtificialContentText>
                      <ArtificialContentTextSuggest>
                      {dataGen?.aigen?.text}
                      </ArtificialContentTextSuggest>
                    </>
                  )}
                </ArtificialContentInnerBottomInner>
              </ArtificialContentInnerBottom>
              <ArtificialButtons>
                {isMobile && (
                  <ArtificialButtonRegenerate>
                    Re-generate
                  </ArtificialButtonRegenerate>
                )}
                <ArtificialButtonSave
                  onClick={() => handleDownloadImage(imgList)}
                >
                  Save Image
                </ArtificialButtonSave>
              </ArtificialButtons>
            </ArtificialContentInner>
          </ArtificialContentLeft>
          <Example />
        </ArtificialWrapper>
      </ArtificialContainer>
    </ImageTransferContainer>
  );
};

export default ImageTransfer;
