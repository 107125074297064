import styled from "styled-components";
import logoToken from "../../assets/Common/logoToken.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BottomBox = ({ setCollapse, collapse }: any) => {
  const { t } = useTranslation();
  const { tokenPrice }: any = useSelector((state: any) => state.user);
  return (
    <BoxWapper className={collapse ? "collapse" : ""}>
      <FlexSpaceBetweenBox>
        <img
          className="logotokent"
          src={logoToken}
          alt=""
          onClick={() => {
            setCollapse(false);
          }}
        />
      </FlexSpaceBetweenBox>
      <BoxContent className={collapse ? "collapse" : ""}>
        <h3>{t("AIFI Price")}</h3>
        <FlexSpaceBetweenBox>
          <h4>{tokenPrice ? tokenPrice : "--"}$</h4>
        </FlexSpaceBetweenBox>
        <p>
          {t("Each wallet can purchase a maximum of")}
          <span> $2</span>
        </p>
        <Link to="/buy-amfi">
          <BtnBuy>
            <h5>{t("Buy AIFI")}</h5>
          </BtnBuy>
        </Link>
      </BoxContent>
    </BoxWapper>
  );
};
export default BottomBox;

const BoxWapper = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #020202;
  border: 1px solid #fcfcfd;
  border-radius: 12px;
  .logotokent {
    width: 54px;
    height: 54px;
    transition: all 0.15s linear;
  }
  &.collapse {
    padding: 10px;
    height: 50px;
    overflow: hidden;
    .logotokent {
      width: 30px;
      height: 30px;
    }
  }
`;
const FlexSpaceBetweenBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3,
  h4,
  p {
    transition: all 0.25s;
  }
  h3 {
    color: #7f56d9;
    font-size: 18px;
  }
  h4 {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    color: #77ff60;
  }
  p {
    color: #667085;
    font-size: 13px;
    span {
      color: #fff;
      font-weight: 500;
    }
  }
  &.collapse {
    h3,
    h4,
    p {
      opacity: 0;
      font-size: 0px;
      visibility: hidden;
    }
  }
`;
const BtnBuy = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  h5 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(to right, #c44cff, #5c00fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
