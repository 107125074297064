import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex, Image, message, Upload } from "antd";
import type { GetProp, UploadProps } from "antd";
import {
  ArtificialButtonRegenerate,
  ArtificialButtons,
  ArtificialButtonSave,
  ArtificialContainer,
  ArtificialContentInner,
  ArtificialContentInnerBottom,
  ArtificialContentInnerBottomInner,
  ArtificialContentInnerTop,
  ArtificialContentInnerTopTetArea,
  ArtificialContentLeft,
  ArtificialContentText,
  ArtificialContentTextSuggest,
  ArtificialTitleInfo,
  ArtificialToken,
  ArtificialWrapper,
} from "../../ArtificialIntelligenceVideo/styled";
import {
  ContentWork,
  ContentWorkList,
  TitleArtWork,
} from "../TextToImage/styled";
import Example from "../../../Example";
import type { UploadFile } from "antd";
import {
  ContentUploadImage,
  ImageTransferContainer,
} from "../ImageTransfer/styled";
import { useSelector } from "react-redux";
import { convertFormatNumber } from "../../../../../utils/convertFormatNumber";
import { ContextProviderWrapper } from "../../../../../components/Context";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { dispatch } from "../../../../../redux/store";
import { UserOverview } from "../../../../../redux/slices/user";
import toast from "react-hot-toast";
import LoadingCube from "../../../../../components/LoadingCube";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { DashboardTitle } from "../../../../../Layout/styled";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Faceswap = () => {
  const [loading, setLoading] = useState(false);
  const [previewOpen1, setPreviewOpen1] = useState(false);
  const [previewImage1, setPreviewImage1] = useState("");
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);
  const [previewOpen2, setPreviewOpen2] = useState(false);
  const [previewImage2, setPreviewImage2] = useState("");
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const intervalRef = useRef<any>();
  const { userProfile }: any = useSelector((state: any) => state.user);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [img1Base64, setImg1Base64] = useState();
  const [img2Base64, setImg2Base64] = useState();

  //handle base64
  const extractBase64Data = (base64String: any) => {
    const commaPosition = base64String.indexOf(",");
    if (commaPosition === -1) {
      throw new Error("Invalid base64 string");
    }
    return base64String.substring(commaPosition + 1);
  };

  //upload img
  const handlePreview1 = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage1(file.url || (file.preview as string));
    setPreviewOpen1(true);
  };
  const handlePreview2 = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage2(file.url || (file.preview as string));
    setPreviewOpen2(true);
  };

  const handleChange1: UploadProps["onChange"] = async ({
    fileList: newFileList,
    file,
  }) => {
    if (file.status === "removed") {
      setFileList1(newFileList);
      return;
    }
    try {
      const currentFileImg: any = {
        ...newFileList[0],
      };
      const imgBase64: any = await getBase64(currentFileImg.originFileObj);
      setImg1Base64(imgBase64);
      setFileList1([
        {
          ...currentFileImg,
          thumbUrl: imgBase64,
        },
      ]);
    } catch (error) {
      console.log("handleChange1 err", error);
    }
  };
  const handleChange2: UploadProps["onChange"] = async ({
    fileList: newFileList,
    file,
  }) => {
    if (file.status === "removed") {
      setFileList2(newFileList);
      return;
    }
    try {
      const currentFileImg: any = {
        ...newFileList[0],
      };
      const imgBase64: any = await getBase64(currentFileImg.originFileObj);
      setImg2Base64(imgBase64);
      setFileList2([
        {
          ...currentFileImg,
          thumbUrl: imgBase64,
        },
      ]);
    } catch (error) {
      console.log("handleChange1 err", error);
    }
  };

  const uploadButton1 = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <ContentUploadImage>
            <p className="text-upload">
              Your Image<span>The image should contain at least one face</span>
            </p>
            <img src="/assets/artificial/icn_img_05.svg" alt="" />
            <p className="text-drop">
              Drop & drag or <span>choose file</span>
            </p>
          </ContentUploadImage>
        </>
      )}
    </button>
  );
  const uploadButton2 = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <ContentUploadImage>
            <p className="text-upload">
              Target Image
              <span>The image should contain at least one face</span>
            </p>
            <img src="/assets/artificial/icn_img_05.svg" alt="" />
            <p className="text-drop">
              Drop & drag or <span>choose file</span>
            </p>
          </ContentUploadImage>
        </>
      )}
    </button>
  );

  // handle reload data user
  const GET_USER = gql`
    query {
      user {
        email
        refCode
        phoneNumber
        accountID
        secret
        createdAt
        children
        levelAMFI
        packUSDT
        verified2FA
        totalSales
        totalSalesLeft
        totalSalesRight
        totalLeftDirectLine
        totalRightDirectLine
        directCommission
        rightReserve
        leftReserve
        totalRevenue
        modeTree
        bonusVerify
        totalBonus
        totalWeakBranch
        totalStaking
        maxOutUSDT
        totalDepositAMFI
        totalDepositUSDT
        parentEmail
        wallet {
          bot {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          aimetafi {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          usdt {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          point {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          revenue {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
          bonus {
            address
            balance
            transaction {
              amount
              id
              hash
              note
              createdAt
              transactionType
            }
          }
        }
        affiliate {
          displayName
          email
          createdAt
          branch
          accountID
          packAMFI
          packUSDT
          parentID
          children
          levelAMFI
          refCode
        }
        transactionRevenue {
          amount
          id
          note
          createdAt
          transactionType
        }
        packInvestingAMFI {
          price
          rate
          priceAMFI
          expireDate
          packDatetime
          paymentCurrency
        }
      }
      tickets {
        id
        subject
        status
        messages {
          text
          author
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      kyc {
        status
        accountID
        frontURL
        backURL
        documentID
        country
      }
      settings {
        tag
        config
      }
    }
  `;
  const { data, refetch, loading: isFetchUser } = useQuery(GET_USER);
  // api check data
  const GET_AIGEN = gql`
    query aigen($id: String!) {
      aigen(id: $id) {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;

  const [FetchDataGen, { data: dataGen }] = useLazyQuery(GET_AIGEN, {
    fetchPolicy: "network-only",
  });

  // api history gen
  const GET_AIGENS = gql`
    query aigens {
      aigens {
        id
        accountID
        credit
        AIType
        status
        resultUrl
        text
        createdAt
        updatedAt
      }
    }
  `;
  const { data: dataGens, refetch: refetchGens } = useQuery(GET_AIGENS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    dispatch(UserOverview(data));
    if (dataGen?.aigen?.resultUrl) {
      clearInterval(intervalRef.current);
      refetchGens();
      toast.success("Gen Image Success");
      refetch();
      setImgList([dataGen?.aigen?.resultUrl]);
      setLoadingImage(false);
    }
  }, [dataGen]);

  //api handle swapface
  const SWAP_FACE = gql`
    mutation swapFace(
      $file1: String!
      $fileName1: String!
      $file2: String!
      $fileName2: String!
    ) {
      swapFace(
        file1: $file1
        fileName1: $fileName1
        file2: $file2
        fileName2: $fileName2
      )
    }
  `;
  const [swapFace] = useMutation(SWAP_FACE);

  const handleSwapFace = async () => {
    setLoadingImage(true);
    try {
      const file1Base64 = img1Base64 ? extractBase64Data(img1Base64) : "";
      const file2Base64 = img2Base64 ? extractBase64Data(img2Base64) : "";

      const res = await swapFace({
        variables: {
          file1: file1Base64,
          fileName1: fileList1[0]?.name,
          file2: file2Base64,
          fileName2: fileList2[0]?.name,
        },
      });
      if (res) {
        intervalRef.current = setInterval(() => {
          FetchDataGen({
            variables: {
              id: res?.data?.swapFace,
            },
          });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle download image
  const [imgList, setImgList] = useState<any>(null);
  const handleDownloadImage = (images: any) => {
    const downLoad = async (file: any) => {
      try {
        await saveAs(file, `${uuidv4()}`);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    images.forEach(async (element: any) => {
      await downLoad(element);
    });
  };

  return (
    <ImageTransferContainer className="is-faceswap">
      <ArtificialContainer>
        <ArtificialWrapper>
          <ArtificialContentLeft>
            <ArtificialTitleInfo>
              <DashboardTitle background="#32D583">
                AI Image Faceswap
              </DashboardTitle>
              <ArtificialToken>
                <span>Credit:</span>
                {userProfile?.user
                  ? `${convertFormatNumber(
                      userProfile?.user?.wallet.aimetafi.balance.toFixed(4)
                    )}`
                  : "--"}
              </ArtificialToken>
            </ArtificialTitleInfo>
            <ArtificialContentInner>
              {!isMobile && (
                <p className="text-bio">
                  Step into different personas with our AI Image Faceswap tool.
                  Easily swap faces in photos to reimagine yourself in various
                  styles and characters. Whether you're looking to star in your
                  favorite movie or create hilarious memes, the possibilities
                  are endless.
                </p>
              )}
              <ArtificialContentInnerTop>
                <ArtificialContentInnerTopTetArea className="is-flex">
                  <Flex gap="middle" className="block-upload-image">
                    <>
                      <Upload
                        listType="picture-card"
                        fileList={fileList1}
                        onPreview={handlePreview1}
                        onChange={handleChange1}
                        beforeUpload={() => false}
                      >
                        {fileList1.length >= 1 ? null : uploadButton1}
                      </Upload>
                      {previewImage1 && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen1,
                            onVisibleChange: (visible) =>
                              setPreviewOpen1(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage1(""),
                          }}
                          src={previewImage1}
                        />
                      )}
                    </>
                  </Flex>
                  <Flex gap="middle" className="block-upload-image">
                    <>
                      <Upload
                        listType="picture-card"
                        fileList={fileList2}
                        onPreview={handlePreview2}
                        onChange={handleChange2}
                        beforeUpload={() => false}
                      >
                        {fileList2.length >= 1 ? null : uploadButton2}
                      </Upload>
                      {previewImage2 && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen2,
                            onVisibleChange: (visible) =>
                              setPreviewOpen2(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage2(""),
                          }}
                          src={previewImage2}
                        />
                      )}
                    </>
                  </Flex>
                </ArtificialContentInnerTopTetArea>
                <ContentWork>
                  <ArtificialButtonSave
                    style={{ marginTop: "10px" }}
                    onClick={handleSwapFace}
                  >
                    Generate (1 Credit)
                  </ArtificialButtonSave>
                </ContentWork>
              </ArtificialContentInnerTop>
              <ArtificialContentInnerBottom>
                <ArtificialTitleInfo>
                  <p>
                    Generated Image
                    <span>Check the image below</span>
                  </p>
                  <div className="image">
                    <img src="/assets/artificial/icn_img_02.svg" alt="" />
                  </div>
                </ArtificialTitleInfo>
                <ArtificialContentInnerBottomInner>
                  <div className="block-video">
                    {!loadingImage ? (
                      <img
                        className="image-icon"
                        src={
                          dataGen?.aigen?.resultUrl
                            ? dataGen?.aigen?.resultUrl
                            : "/assets/artificial/icn_img_03.png"
                        }
                        alt=""
                      />
                    ) : (
                      <LoadingCube />
                    )}
                  </div>
                  {!isMobile && (
                    <div className="block-action">
                      <h3>Action</h3>
                      <p>
                        Swap faces in photos effortlessly with our AI Image
                        Faceswap tool. You can try on different personas,
                        styles, and characters with just a few clicks. Want to
                        see yourself as a movie star or create some funny memes?
                        The options are limitless!
                      </p>
                      <div className="block-button">
                        <button className="re-generate">Re-generate</button>
                        <ArtificialButtonSave
                          onClick={() => handleDownloadImage(imgList)}
                        >
                          Save Image
                        </ArtificialButtonSave>
                      </div>
                    </div>
                  )}
                  {isMobile && (
                    <>
                      <ArtificialContentText className="is-normal">
                        Your Prompt
                      </ArtificialContentText>
                      <ArtificialContentTextSuggest>
                        Chinese style, water stream, mountains, mist, hyper
                        realistic, lush gnarly plants, 8 k, denoised, cinematic,
                        fengshui
                      </ArtificialContentTextSuggest>
                      <ArtificialButtons>
                        {isMobile && (
                          <ArtificialButtonRegenerate>
                            Re-generate
                          </ArtificialButtonRegenerate>
                        )}
                        <ArtificialButtonSave>Save Image</ArtificialButtonSave>
                      </ArtificialButtons>
                    </>
                  )}
                </ArtificialContentInnerBottomInner>
              </ArtificialContentInnerBottom>
            </ArtificialContentInner>
          </ArtificialContentLeft>
          <Example />
        </ArtificialWrapper>
      </ArtificialContainer>
    </ImageTransferContainer>
  );
};

export default Faceswap;
