export const IPFS = {
  brozen: {
    label: "Bronze",
    key: "ipfs://bafkreidxcysyg3cinl4wpvm5nkxgxftz2uhbhntefexthkdl6rjozixcqm",
    image:
      "https://bafybeigbdyqprnvfrewcqnqo75wmwwflr75o7nnut7qf24wsgljnxecyci.ipfs.nftstorage.link/",
  },
  silver: {
    label: "Silver",
    key: "ipfs://bafkreihn3uip42z4cww2ajua2ulqwdlviclnw2ihxriawn27su6ojap7qq",
    image:
      "https://bafybeibzbe5zd3tf2nqm63yjy4ltoj2rc4e7fwc3fgmwuous466lbhjyrm.ipfs.nftstorage.link/",
  },
  gold: {
    label: "Gold",
    key: "ipfs://bafkreiczsnsikyc62taeaefvp5g6oyx4ywfufw3g7bat5r6lu3dgywvb3e",
    image:
      "https://bafybeiazl7coozpmv5zyhkvbygzrh5smozrjuczzwfcmb3gmo25clssuwm.ipfs.nftstorage.link/",
  },
};
